class Skill {
  constructor(type) {
    this._type = type;
    this._proficiencyLevel = 0;
  }

  get type() {
    return this._type;
  }

  train() {
    this._proficiencyLevel = 1;
  }

  gainExpertise() {
    this._proficiencyLevel = 2;
  }

  get proficiencyLevel() {
    return this._proficiencyLevel;
  }
}
export default Skill;
