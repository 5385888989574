import assert from '../Common/Assert.js';

import CharacterEnhancement, { EnhancementTypes } from './CharacterEnhancement.js';
import AbilityType from './AbilityType.js';
import { AttackRollTypes } from './Actions/AttackRollType.js';

class StaticEffect extends CharacterEnhancement {
  constructor() {
    super(EnhancementTypes.Effect);
  }

  static applyToAbilityScore(abilityType, baseScore, effects) {
    var min = 0;
    var max = 999;
    var modifiers = 0;
    effects.forEach((effect) => {
      if (effect instanceof AbilityEffect && effect.abilityType === abilityType) {
        if (effect instanceof AbilityScoreModifier) {
          modifiers += effect.modifier;
        }
      }
    });
    return Math.max(Math.min(max, baseScore + modifiers), min);
  }

  static applyToMaxHP(baseHP, level, effects) {
    var hp = baseHP;
    effects.forEach((effect) => {
      if (effect instanceof MaxHPEffect) {
        if (effect instanceof MaxHPBonusPerLevel) {
          hp += effect.bonusHP * level;
        }
      }
    });
    return hp;
  }

  static calculateAC(character) {
    var baseAC = 10;
    var acModifiers = 0;
    var dexModifierMax = 99;
    const wearingArmorExceptShield = StaticEffect.isWearingArmorExceptShield(character);
    const wearingArmorOrShield =
      wearingArmorExceptShield || StaticEffect.isWearingShield(character);
    var includeWisdomIfUnarmored = false;
    var includeConstitutionIfUnarmored = false;

    var min = 0;
    var max = 999;
    character.effects.forEach((effect) => {
      if (effect instanceof ACEffect) {
        if (effect instanceof ACModifier) {
          if (wearingArmorExceptShield || !effect.requiresArmor) {
            acModifiers += effect.modifier;
          }
        } else if (effect instanceof ACBaseOverride) {
          baseAC = effect.baseAC;
          dexModifierMax = Math.min(effect.dexLimit, dexModifierMax);
        } else if (effect === StaticEffect.Types.UNARMORED_DEFENSE_BARBARIAN) {
          includeConstitutionIfUnarmored = true;
        } else if (effect === StaticEffect.Types.UNARMORED_DEFENSE_MONK) {
          includeWisdomIfUnarmored = true;
        }
      }
    });
    acModifiers += Math.min(character.abilityModifier(AbilityType.DEX), dexModifierMax);
    if (includeConstitutionIfUnarmored && includeWisdomIfUnarmored && !wearingArmorOrShield) {
      acModifiers += Math.max(
        character.abilityModifier(AbilityType.CON),
        character.abilityModifier(AbilityType.WIS)
      );
    } else if (includeConstitutionIfUnarmored && !wearingArmorExceptShield) {
      acModifiers += character.abilityModifier(AbilityType.CON);
    } else if (includeWisdomIfUnarmored && !wearingArmorOrShield) {
      acModifiers += character.abilityModifier(AbilityType.WIS);
    }
    return Math.max(Math.min(max, baseAC + acModifiers), min);
  }

  static isWearingArmorExceptShield(character) {
    return character.effects.some((effect) => {
      return effect instanceof ACBaseOverride && effect.countsAsArmor;
    });
  }

  static isWearingShield(character) {
    return character.effects.some((effect) => {
      return effect instanceof ACModifier && effect.countsAsShield;
    });
  }

  static calculateSpeed(character) {
    const isWearingArmorOrShield =
      StaticEffect.isWearingArmorExceptShield(character) || StaticEffect.isWearingShield(character);
    var speed = character.race.speed;

    character.effects.forEach((effect) => {
      if (effect instanceof SpeedEffect) {
        if (
          effect instanceof SpeedModifier ||
          (isWearingArmorOrShield && effect instanceof ArmoredSpeedModifier) ||
          (!isWearingArmorOrShield && effect instanceof UnarmoredSpeedModifier)
        ) {
          speed += effect.modifier(character.level);
        } else if (effect instanceof SpeedStrengthPenalty) {
          if (character.abilityScore(AbilityType.STR) < effect.strengthRequirement) {
            speed -= effect.penalty;
          }
        }
      }
    });
    return Math.max(0, speed);
  }

  static attackModifiers(character, attackRollType) {
    var bonus = 0;
    character.effects.forEach((effect) => {
      if (effect instanceof AttackModifier) {
        if (attackRollType === AttackRollTypes.Ranged && effect instanceof RangedAttackBonus) {
          bonus += effect.bonus;
        }
      }
    });
    return bonus;
  }
}

class AbilityEffect extends StaticEffect {
  constructor(abilityType) {
    super();
    this._abilityType = abilityType;
  }

  get abilityType() {
    return this._abilityType;
  }
}

class AbilityScoreModifier extends AbilityEffect {
  constructor(abilityType, modifier) {
    super(abilityType);
    this._modifier = modifier;
  }

  get modifier() {
    return this._modifier;
  }
}

class MaxHPEffect extends StaticEffect {}
class MaxHPBonusPerLevel extends MaxHPEffect {
  constructor(hpPerLevel) {
    super();
    this._hpPerLevel = hpPerLevel;
  }

  get bonusHP() {
    return this._hpPerLevel;
  }
}

class ACEffect extends StaticEffect {}

class ACModifier extends ACEffect {
  constructor(modifier, opt_requiresArmor, opt_countsAsShield) {
    super();
    this._modifier = modifier;
    this._requiresArmor = !!opt_requiresArmor;
    this._countsAsShield = !!opt_countsAsShield;
  }

  get modifier() {
    return this._modifier;
  }

  get requiresArmor() {
    return this._requiresArmor;
  }
}

class ACBaseOverride extends ACEffect {
  constructor(baseAC, countsAsArmor, opt_dexLimit) {
    super();
    this._baseAC = baseAC;
    this._countsAsArmor = countsAsArmor;
    this._dexLimit = opt_dexLimit || 99;
  }

  get baseAC() {
    return this._baseAC;
  }

  get countsAsArmor() {
    return this._countsAsArmor;
  }

  get dexLimit() {
    return this._dexLimit;
  }
}

class SpeedEffect extends StaticEffect {}

class SpeedModifierBase extends SpeedEffect {
  constructor(staticOrLevelBonuses) {
    super();
    this._staticOrLevelBonuses = staticOrLevelBonuses;
  }

  modifier(level) {
    if (typeof this._staticOrLevelBonuses === 'number') {
      return this._staticOrLevelBonuses;
    } else {
      var bonus = 0;
      for (var l = 1; l <= level; l++) {
        bonus += this._staticOrLevelBonuses[l] || 0;
      }
      return bonus;
    }
  }
}
class UnarmoredSpeedModifier extends SpeedModifierBase {}
class ArmoredSpeedModifier extends SpeedModifierBase {}
class SpeedModifier extends SpeedModifierBase {}

class SpeedStrengthPenalty extends SpeedEffect {
  constructor(strengthRequirement, penalty) {
    super();
    this._strengthRequirement = strengthRequirement;
    this._penalty = penalty;
  }
  get strengthRequirement() {
    return this._strengthRequirement;
  }
  get penalty() {
    return this._penalty;
  }
}

// TODO: Actually look at these effects
class AttackModifier extends StaticEffect {}
class RangedAttackBonus extends AttackModifier {
  constructor(bonus) {
    super();
    assert(typeof bonus === 'number', 'Attack bonus must be numeric');
    this._bonus = bonus;
  }

  get bonus() {
    return this._bonus;
  }
}

class NotYetImplementedEffect extends StaticEffect {}

StaticEffect.Types = {
  ABILITY_SCORE_INCREASE: function(abilityType, bonus) {
    return new AbilityScoreModifier(abilityType, bonus);
  },
  MAX_HP_BONUS_PER_LEVEL: function(bonus) {
    return new MaxHPBonusPerLevel(bonus);
  },
  ARMOR_AC: function(ac, opt_dexLimit) {
    return new ACBaseOverride(ac, true /* countsAsArmor */, opt_dexLimit);
  },
  ARMORED_AC_BONUS: function(bonus) {
    return new ACModifier(bonus, true /* requiresArmor */);
  },
  SHIELD_AC: function(opt_extraBonus) {
    return new ACModifier(
      2 + (opt_extraBonus || 0),
      false /* requiresArmor */,
      true /* countsAsShield */
    );
  },
  UNARMORED_DEFENSE_BARBARIAN: new ACEffect(),
  UNARMORED_DEFENSE_MONK: new ACEffect(),
  UNARMORED_MOVEMENT: function(staticOrLevelBonuses) {
    return new UnarmoredSpeedModifier(staticOrLevelBonuses);
  },
  ARMORED_MOVEMENT: function(staticOrLevelBonuses) {
    return new ArmoredSpeedModifier(staticOrLevelBonuses);
  },
  HEAVY_ARMOR: function(strengthRequirement) {
    return new SpeedStrengthPenalty(strengthRequirement, 10);
  },
  MOVEMENT: function(staticOrLevelBonuses) {
    return new SpeedModifier(staticOrLevelBonuses);
  },
  RANGED_ATTACK_BONUS: function(bonus) {
    return new RangedAttackBonus(bonus);
  },
  RESISTANCE: function(damageType) {
    return new NotYetImplementedEffect();
  },
  CRITICAL_HIT_IMMUNITY: new NotYetImplementedEffect(),
};

export default StaticEffect;
