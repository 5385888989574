import Proficiency from './Proficiency.js';

// Simple Melee
const CLUB = new Proficiency('Club');
const DAGGER = new Proficiency('Dagger');
const GREATCLUB = new Proficiency('Greatclub');
const HANDAXE = new Proficiency('Handaxe');
const JAVELIN = new Proficiency('Javelin');
const LIGHT_HAMMER = new Proficiency('Light Hammer');
const MACE = new Proficiency('Mace');
const QUARTERSTAFF = new Proficiency('Quarterstaff');
const SICKLE = new Proficiency('Sickle');
const SPEAR = new Proficiency('Spear');

// Simple Ranged
const DARTS = new Proficiency('Darts');
const LIGHT_CROSSBOW = new Proficiency('Light Crossbow');
const SHORTBOW = new Proficiency('Shortbow');
const SLING = new Proficiency('Sling');

// Martial Melee
const BATTLEAXE = new Proficiency('Battleaxe');
const FLAIL = new Proficiency('Flail');
const GLAIVE = new Proficiency('Glaive');
const GREATAXE = new Proficiency('Greataxe');
const GREATSWORD = new Proficiency('Greatsword');
const HALBRED = new Proficiency('Halbred');
const LANCE = new Proficiency('Lance');
const LONGSWORD = new Proficiency('Longsword');
const MAUL = new Proficiency('Maul');
const MORNINGSTAR = new Proficiency('Morningstar');
const PIKE = new Proficiency('Pike');
const RAPIER = new Proficiency('Rapier');
const SCIMITAR = new Proficiency('Scimitar');
const SHORTSWORD = new Proficiency('Shortsword');
const TRIDENT = new Proficiency('Trident');
const WARHAMMER = new Proficiency('Warhammer');
const WAR_PICK = new Proficiency('War Pick');
const WHIP = new Proficiency('Whip');

// Martial Ranged
const BLOWGUN = new Proficiency('Blowgun');
const HAND_CROSSBOW = new Proficiency('Hand Crossbow');
const HEAVY_CROSSBOW = new Proficiency('Heavy Crossbow');
const LONGBOW = new Proficiency('Longbow');
const NET = new Proficiency('Net');

// Armor
const LIGHT_ARMOR = new Proficiency('Light Armor');
const MEDIUM_ARMOR = new Proficiency('Medium Armor');
const HEAVY_ARMOR = new Proficiency('Heavy Armor');

const Proficiencies = {
  IMPOSSIBLE: new Proficiency('-'),

  AllArmor: [LIGHT_ARMOR, MEDIUM_ARMOR, HEAVY_ARMOR],

  Battleaxe: BATTLEAXE,
  Blowgun: BLOWGUN,
  BrewersSupplies: new Proficiency("Brewer's Supplies"),

  CartographersTools: new Proficiency("Cartographer's Tools"),
  Club: CLUB,

  Dagger: DAGGER,
  Darts: DARTS,
  DisguiseKit: new Proficiency('Disguise Kit'),
  Drum: new Proficiency('Drum'),

  Flail: FLAIL,

  Glaive: GLAIVE,
  Greataxe: GREATAXE,
  Greatclub: GREATCLUB,
  Greatsword: GREATSWORD,

  Halbred: HALBRED,
  Handaxe: HANDAXE,
  HandCrossbow: HAND_CROSSBOW,
  HeavyArmor: HEAVY_ARMOR,
  HeavyCrossbow: HEAVY_CROSSBOW,
  HerbalismKit: new Proficiency('Herbalism Kit'),
  Horn: new Proficiency('Horn'),

  Javelin: JAVELIN,

  Lance: LANCE,
  LightCrossbow: LIGHT_CROSSBOW,
  LightHammer: LIGHT_HAMMER,
  LightArmor: LIGHT_ARMOR,
  Longbow: LONGBOW,
  Longsword: LONGSWORD,
  Lute: new Proficiency('Lute'),
  Lyre: new Proficiency('Lyre'),

  Mace: MACE,
  MartialWeapons: [
    BATTLEAXE,
    FLAIL,
    GLAIVE,
    GREATAXE,
    GREATSWORD,
    HALBRED,
    LANCE,
    LONGSWORD,
    MAUL,
    PIKE,
    RAPIER,
    SCIMITAR,
    SHORTSWORD,
    TRIDENT,
    WARHAMMER,
    WAR_PICK,
    WHIP,

    BLOWGUN,
    HAND_CROSSBOW,
    HEAVY_CROSSBOW,
    LONGBOW,
    NET,
  ],
  Maul: MAUL,
  MediumArmor: MEDIUM_ARMOR,
  Morningstar: MORNINGSTAR,

  Net: NET,

  PaintersSupplies: new Proficiency("Painter's Supplies"),
  Pike: PIKE,

  Quarterstaff: QUARTERSTAFF,

  Rapier: RAPIER,

  Scimitar: SCIMITAR,
  Shield: new Proficiency('Shield'),
  Shortbow: SHORTBOW,
  Shortsword: SHORTSWORD,
  Sickle: SICKLE,
  SimpleWeapons: [
    CLUB,
    DAGGER,
    DARTS,
    GREATCLUB,
    HANDAXE,
    JAVELIN,
    LIGHT_CROSSBOW,
    LIGHT_HAMMER,
    MACE,
    QUARTERSTAFF,
    SHORTBOW,
    SICKLE,
    SLING,
    SPEAR,
  ],
  Sling: SLING,
  Spear: SPEAR,

  ThievesTools: new Proficiency("Thieve's Tools"),
  Trident: TRIDENT,

  UnarmedStrike: new Proficiency('Unarmed Strike'),

  Viol: new Proficiency('Viol'),

  Warhammer: WARHAMMER,
  WarPick: WAR_PICK,
  Whip: WHIP,
  WoodcarversTools: new Proficiency("Woodcarver's Tools"),
};

export default Proficiencies;
