import assert from '../Common/Assert.js';
import { UnfoldArray } from '../Common/ArrayUtilities.js';

import Ability from './Ability.js';
import AbilityType from './AbilityType.js';
import Actions from './Actions/Actions.js';
import CarriedItem from './CarriedItem.js';
import StaticEffect from './StaticEffect.js';
import Feat from './Feat.js';
import Feats from './Feats.js';
import Item from './Item.js';
import Proficiencies from './Proficiencies.js';
import Proficiency from './Proficiency.js';
import Race from './Race.js';
import Skill from './Skill.js';
import SkillType from './SkillType.js';
import Spell from './Spells/Spell.js';

import Roll from './Roll.js';

class Character {
  constructor(
    id,
    name,
    portrait,
    age,
    height,
    weight,
    hair,
    eyes,
    skin,
    marks,
    descriptionURL,
    personalityTraits,
    languages,
    race,
    charClass,
    level,
    str,
    dex,
    con,
    int,
    wis,
    cha,
    feats,
    skills,
    proficiencies,
    spells,
    items
  ) {
    assert(race != null && race instanceof Race, 'Invalid race');

    this._id = id;
    this._name = name;
    this._portrait = portrait;

    this._age = age;
    this._height = height;
    this._weight = weight;

    this._hair = hair;
    this._eyes = eyes;
    this._skin = skin;
    this._marks = marks;

    this._descriptionURL = descriptionURL;

    this._personalityTraits = personalityTraits;

    this._languages = race.languages.concat(languages);

    this._race = race;
    this._class = charClass;
    this._level = level;

    this._abilities = [
      new Ability(AbilityType.STR, str),
      new Ability(AbilityType.DEX, dex),
      new Ability(AbilityType.CON, con),
      new Ability(AbilityType.INT, int),
      new Ability(AbilityType.WIS, wis),
      new Ability(AbilityType.CHA, cha),
    ];

    this._skills = [];
    SkillType.LIST.forEach((skillType) => {
      this._skills.push(new Skill(skillType));
    });
    this._feats = [];
    this._spells = [];
    this._proficiencies = [Proficiencies.UnarmedStrike];
    this._items = [];

    this.trainFeats(feats);
    this.trainSkills(skills);
    this.learnProficiencies(proficiencies);
    this.learnSpells(spells);
    this.addItems(items);
    this.enhancers.forEach((enhancer) => {
      this.trainSkills(enhancer.skills);
      this._languages = this._languages.concat(enhancer.languages);
    });

    // Note: This assumes constitution has never changed when leveling up
    var baseMaxHP =
      charClass.hitDiceSides +
      level * this.abilityModifier(AbilityType.CON) +
      (level - 1) * (Math.floor(charClass.hitDiceSides / 2) + 1);
    this._maxHP = StaticEffect.applyToMaxHP(baseMaxHP, level, this.effects);
  }

  get id() {
    return this._id;
  }
  get name() {
    return this._name;
  }
  get portrait() {
    return this._portrait;
  }

  get age() {
    return this._age;
  }
  get height() {
    return this._height;
  }
  get weight() {
    return this._weight;
  }

  get hair() {
    return this._hair;
  }
  get eyes() {
    return this._eyes;
  }
  get skin() {
    return this._skin;
  }
  get marks() {
    return this._marks;
  }

  get descriptionURL() {
    return this._descriptionURL;
  }

  get personalityTraits() {
    return this._personalityTraits;
  }

  get languages() {
    return this._languages;
  }

  get race() {
    return this._race;
  }
  get characterClass() {
    return this._class;
  }
  get level() {
    return this._level;
  }

  get spellSlots() {
    return this._class.spellSlots(this.level);
  }
  get powerPoints() {
    return this._class.powerPoints(this);
  }

  get maxHP() {
    return this._maxHP;
  }
  get initiative() {
    return this.abilityCheckModifier(AbilityType.DEX);
  }
  get speed() {
    return StaticEffect.calculateSpeed(this);
  }
  get ac() {
    return StaticEffect.calculateAC(this);
  }

  get spells() {
    return this._spells;
  }

  get enhancers() {
    var enhancers = [];
    enhancers = enhancers.concat(this.feats);
    this.items.forEach((item) => {
      if (item.isActive && (item.isAttuned || !item.item.requiresAttunement)) {
        enhancers.push(item.item);
      }
    });
    return enhancers;
  }

  get effects() {
    var effects = [];
    this.enhancers.forEach((enhancer) => {
      effects = effects.concat(enhancer.effects);
    });
    return effects;
  }

  get actions() {
    var actions = [Actions.SHORT_REST, Actions.LONG_REST];
    if (
      this.abilityModifier(AbilityType.STR) > -1 ||
      (this.hasMartialArts && this.abilityModifier(AbilityType.DEX) > -1)
    ) {
      if (this.hasMartialArts) {
        actions.push(Actions.MARTIAL_ARTS);
      } else {
        actions.push(Actions.UNARMED_STRIKE);
      }
    }
    this.enhancers.forEach((enhancer) => {
      actions = actions.concat(enhancer.actions);
    });
    actions = actions.concat(this.spells);
    return actions;
  }

  get items() {
    return this._items;
  }

  get maxCarriedWeight() {
    return 15 * this.abilityScore(AbilityType.STR);
  }

  get carriedWeight() {
    return Math.ceil(
      this._items.reduce((weight, item) => {
        return weight + item.item.totalWeight;
      }, 0)
    );
  }

  get proficiencyBonus() {
    return Math.ceil(this.level / 4) + 1;
  }

  get nonProficiencyBonus() {
    if (this.hasFeat(Feats.JACK_OF_ALL_TRADES)) {
      return Math.floor(this.proficiencyBonus / 2);
    } else {
      return 0;
    }
  }

  get passivePerception() {
    return 10 + this.skillBonus(SkillType.PERCEPTION);
  }

  get abilities() {
    return this._abilities;
  }

  get feats() {
    return this.race.feats.concat(this.characterClass.feats(this.level)).concat(this._feats);
  }

  get spellAttackModifier() {
    return this.proficiencyBonus + this.spellAbilityModifier;
  }

  get spellAbilityModifier() {
    return this.abilityModifier(this.characterClass.spellAbility);
  }

  get spellSaveDC() {
    return 8 + this.proficiencyBonus + this.abilityModifier(this.characterClass.spellAbility);
  }

  ability(abilityType) {
    return this.abilities.find((ability) => {
      return ability.type === abilityType;
    });
  }

  abilityScore(abilityType) {
    return StaticEffect.applyToAbilityScore(
      abilityType,
      this.ability(abilityType).baseScore,
      this.effects
    );
  }

  abilityModifier(abilityType) {
    return Ability.modifier(this.abilityScore(abilityType));
  }

  abilityCheckModifier(abilityType, proficiencyLevel) {
    return (
      Ability.modifier(this.abilityScore(abilityType)) +
      (proficiencyLevel > 0 ? this.proficiencyBonus * proficiencyLevel : this.nonProficiencyBonus)
    );
  }

  abilitySavingThrowModifier(abilityType) {
    return (
      this.abilityModifier(abilityType) +
      (this.characterClass.isSavingThrowProficient(abilityType) ? this.proficiencyBonus : 0)
    );
  }

  trainFeats(...feats) {
    this._feats = this._feats.concat(UnfoldArray(feats, Feat));
  }

  get skills() {
    var skills = this._skills;
    // this.enhancers.forEach(enhancer => {
    //     skills = skills.concat(enhancer.skills);
    // });
    return skills;
  }

  skill(skillType) {
    return this.skills.find((skill) => skill.type === skillType);
  }

  trainSkills(...skillTypes) {
    UnfoldArray(skillTypes, SkillType).forEach((skillType) => {
      this.skill(skillType).train();
    });
  }

  expertiseSkills(...skillTypes) {
    UnfoldArray(skillTypes, SkillType).forEach((skillType) => {
      this.skill(skillType).gainExpertise();
    });
  }

  learnSpells(...spells) {
    this._spells = this._spells.concat(UnfoldArray(spells, Spell));
  }

  hasFeat(feat) {
    return this.feats.indexOf(feat) > -1;
  }

  get hasMartialArts() {
    return this.hasFeat(Feats.MARTIAL_ARTS) || this.hasFeat(Feats.HOMEBREW_MONKDRUID_MARTIAL_ARTS);
  }

  get hasTwoWeaponFighting() {
    return this.hasFeat(Feats.FIGHTING_STYLE_TWO_WEAPON_FIGHTING);
  }

  get isPactCaster() {
    return this.hasFeat(Feats.HOMEBREW_PACTFIGHTER_SPELLCASTING);
  }

  get proficiencies() {
    var proficiencies = this._proficiencies;
    this.enhancers.forEach((enhancer) => {
      proficiencies = proficiencies.concat(enhancer.proficiencies);
    });
    return proficiencies;
  }

  hasProficiency(proficiency) {
    return this.proficiencies.indexOf(proficiency) > -1;
  }

  learnProficiencies(...proficiencies) {
    this._proficiencies = this._proficiencies.concat(UnfoldArray(proficiencies, Proficiency));
  }

  addItems(...items) {
    UnfoldArray(items, [Item, CarriedItem]).forEach((item) => {
      if (item instanceof CarriedItem) {
        this._items.push(item);
      } else if (item instanceof Item) {
        this._items.push(new CarriedItem(item));
      } else {
        throw new Error('Invalid item type. This should have been prevented by UnfoldArray.');
      }
    });
  }

  skillModifier(skillType) {
    const skill = this.skill(skillType);
    return this.abilityCheckModifier(skill.type.abilityType, skill.proficiencyLevel);
  }

  skillCheck(skillType, rollType, opt_specialBonus) {
    var roll1 = Roll.d20();
    var roll2 = Roll.d20();
    var useFirst;
    var showSecond;
    if (rollType === Roll.Type.Advantage) {
      useFirst = roll1 >= roll2;
      showSecond = true;
    } else if (rollType === Roll.Type.Disadvantage) {
      useFirst = roll1 <= roll2;
      showSecond = true;
    } else {
      useFirst = true;
      showSecond = false;
    }
    var score = useFirst ? roll1 : roll2;
    var result = '';
    if (!useFirst) {
      result += '[' + roll1 + ']';
    } else {
      result += roll1;
    }
    if (showSecond) {
      if (useFirst) {
        result += '[' + roll2 + ']';
      } else {
        result += roll2;
      }
    }
    var skillModifier = this.skillModifier(skillType);
    score += skillModifier;
    if (skillModifier >= 0) {
      result += ' + ' + skillModifier;
    } else {
      result += ' - ' + -skillModifier;
    }
    if (opt_specialBonus) {
      score += opt_specialBonus;
      if (opt_specialBonus >= 0) {
        result += ' + ' + opt_specialBonus;
      } else {
        result += ' - ' + -opt_specialBonus;
      }
    }
    result += ' = ' + score;
    return result;
  }
}

export default Character;
