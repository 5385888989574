import Action from './Action.js';
import AreaOfEffects from './AreaOfEffects.js';
import Durations from './Durations.js';
import Effects from './Effects.js';
import Ranges from './Ranges.js';
import Sort from './Sort.js';
import Times from './Times.js';
import ToHits from './ToHits.js';
import Types from './Types.js';
import Utilities from './Utilities.js';

import { C } from '../Spells/Components.js';
import Roll from '../Roll.js';

import LONG_REST from './Common/LongRest.js';
import SHORT_REST from './Common/ShortRest.js';
import UNARMED_STRIKE from './Common/UnarmedStrike.js';

import WILD_COMPANION from './Class/Druid/WildCompanion.js';
import WILD_SHAPE from './Class/Druid/WildShape.js';

import MARTIAL_ARTS from './Class/Monk/MartialArts.js';


const Actions = {
  // Common Actions
  LONG_REST,
  SHORT_REST,
  UNARMED_STRIKE,

  // Monk
  MARTIAL_ARTS,

  // TODO: Indicate quantity per short/long rest better
  FEY_STEP: new Action(
    'Fey Step',
    Types.OncePerShortRestPower,
    Sort.Power,
    [
      'Briefly surrounded by silvery mist, you teleport up to 30 feet to an unoccupied space that you can see.',
      'You can use this power once per short or long rest.',
    ],
    [Utilities.Combat, Utilities.NonCombat],
    Times.Bonus,
    Durations.Instantaneous,
    Ranges.Range(30),
    AreaOfEffects.SingleTarget,
    ToHits.AlwaysWorks,
    Effects.Teleport(),
    null /* requires */
  ),

  RAGE: new Action(
    'Rage',
    Types.Spell('1 Rage Use'),
    Sort.Power,
    [
      'In battle, you fight with primal ferocity. On your turn, you can enter a rage as a bonus action.',
      'While raging, you gain the following benefits if you aren’t wearing heavy armor:',
      '* You have advantage on Strength checks and Strength saving throws.',
      '* When you make a melee weapon attack using Strength, you gain a bonus to the damage roll that increases as you gain levels as a barbarian, as shown in the Rage Damage column of the Barbarian table.',
      '* You have resistance to bludgeoning, piercing, and slashing damage.',
      'If you are able to cast spells, you can’t cast them or concentrate on them while raging.',
      'Your rage lasts for 1 minute. It ends early if you are knocked unconscious or if your turn ends and you haven’t attacked a hostile creature since your last turn or taken damage since then. You can also end your rage on your turn as a bonus action.',
      'Once you have raged the number of times shown for your barbarian level in the Rages column of the Barbarian table, you must finish a long rest before you can rage again.',
    ],
    [Utilities.Combat],
    Times.Bonus,
    Durations.Minutes(1),
    Ranges.Self,
    AreaOfEffects.SingleTarget,
    ToHits.AlwaysWorks,
    Effects.Rage(),
    null /* requires */
  ),

  RECKLESS_ATTACK: new Action(
    'Reckless Attack',
    Types.AtWillPower,
    Sort.Power,
    [
      'When you make your first attack on your turn, you can decide to attack recklessly, giving you advantage on melee weapon attack rolls using STR during this turn, but attack rolls against you have advantage until your next turn.',
    ],
    [Utilities.Combat],
    Times.DuringAttack,
    Durations.Instantaneous,
    Ranges.Self,
    AreaOfEffects.SingleTarget,
    ToHits.AlwaysWorks,
    Effects.Buff(true),
    null /* requires */
  ),

  DANGER_SENSE: new Action(
    'Danger Sense',
    Types.AtWillPower,
    Sort.AlwaysActivePower,
    [
      'You have advantage on DEX saving throws against effects that you can see while not blinded, deafened, or incapacitated.',
    ],
    [Utilities.Combat],
    Times.AlwaysActive,
    Durations.Instantaneous,
    Ranges.Self,
    AreaOfEffects.SingleTarget,
    ToHits.AlwaysWorks,
    Effects.Buff(true),
    null /* requires */
  ),

  BARDIC_INSPIRATION: new Action(
    'Bardic Inspiration',
    Types.Spell('1 Inspiration Use'),
    Sort.Power,
    [
      'You can inspire others through stirring words or music. To do so, you use a bonus action on your turn to choose one creature other than yourself within 60 feet of you who can hear you. That creature gains one Bardic Inspiration die, a d6.',
      'Once within the next 10 minutes, the creature can roll the die and add the number rolled to one ability check, attack roll, or saving throw it makes. The creature can wait until after it rolls the d20 before deciding to use the Bardic Inspiration die, but must decide before the DM says whether the roll succeeds or fails. Once the Bardic Inspiration die is rolled, it is lost. A creature can have only one Bardic Inspiration die at a time.',
      'You can use this feature a number of times equal to your Charisma modifier (a minimum of once). You regain any expended uses when you finish a long rest.',
      'Your Bardic Inspiration die changes when you reach certain levels in this class. The die becomes a d8 at 5th level, a d10 at 10th level, and a d12 at 15th level.',
    ],
    [Utilities.Combat, Utilities.NonCombat],
    Times.Bonus,
    Durations.Instantaneous,
    Ranges.Range(60),
    AreaOfEffects.SingleTarget,
    ToHits.AlwaysWorks,
    Effects.Inspire(),
    null /* requires */
  ),

  SONG_OF_REST: new Action(
    'Song of Rest',
    Types.OncePerShortRestPower,
    Sort.Power,
    [
      'You can use soothing music or oration to help revitalize your wounded allies during a short rest. If you or any friendly creatures who can hear your performance regain hit points at the end of the short rest by spending one or more Hit Dice, each of those creatures regains an extra 1d6 hit points.',
      'The extra hit points increase when you reach certain levels in this class: to 1d8 at 9th level, to 1d10 at 13th level, and to 1d12 at 17th level.',
    ],
    [Utilities.Rest],
    Times.ShortRest,
    Durations.Instantaneous,
    Ranges.Self,
    AreaOfEffects.SingleTarget,
    ToHits.AlwaysWorks,
    Effects.Recover(true),
    null /* requires */
  ),

  WILD_SHAPE,
  WILD_COMPANION,

  NATURAL_RECOVERY: new Action(
    'Natural Recovery',
    Types.OncePerLongRestPower,
    Sort.Power,
    [
      'You regain spell slots that have a combined level that is equal to or less than half your druid level (rounded up), and none of the slots can be 6th level or higher.',
      'You can use this at most once per long rest.',
    ],
    [Utilities.Rest],
    Times.ShortRest,
    Durations.Instantaneous,
    Ranges.Self,
    AreaOfEffects.SingleTarget,
    ToHits.AlwaysWorks,
    Effects.Recover(true),
    null /* requires */
  ),

  SPIRIT_TOTEM: new Action(
    'Spirit Totem',
    Types.OncePerShortRestPower,
    Sort.Power,
    ['You summon a Spirit Totem. See the Spirit Totem Feat for details.'],
    [Utilities.Combat, Utilities.NonCombat],
    Times.Bonus,
    Durations.Minutes(1),
    Ranges.Range(60),
    AreaOfEffects.SingleTarget,
    ToHits.AlwaysWorks,
    Effects.Summon(true),
    null /* requires */
  ),

  SECOND_WIND: new Action(
    'Second Wind',
    Types.OncePerShortRestPower,
    Sort.Power,
    [
      'On your turn, you can use a bonus action to regain hit points equal to 1d10 + your fighter level.',
    ],
    [Utilities.Combat],
    Times.Bonus,
    Durations.Instantaneous,
    Ranges.Self,
    AreaOfEffects.SingleTarget,
    // TODO Add + your fighter level
    ToHits.AlwaysWorks,
    Effects.Heal(
      Roll.d(10),
      false /* useSpellModifier */,
      false /* extraDetails */,
      (function() {
        var bonus = {};
        for (var level = 1; level <= 20; level++) {
          bonus[level] = Roll.constant(1);
        }
        return bonus;
      })()
    ),
    null /* requires */
  ),

  ACTION_SURGE: new Action(
    'Action Surge',
    Types.OncePerShortRestPower,
    Sort.Power,
    ['You summon a Spirit Totem. See the Spirit Totem Feat for details.'],
    [Utilities.Combat],
    Times.Action,
    Durations.Instantaneous,
    Ranges.Self,
    AreaOfEffects.SingleTarget,
    ToHits.AlwaysWorks,
    Effects.Recover(true),
    null /* requires */
  ),

  WEAPON_BOND_SUMMON: new Action(
    'Summon Bound Weapon',
    Types.AtWillPower,
    Sort.Power,
    ['You summon one of your bonded weapons. See the Weapon Bond Feat for details.'],
    [Utilities.Combat],
    Times.Bonus,
    Durations.Instantaneous,
    Ranges.Self,
    AreaOfEffects.SingleTarget,
    ToHits.AlwaysWorks,
    Effects.Summon(true),
    null /* requires */
  ),

  MINOR_ALCHEMY: new Action(
    'Minor Alchemy',
    Types.AtWillPower,
    Sort.Power,
    [
      'You can temporarily alter the physical properties of one nonmagical object, changing it from one substance into another. You perform a special alchemical procedure on one object composed entirely of wood, stone (but not a gemstone), iron, copper, or silver, transforming it into a different one of those materials. For each 10 minutes you spend performing the procedure, you can transform up to 1 cubic foot of material. After 1 hour, or until you lose your concentration (as if you were concentrating on a spell), the material reverts to its original substance.',
    ],
    Utilities.NonCombat,
    Times.Minutes(10),
    Durations.Hours(1),
    Ranges.Touch,
    AreaOfEffects.Cube(1),
    ToHits.AlwaysWorks,
    Effects.Control(true),
    C /* requires */
  ),

  TRANSMUTERS_STONE: new Action(
    "Transmuter's Stone",
    Types.AtWillPower,
    Sort.Power,
    [
      'You can spend 8 hours creating a transmuter’s stone that stores transmutation magic. You can benefit from the stone yourself or give it to another creature. A creature gains a benefit of your choice as long as the stone is in the creature’s possession. When you create the stone, choose the benefit from the following options:',
      '* Darkvision out to a range of 60 feet',
      '* An increase to speed of 10 feet while the creature is unencumbered',
      '* Proficiency in Constitution saving throws',
      '* Resistance to acid, cold, fire, lightning, or thunder damage (your choice whenever you choose this benefit)',
      'Each time you cast a transmutation spell of 1st level or higher, you can change the effect of your stone if the stone is on your person.',
      'If you create a new transmuter’s stone, the previous one ceases to function.',
    ],
    Utilities.NonCombat,
    Times.Hours(8),
    Durations.NA,
    Ranges.Self,
    AreaOfEffects.SingleTarget,
    ToHits.AlwaysWorks,
    Effects.Create(true),
    null /* requires */
  ),
};

export default Actions;
