import AbilityType from './AbilityType.js';
import Feats from './Feats.js';
import Languages from './Languages.js';
import Race from './Race.js';
import Size from './Size.js';

const DWARF = new Race(
  'Dwarf',
  25,
  Size.MEDIUM,
  [
    Feats.ABILITY_SCORE_INCREASE(AbilityType.CON, 2),
    Feats.DARKVISION,
    Feats.DWARVEN_RESILIENCE,
    Feats.DWARVEN_COMBAT_TRAINING,
    Feats.DWARF_TOOL_PROFICIENCY,
    Feats.STONECUNNING,
  ],
  [Languages.COMMON, Languages.DWARVISH]
);

const ELF = new Race(
  'Elf',
  30,
  Size.MEDIUM,
  [
    Feats.ABILITY_SCORE_INCREASE(AbilityType.DEX, 2),
    Feats.DARKVISION,
    Feats.KEEN_SENSES,
    Feats.FEY_ANCESTRY,
    Feats.TRANCE,
  ],
  [Languages.COMMON, Languages.ELVISH]
);

const Races = {
  DWARF: DWARF,
  DWARF_HILL: new Race(
    'Hill Dwarf',
    DWARF.speed,
    DWARF.size,
    DWARF.feats.concat([Feats.ABILITY_SCORE_INCREASE(AbilityType.WIS, 1), Feats.DWARVEN_TOUGHNESS]),
    DWARF.languages
  ),
  DWARF_MOUNTAIN: new Race(
    'Mountain Dwarf',
    DWARF.speed,
    DWARF.size,
    DWARF.feats.concat([
      Feats.ABILITY_SCORE_INCREASE(AbilityType.STR, 2),
      Feats.DWARVEN_ARMOR_TRAINING,
    ]),
    DWARF.languages
  ),

  ELF: ELF,
  ELF_HIGH: new Race(
    'High Elf',
    ELF.speed,
    ELF.size,
    ELF.feats.concat([
      Feats.ABILITY_SCORE_INCREASE(AbilityType.INT, 1),
      Feats.ELF_WEAPON_TRAINING,
      // Cantrip - You know one cantrip of your choice from the wizard spell list. Intelligence is your spellcasting ability for it.
      // Extra Language - You can speak, read, and write one extra language of your choice.
    ]),
    ELF.languages
  ),
  ELF_WOOD: new Race(
    'Wood Elf',
    ELF.speed,
    ELF.size,
    ELF.feats.concat([
      Feats.ABILITY_SCORE_INCREASE(AbilityType.WIS, 1),
      Feats.ELF_WEAPON_TRAINING,
      // Fleet of Foot - Your base walking speed increases to 35 feet.
      // Mask of the Wild - You can attempt to hide even when you are only lightly obscured by foliage, heavy rain, falling snow, mist, and other natural phenomena.
    ]),
    ELF.languages
  ),
  ELF_DROW: new Race(
    'Dark Elf (Drow)',
    ELF.speed,
    ELF.size,
    ELF.feats.concat([
      Feats.ABILITY_SCORE_INCREASE(AbilityType.CHA, 1),
      // Superior Darkvision - Your darkvision has a radius of 120 feet.
      // Sunlight Sensitivity =- You have disadvantage on attack rolls and on Wisdom (Perception) checks that rely on sight when you, the target of your attack, or whatever you are trying to perceive is in direct sunlight.
      // Drow Magic - You know the dancing lights cantrip. When you reach 3rd level, you can cast the faerie fire spell once with this trait and regain the ability to do so when you finish a long rest. When you reach 5th level, you can cast the darkness spell once with this trait and regain the ability to do so when you finish a long rest. Charisma is your spellcasting ability for these spells.
      // Drow Weapon Training - You have proficiency with rapiers, shortswords, and hand crossbows.
    ]),
    ELF.languages
  ),
  ELF_ELADRIN: new Race(
    'Eladrin (Elf)',
    ELF.speed,
    ELF.size,
    ELF.feats.concat([
      Feats.ABILITY_SCORE_INCREASE(AbilityType.INT, 1),
      Feats.ELF_WEAPON_TRAINING,
      Feats.FEY_STEP,
    ]),
    ELF.languages
  ),

  HALF_ELF: new Race(
    'Half-Elf',
    30,
    Size.MEDIUM,
    [
      Feats.ABILITY_SCORE_INCREASE(AbilityType.CHA, 2),
      // Two other ability scores increase by 1
      Feats.DARKVISION,
      Feats.FEY_ANCESTRY,
      Feats.SKILL_VERSATILITY,
    ],
    [
      Languages.COMMON,
      Languages.ELVISH,
      // Plus one language of choice
    ]
  ),

  HUMAN: new Race(
    'Human',
    30,
    Size.MEDIUM,
    [
      Feats.ABILITY_SCORE_INCREASE(AbilityType.STR, 1),
      Feats.ABILITY_SCORE_INCREASE(AbilityType.DEX, 1),
      Feats.ABILITY_SCORE_INCREASE(AbilityType.CON, 1),
      Feats.ABILITY_SCORE_INCREASE(AbilityType.INT, 1),
      Feats.ABILITY_SCORE_INCREASE(AbilityType.WIS, 1),
      Feats.ABILITY_SCORE_INCREASE(AbilityType.CHA, 1),
    ],
    [
      Languages.COMMON,
      // Plus one language of choice
    ]
  ),
  HUMAN_VARIANT: new Race(
    'Human',
    30,
    Size.MEDIUM,
    [
      // Two ability score increases of 1, plus 1 skill and 1 feat
    ],
    [
      Languages.COMMON,
      // Plus one language of choice
    ]
  ),

  TIEFLING: new Race(
    'Tiefling',
    30,
    Size.MEDIUM,
    [
      Feats.ABILITY_SCORE_INCREASE(AbilityType.INT, 1),
      Feats.ABILITY_SCORE_INCREASE(AbilityType.CHA, 2),
      Feats.DARKVISION,
      Feats.HELLISH_RESISTANCE,
      Feats.INFERNAL_LEGACY,
    ],
    [Languages.COMMON, Languages.INFERNAL]
  ),
};

export default Races;
