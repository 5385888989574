import AbilityType from '../Character/AbilityType.js';
import CarriedItem from '../Character/CarriedItem.js';
import Character from '../Character/Character.js';
import Classes from '../Character/Classes.js';
import Feats from '../Character/Feats.js';
import Items from '../Character/Items.js';
import Proficiencies from '../Character/Proficiencies.js';
import SkillType from '../Character/SkillType.js';
import Spells from '../Character/Spells/Spells.js';

import RonPortrait from '../Portraits/RonPortrait.png';

import { LANGUAGES, RACE, LAST_NAME, WA_PREFIX, WA_SUFFIX, LEVEL } from './Common.js';

// prettier-ignore
const ron = new Character('ron',
    'Rohnn' + LAST_NAME, RonPortrait,
    45, '6\' 3"', '160 lbs', // AGE HEIGHT WEIGHT
    'Dark brown', 'Blue/Green', 'Light', 'Hair changes color in high-emotion situations', //	HAIR EYES SKIN MARKS
    WA_PREFIX + 'rohnn' + WA_SUFFIX,
    [
        'Styles his act according to his surroundings.',
        'Seems to always have a feel for his audience\'s emotions.',
        'A capable musician, poet, magician, comic, and storyteller.',
        'Would do anything for his family. Their happiness and safety are very important to him.',
        'There is tragedy in his past that he keeps to himself, while being afraid of that tragedy repeating. He has put his past behind him, and hopes it stays there.',
        'Has a disarming smile and projects confidence even when he is unsure of himself.',
        'Feels he could be wealthier and more successful, if only he had the chance.',
    ], LANGUAGES,
    RACE, Classes.COLLEGE_OF_TBD, LEVEL,
    10, 13, 12, 10, 13, 15, // STR DEX CON INT WIS CHA
    [
        // Background Feat
        Feats.BY_POPULAR_DEMAND,

        // Half Elf choices
        Feats.ABILITY_SCORE_INCREASE(AbilityType.DEX, 1),
        Feats.ABILITY_SCORE_INCREASE(AbilityType.WIS, 1),

        // Other Feats
    ], [
        // Half Elf Choices
        SkillType.DECEPTION,
        SkillType.PERSUASION,

        // Bard Choices
        SkillType.INTIMIDATION,
        SkillType.INSIGHT,
        SkillType.PERFORMANCE,

        /// Background Choices
        SkillType.ACROBATICS,
        SkillType.SLEIGHT_OF_HAND,
    ], [
        // Bard Choices
        Proficiencies.Lute,
        Proficiencies.Lyre,
        Proficiencies.Viol,

        // Background Choices
        Proficiencies.Horn,
        Proficiencies.DisguiseKit,

        // Bonus Choice
        Proficiencies.Drum,
    ], [
        // Prepared Spells
        Spells.MINOR_ILLUSION,
        Spells.VICIOUS_MOCKERY,
        Spells.CURE_WOUNDS,
        Spells.DISSONANT_WHISPERS,
        Spells.FEATHER_FALL,
        Spells.HEALING_WORD,

        // Level 2 Spells
        //Spells.DISGUISE_SELF,
        //Spells.EARTH_TREMOR,
        Spells.HIDEOUS_LAUGHTER,
        //Spells.SILENT_IMAGE,
        //Spells.THUNDERWAVE,
    ], [
        Items.Backpack,
        Items.Bedroll,
        Items.ClothesCommon(5),
        Items.ClothesFine(1),
        Items.Compass,
        Items.Costume(3),
        new CarriedItem(Items.CrossbowBoltCase, true /* isActive */ ),
        new CarriedItem(Items.CrossbowBolts(20), true /* isActive */ ),
        new CarriedItem(Items.LightCrossbow(), true /* isActive */ ),
        Items.DisguiseKit,
        new CarriedItem(Items.Gambeson(), true /* isActive */ ),
        Items.Horn,
        Items.Lute,
        Items.Lyre,
        new CarriedItem(Items.Rapier(), true /* isActive */ ),
        Items.Rations(5),
        Items.Rope50(1),
        Items.Tinderbox,
        Items.Torch(2),
        Items.Waterskin,
        Items.Viol,
    ]);
ron.expertiseSkills(SkillType.PERFORMANCE);
export default ron;
