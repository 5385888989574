import Type from './Type.js';

export default {
  MeleeAttack: new Type('Melee Attack'),
  RangedAttack: new Type('Ranged Attack'),
  ThrownAttack: new Type('Thrown Attack'),
  AtWillPower: new Type('At Will Power'),
  OncePerShortRestPower: new Type('Once Per Rest Power'),
  OncePerLongRestPower: new Type('Once Per Long Rest Power'),
  ItemPower: new Type('Item Power'),
  Spell: function(type) {
    return new Type(type);
  },
};
