import { VSMC } from '../Components.js';
import PointType from '../PointType.js';
import Spell from '../Spell.js';

import AreaOfEffects from '../../Actions/AreaOfEffects.js';
import Durations from '../../Actions/Durations.js';
import Effects from '../../Actions/Effects.js';
import Ranges from '../../Actions/Ranges.js';
import Times from '../../Actions/Times.js';
import ToHits from '../../Actions/ToHits.js';
import Utilities from '../../Actions/Utilities.js';

export default new Spell(
  'Dancing Lights',
  0,
  PointType.SpellSlot,
  [
    'You create up to four torch-sized lights within range, making them appear as torches, lanterns, or glowing orbs that hover in the air for the duration. You can also combine the four lights into one glowing vaguely humanoid form of Medium size. Whichever form you choose, each light sheds dim light in a 10- foot radius.',
    "As a bonus action on your turn, you can move the lights up to 60 feet to a new spot within range. A light must be within 20 feet of another light created by this spell, and a light winks out if it exceeds the spell's range.",
  ],
  Utilities.NonCombat,
  Times.Action,
  Durations.Minutes(1),
  Ranges.Range(120),
  AreaOfEffects.SingleTarget,
  ToHits.AlwaysWorks,
  Effects.Utility(),
  VSMC('a bit of phosphorus or wychwood, or a glowworm'),
  'Evocation'
);
