import React from 'react';

function InventoryDetails(props) {
  const character = props.character;
  const splitItems = props.splitItems;

  if (!character.items || character.items.length === 0) {
    return null;
  }

  const itemsAttuned = [];
  const itemsWorn = [];
  const itemsCarried = [];
  const itemsAll = [];
  character.items
    .concat()
    .sort((a, b) => {
      if (a.item.name < b.item.name) {
        return -1;
      } else if (a.item.name > b.item.name) {
        return 1;
      } else {
        return 0;
      }
    })
    .forEach((item, index) => {
      if (splitItems) {
        if (item.isAttuned) {
          itemsAttuned.push(<Item key={'attuned' + index} item={item} />);
        }
        if (item.isActive) {
          itemsWorn.push(<Item key={'worn' + index} item={item} />);
        } else {
          itemsCarried.push(<Item key={'carried' + index} item={item} />);
        }
      } else {
        itemsAll.push(<Item key={'all' + index} item={item} />);
      }
    });
  return (
    <div className="section InventoryDetails">
      <div className="title">Item Details</div>
      {itemsAll.length > 0 && <div className="Equipment All">{itemsAll}</div>}
      {itemsAttuned.length > 0 && (
        <div className="Equipment Attuned">
          <div className="title">Attuned</div>
          {itemsAttuned}
        </div>
      )}
      {itemsWorn.length > 0 && (
        <div className="Equipment Active">
          <div className="title">Active</div>
          {itemsWorn}
        </div>
      )}
      {itemsCarried.length > 0 && (
        <div className="Equipment Carried">
          <div className="title">Carried</div>
          {itemsCarried}
        </div>
      )}
    </div>
  );
}

function Item(props) {
  const item = props.item;
  return (
    <div className="Item">
      <div className="Name">{item.item.name}</div>
      <div className="Description">
        {item.item.description}
        {item.item.requiresAttunement && (
          <div className="RequiresAttunement">
            You must attune to this item before it can be used.
          </div>
        )}
        {item.item.requiresAttunement && item.isAttuned && (
          <div className="Attuned">You are attuned to this item.</div>
        )}
      </div>
    </div>
  );
}

export default InventoryDetails;
