class Dice {
  constructor(count, sides) {
    this._count = count;
    this._sides = sides;
  }

  get count() {
    return this._count;
  }

  get sides() {
    return this._sides;
  }

  get name() {
    return this._count + 'd' + this._sides;
  }

  static d(sides) {
    return new Dice(1, sides);
  }
}

export default Dice;
