import React from 'react';
import MissingPortrait from '../Portraits/MissingPortrait.png';
import Value from './Value.js';

function BasicDetails(props) {
  const character = props.character;
  return (
    <div className="section BasicDetails">
      <img
        className="Portrait"
        src={character.portrait || MissingPortrait}
        alt={'Portrait of ' + character.name}
      />
      <div className="evenlySpacedRow Details">
        <div className="Name">{character.name}</div>
        <div className="Race">{character.race.name}</div>
        <div className="Class">
          {character.characterClass.name} - Level {character.level}
        </div>
      </div>
      <div className="PhysicalAttributes">
        {character.age && (
          <div className="Age">
            <Value value={character.age} />
          </div>
        )}
        {character.height && (
          <div className="Height">
            <Value value={character.height} />
          </div>
        )}
        {character.weight && (
          <div className="Weight">
            <Value value={character.weight} />
          </div>
        )}
        {/* character.race && (character.weight || character.height) &&
              <div className="tertiary Size"><Value value={character.race.size.name}/></div>
            */}
        {character.hair && (
          <div className="Hair">
            <Value value={character.hair} />
          </div>
        )}
        {character.eyes && (
          <div className="Eyes">
            <Value value={character.eyes} />
          </div>
        )}
        {character.skin && (
          <div className="Skin">
            <Value value={character.skin} />
          </div>
        )}
        {character.marks && (
          <div className="Marks">
            <Value value={character.marks} />
          </div>
        )}
      </div>
    </div>
  );
}

export default BasicDetails;
