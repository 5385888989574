class AbilityType {
  constructor(name, abbreviation) {
    this._name = name;
    this._abbreviation = abbreviation;
    this._skillTypes = [];
  }
  get name() {
    return this._name;
  }
  get abbreviation() {
    return this._abbreviation;
  }
  addSkillType(skillType) {
    this._skillTypes.push(skillType);
  }
  get skillTypes() {
    return this._skillTypes;
  }
}
AbilityType.STR = new AbilityType('Strength', 'STR');
AbilityType.DEX = new AbilityType('Dexterity', 'DEX');
AbilityType.CON = new AbilityType('Constitution', 'CON');
AbilityType.INT = new AbilityType('Intelligence', 'INT');
AbilityType.WIS = new AbilityType('Wisdom', 'WIS');
AbilityType.CHA = new AbilityType('Charisma', 'CHA');
AbilityType.ALL = [
  AbilityType.STR,
  AbilityType.DEX,
  AbilityType.CON,
  AbilityType.INT,
  AbilityType.WIS,
  AbilityType.CHA,
];

export default AbilityType;
