import React from 'react';

import Value from './Value.js';
import Actions from './Actions.js';
import SpellSlots from './SpellSlots.js';

function NonCombat(props) {
  const character = props.character;
  const languages = character.languages.map((language) => (
    <Language key={language.name} language={language} />
  ));
  return (
    <div className="section NonCombat">
      <div className="title">Non-Combat</div>
      <div className="Basics">
        <div className="Speed">
          <Value value={character.speed + ' ft'} />
        </div>
        <div className="Languages">
          <span className="label">Languages: </span>
          {languages}
        </div>
      </div>
      <SpellSlots character={character} includeDetails={false} />
      <Actions
        character={character}
        includeCombat={false}
        includeNonCombat={true}
        includeDetails={false}
      />
    </div>
  );
}

function Language(props) {
  const language = props.language;
  return <Value value={language.name} />;
}

export default NonCombat;
