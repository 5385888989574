import { UnfoldArray } from '../Common/ArrayUtilities.js';
import AbilityType from './AbilityType.js';
import Actions from './Actions/Actions.js';
import StaticEffects from './StaticEffects.js';
import Feat from './Feat.js';
import Languages from './Languages.js';
import Spell from './Spells/Spell.js';
import Spells from './Spells/Spells.js';
import Proficiency from './Proficiency.js';
import Proficiencies from './Proficiencies.js';
import SkillType from './SkillType.js';

// TODO: Convert spell slots to points
const PROFICIENCIES = function(name, proficiencies) {
  proficiencies = UnfoldArray(proficiencies, Proficiency);
  return new Feat(
    name,
    [
      'You gain the following proficiencies: ' +
        proficiencies.map((proficiency) => proficiency.name).join(', '),
    ],
    true /* isTrivial */,
    proficiencies
  );
};
const SKILLS = function(name, skills) {
  skills = UnfoldArray(skills, SkillType);
  return new Feat(
    name,
    ['You gain the following skills: ' + skills.map((skill) => skill.name).join(', ')],
    true /* isTrivial */,
    skills
  );
};

const SPELLS = function(name, spells) {
  spells = UnfoldArray(spells, Spell);
  return new Feat(
    name,
    ['You gain access to the following spells: ' + spells.map((spell) => spell.name).join(', ')],
    true /* isTrivial */,
    spells
  );
};

const Feats = {
  // -------------------
  // Common
  ABILITY_SCORE_INCREASE: function(abilityType, amount) {
    return new Feat(
      'Ability Score Increase',
      ['Your ' + abilityType.name + ' score increases by ' + amount + '.'],
      true /* isTrivial */,
      [StaticEffects.ABILITY_SCORE_INCREASE(abilityType, amount)]
    );
  },
  PROFICIENCIES: PROFICIENCIES,
  SKILLS: SKILLS,
  SPELLS: SPELLS,
  DARKVISION: new Feat(
    'Darkvision',
    [
      'You can see in dim light within 60 feet of you as if it were bright light, and in darkness as if it were dim light. You can’t discern color in darkness, only shades of gray.',
    ],
    false /* isTrivial */
  ),

  // -------------------
  // Backgrounds
  RUSTIC_HOSPITALITY: new Feat(
    'Rustic Hospitality',
    [
      'Since you come from the ranks of the common folk, you fit in among them with ease. You can find a place to hide, rest, or recuperate among other commoners, unless you have shown yourself to be a danger to them. They will shield you from the law or anyone else searching for you, though they will not risk their lives for you.',
    ],
    false /* isTrivial */
  ),

  HOMEBREW_FRIENDLYFACE: new Feat(
    'Friendly Face',
    [
      "Your willingness to help others tends to earn you favors in return. You find it easier to find a place to hide, rest, or recuperate in areas where you have helped others in the past. Those offering shelter will often shield you from the law or anyone else searching for you, as long as doing so doesn't put them in too much risk.",
    ],
    false /* isTrivial */
  ),

  HOMEBREW_TIILA_TRAINING_DRUIDCRAFT: SPELLS("Tii'la's training (Spells)", [Spells.DRUIDCRAFT]),
  HOMEBREW_TIILA_TRAINING_AC: new Feat(
    "Tii'la's training (AC)",
    [
      'While not wearing armor and not using a shield, your AC equals 10 + your Dexterity modifier + your Wisdom modifier.',
    ],
    false /* isTrivial */,
    [StaticEffects.UNARMORED_DEFENSE_MONK]
  ),
  HOMEBREW_CITY_SECRETS_ALT_C: new Feat(
    'Mind the Flow',
    [
      'Your days spent watching people has made you quick to pick up on the patterns and flow of people moving through cities and towns. You easily pick up on who knows where they are going, and can find passages through the urban sprawl that most others would miss. When you are not in combat, you (and companions you lead) can travel between any two locations in a city or town twice as fast as your speed would normally allow.',
    ],
    false /* isTrivial */
  ),
  HOMEBREW_CITY_SECRETS_ALT_J: new Feat(
    'Blending In',
    [
      'Your life as an orphan on the run has helped you pick up on the patterns and flow of people moving through cities and towns. You easily pick up on where people are going, and where they are likely to look. When not in combat, you (and companions you lead) can travel between any two locations in a city or town with advantage on stealth checks.',
    ],
    false /* isTrivial */
  ),

  BY_POPULAR_DEMAND: new Feat(
    'By Popular Demand',
    [
      'You can always find a place to perform, usually in an inn or tavern but possibly with a circus, at a theater, or even in a noble’s court. At such a place, you receive free lodging and food of a modest or comfortable standard (depending on the quality of the establishment), as long as you perform each night. In addition, your performance makes you something of a local figure. When strangers recognize you in a town where you have performed, they typically take a liking to you.',
    ],
    false /* isTrivial */
  ),

  WANDERER: new Feat(
    'Wanderer',
    [
      'You have an excellent memory for maps and geography, and you can always recall the general layout of terrain, settlements, and other features around you. In addition, you can find food and fresh water for yourself and up to five other people each day, provided that the land offers berries, small game, water, and so forth.',
    ],
    false /* isTrivial */
  ),

  RESEARCHER: new Feat(
    'Researcher',
    [
      'When you attempt to learn or recall a piece of lore, if you do not know that information, you often know where and from whom you can obtain it. Usually, this information comes from a library, scriptorium, university, or a sage or other learned person or creature. Your DM might rule that the knowledge you seek is secreted away in an almost inaccessible place, or that it simply cannot be found. Unearthing the deepest secrets of the multiverse can require an adventure or even a whole campaign.',
    ],
    false /* isTrivial */
  ),

  // -------------------
  // Dwarf
  DWARVEN_RESILIENCE: new Feat(
    'Dwarven Resilience',
    [
      'You have advantage on saving throws against poison, and you have resistance against poison damage',
    ],
    false /* isTrivial */
  ),
  DWARVEN_COMBAT_TRAINING: PROFICIENCIES('Dwarven Combat Training', [
    Proficiencies.Battleaxe,
    Proficiencies.Handaxe,
    Proficiencies.LightHammer,
    Proficiencies.Warhammer,
  ]),
  DWARF_TOOL_PROFICIENCY: new Feat(
    'Tool Proficiency',
    [
      'You gain proficiency with the artisan’s tools of your choice: smith’s tools, brewer’s supplies, or mason’s tools.',
    ],
    true /* isTrivial */
  ),
  STONECUNNING: new Feat(
    'Stonecunning',
    [
      'Whenever you make an Intelligence (History) check related to the origin of stonework, you are considered proficient in the History skill and add double your proficiency bonus to the check, instead of your normal proficiency bonus.',
    ],
    false /* isTrivial */
  ),
  DWARVEN_TOUGHNESS: new Feat(
    'Dwarven Toughness',
    ['Your hit point maximum increases by 1, and it increases by 1 every time you gain a level.'],
    false /* isTrivial */,
    [StaticEffects.MAX_HP_BONUS_PER_LEVEL(1)]
  ),
  DWARVEN_ARMOR_TRAINING: PROFICIENCIES('Dwarven Armor Training', [
    Proficiencies.LightArmor,
    Proficiencies.MediumArmor,
  ]),

  // -------------------
  // Elf
  KEEN_SENSES: SKILLS('Keen Senses', [SkillType.PERCEPTION]),
  FEY_ANCESTRY: new Feat(
    'Fey Ancestry',
    [
      'You have advantage on saving throws against being charmed, and magic can’t put you to sleep.',
    ],
    false /* isTrivial */
  ),
  TRANCE: new Feat(
    'Trance',
    [
      'Elves don’t need to sleep. Instead, they meditate deeply, remaining semiconscious, for 4 hours a day. (The Common word for such meditation is “trance.”) While meditating, you can dream after a fashion; such dreams are actually mental exercises that have become reflexive through years of practice. After resting in this way, you gain the same benefit that a human does from 8 hours of sleep.',
    ],
    false /* isTrivial */
  ),
  ELF_WEAPON_TRAINING: PROFICIENCIES('Elf Weapon Training', [
    Proficiencies.Longsword,
    Proficiencies.Shortsword,
    Proficiencies.Shortbow,
    Proficiencies.Longbow,
  ]),
  FEY_STEP: new Feat(
    'Fey Step',
    [
      'You can cast the misty step spell once using this trait. You regain the ability to do so when you finish a short or long rest.',
    ],
    false /* isTrivial */,
    [Actions.FEY_STEP]
  ),

  // -------------------
  // Half-elf
  SKILL_VERSATILITY: new Feat(
    'Skill Versatility',
    ['You gain proficiency in two skills of your choice.'],
    true /* isTrivial */
  ),

  // -------------------
  // Tiefling
  HELLISH_RESISTANCE: new Feat(
    'Hellish Resistance',
    ['You have resistance to fire damage.'],
    false /* isTrivial */
  ),
  INFERNAL_LEGACY: new Feat(
    'Infernal Legacy',
    [
      'You know the thaumaturgy cantrip.',
      'When you reach 3rd level, you can cast the hellish rebuke spell as a 2nd-level spell once with this trait per long rest.',
      'When you reach 5th level, you can cast the darkness spell once with this trait per long rest. Charisma is your spellcasting ability for these spells.',
    ],
    false /* isTrivial */
  ),

  // -------------------
  // Barbarian
  RAGE: new Feat(
    'Rage',
    [
      'In battle, you fight with primal ferocity. On your turn, you can enter a rage as a bonus action.',
      'While raging, you gain the following benefits if you aren’t wearing heavy armor:',
      '* You have advantage on Strength checks and Strength saving throws.',
      '* When you make a melee weapon attack using Strength, you gain a bonus to the damage roll that increases as you gain levels as a barbarian, as shown in the Rage Damage column of the Barbarian table.',
      '* You have resistance to bludgeoning, piercing, and slashing damage.',
      'If you are able to cast spells, you can’t cast them or concentrate on them while raging.',
      'Your rage lasts for 1 minute. It ends early if you are knocked unconscious or if your turn ends and you haven’t attacked a hostile creature since your last turn or taken damage since then. You can also end your rage on your turn as a bonus action.',
      'Once you have raged the number of times shown for your barbarian level in the Rages column of the Barbarian table, you must finish a long rest before you can rage again.',
    ],
    false /* isTrivial */,
    [Actions.RAGE]
  ),
  UNARMORED_DEFENSE_BARBARIAN: new Feat(
    'Unarmored Defense',
    [
      'While you are not wearing any armor, your AC equals 10 + your Dexterity modifier + your Constitution modifier.',
      'You can use a shield and still gain this benefit.',
    ],
    false /* isTrivial */,
    [StaticEffects.UNARMORED_DEFENSE_BARBARIAN]
  ),
  RECKLESS_ATTACK: new Feat(
    'Reckless Attack',
    [
      'When you make your first attack on your turn, you can decide to attack recklessly, giving you advantage on melee weapon attack rolls using STR during this turn, but attack rolls against you have advantage until your next turn.',
    ],
    false /* isTrivial */,
    [Actions.RECKLESS_ATTACK]
  ),
  DANGER_SENSE: new Feat(
    'Danger Sense',
    [
      'You have advantage on DEX saving throws against effects that you can see while not blinded, deafened, or incapacitated.',
    ],
    false /* isTrivial */,
    [Actions.DANGER_SENSE]
  ),

  // -------------------
  // Bard
  BARDIC_INSPIRATION: new Feat(
    'Bardic Inspiration',
    [
      'You can inspire others through stirring words or music. To do so, you use a bonus action on your turn to choose one creature other than yourself within 60 feet of you who can hear you. That creature gains one Bardic Inspiration die, a d6.',
      'Once within the next 10 minutes, the creature can roll the die and add the number rolled to one ability check, attack roll, saving throw, healing roll, or damage roll it makes. The creature can wait until after it rolls the d20 before deciding to use the Bardic Inspiration die, but must decide before the DM says whether the roll succeeds or fails. Once the Bardic Inspiration die is rolled, it is lost. A creature can have only one Bardic Inspiration die at a time.',
      'You can use this feature a number of times equal to your Charisma modifier (a minimum of once). You regain any expended uses when you finish a long rest.',
      'Your Bardic Inspiration die changes when you reach certain levels in this class. The die becomes a d8 at 5th level, a d10 at 10th level, and a d12 at 15th level.',
    ],
    false /* isTrivial */,
    [Actions.BARDIC_INSPIRATION]
  ),

  BARDIC_SPELLCASTING: new Feat(
    'Spellcasting',
    [
      'You have learned to untangle and reshape the fabric of reality in harmony with your wishes and music. Your spells are part of your vast repertoire, magic that you can tune to different situations.',
    ],
    true /* isTrivial */
  ),

  JACK_OF_ALL_TRADES: new Feat(
    'Jack of All Trades',
    [
      'You can add half your proficiency bonus, rounded down, to any ability check you make that doesn’t already include your proficiency bonus.',
    ],
    false /* isTrivial */,
    [
      // TODO: Add this effect!
    ]
  ),

  SONG_OF_REST: new Feat(
    'Song of Rest',
    [
      'You can use soothing music or oration to help revitalize your wounded allies during a short rest. If you or any friendly creatures who can hear your performance regain hit points at the end of the short rest by spending one or more Hit Dice, each of those creatures regains an extra 1d6 hit points.',
      'The extra hit points increase when you reach certain levels in this class: to 1d8 at 9th level, to 1d10 at 13th level, and to 1d12 at 17th level.',
    ],
    false /* isTrivial */,
    [Actions.SONG_OF_REST]
  ),

  FONT_OF_INSPIRATION: new Feat(
    'Font of Inspiration',
    [
      'You regain all of your expended uses of Bardic Inspiration when you finish a short or long rest.',
    ],
    false /* isTrivial */
  ),

  // -------------------
  // Druid
  DRUIDIC: new Feat(
    'Druidic',
    [
      'You know Druidic, the secret language of druids. You can speak the language and use it to leave hidden messages. You and others who know this language automatically spot such a message. Others spot the message’s presence with a successful DC 15 Wisdom (Perception) check but can’t decipher it without magic.',
    ],
    true /* isTrivial */,
    [Languages.DRUIDIC]
  ),
  DRUID_SPELLCASTING: new Feat(
    'Spellcasting',
    [
      'Drawing on the divine essence of nature itself, you can cast spells to shape that essence to your will.',
    ],
    true /* isTrivial */
  ),

  RITUAL_CASTING_DRUID: new Feat(
    'Ritual Casting (Druid)',
    [
      'You can cast a druid spell as a ritual if that spell has the ritual tag and you have the spell prepared.',
    ],
    true /* isTrivial */
  ),

  SPELLCASTING_FOCUS_DRUID: new Feat(
    'Spellcasting Focus (Druid)',
    [
      'You can use a druidic focus as a spellcasting focus for your druid spells.',
      'Example Druidic Focuses: Sprig of mistletoe, Totem, Wooden staff, Yew wand',
    ],
    true /* isTrivial */
  ),

  WILD_SHAPE: new Feat(
    'Wild Shape',
    [
      'Starting at 2nd level, you can use your action to magically assume the shape of a beast that you have seen before. You can use this feature twice. You regain expended uses when you finish a short or long rest.',
      'Your druid level determines the beasts you can transform into.',
      'Level 2-3: Max CR 1/4, no flying or swimming speed (ex: Wolf)',
      'Level 4-7: Max CR 1/2, no flying speed (ex: Crocodile)',
      'Level 8+: Max CR 1 (ex: Giant eagle)',
      'You can stay in a beast shape for a number of hours equal to half your druid level (rounded down). You then revert to your normal form unless you expend another use of this feature. You can revert to your normal form earlier by using a bonus action on your turn. You automatically revert if you fall unconscious, drop to 0 hit points, or die.',
      'While you are transformed, the following rules apply:',
      '* Your game statistics are replaced by the statistics of the beast, but you retain your alignment, personality, and Intelligence, Wisdom, and Charisma scores. You also retain all of your skill and saving throw proficiencies, in addition to gaining those of the creature. If the creature has the same proficiency as you and the bonus in its stat block is higher than yours, use the creature’s bonus instead of yours. If the creature has any legendary or lair actions, you can’t use them.',
      '* When you transform, you assume the beast’s hit points and Hit Dice. When you revert to your normal form, you return to the number of hit points you had before you transformed. However, if you revert as a result of dropping to 0 hit points, any excess damage carries over to your normal form. For example, if you take 10 damage in animal form and have only 1 hit point left, you revert and take 9 damage. As long as the excess damage doesn’t reduce your normal form to 0 hit points, you aren’t knocked unconscious.',
      '* You can’t cast spells, and your ability to speak or take any action that requires hands is limited to the capabilities of your beast form. Transforming doesn’t break your concentration on a spell you’ve already cast, however, or prevent you from taking actions that are part of a spell, such as call lightning, that you’ve already cast.',
      '* You retain the benefit of any features from your class, race, or other source and can use them if the new form is physically capable of doing so. However, you can’t use any of your special senses, such as darkvision, unless your new form also has that sense.',
      '* You choose whether your equipment falls to the ground in your space, merges into your new form, or is worn by it. Worn equipment functions as normal, but the DM decides whether it is practical for the new form to wear a piece of equipment, based on the creature’s shape and size. Your equipment doesn’t change size or shape to match the new form, and any equipment that the new form can’t wear must either fall to the ground or merge with it. Equipment that merges with the form has no effect until you leave the form.',
    ],
    false /* isTrivial */,
    [Actions.WILD_SHAPE]
  ),

  WILD_COMPANION: new Feat(
    'Wild Companion',
    [
      'You can summon a spirit that assumes an animal form.',
      'As an action, you can expend a use of your Wild Shape feature to cast the find familiar spell, without material components.',
      'When you cast the spell in this way, the familiar is a fey instead of a beast, and the familiar disappears after a number of hours equal to half your druid level.',
      'You gain the service of a familiar, a spirit that takes an animal form you choose: bat, cat, crab, frog, hawk, lizard, octopus, poisonous snake, fish, rat, raven, sea horse, spider, or weasel. This spirit is a fey instead of a beast.',
      'Your familiar acts independently of you, but it always obeys your commands. In combat, it rolls its own initiative and acts on its own turn. A familiar can\'t attack, but it can take other actions as normal.',
      'When the familiar drops to 0 hit points, it disappears, leaving behind no physical form.',
      'While your familiar is within 100 feet of you, you can communicate with it telepathically. Additionally, as an action, you can see through your familiar\'s eyes and hear what it hears until the start of your next turn, gaining the benefits of any special senses that the familiar has. During this time, you are deaf and blind with regard to your own senses.',
      'As an action, you can temporarily dismiss your familiar. It disappears into a pocket dimension where it awaits your summons. Alternatively, you can dismiss it forever. As an action while it is temporarily dismissed, you can cause it to reappear in any unoccupied space within 30 feet of you.',
      'You can\'t have more than one familiar at a time. If you cast this spell while you already have a familiar, you instead cause it to adopt a new form. Choose one of the forms from the above list. Your familiar transforms into the chosen creature.',
      'Finally, when you cast a spell with a range of touch, your familiar can deliver the spell as if it had cast the spell. Your familiar must be within 100 feet of you, and it must use its reaction to deliver the spell when you cast it. If the spell requires an attack roll, you use your attack modifier for the roll.',
    ],
    false /* isTrivial */,
    [Actions.WILD_COMPANION]
  ),

  TIMELESS_BODY: new Feat(
    'Timeless Body',
    [
      'The primal magic that you wield causes you to age more slowly. For every 10 years that pass, your body ages only 1 year.',
    ],
    false /* isTrivial */
  ),

  BEAST_SPELLS: new Feat(
    'Beast Spells',
    [
      'You can cast many of your druid spells in any shape you assume using Wild Shape. You can perform the somatic and verbal components of a druid spell while in a beast shape, but you aren’t able to provide material components.',
    ],
    false /* isTrivial */
  ),

  ARCHDRUID: new Feat(
    'Archdruid',
    [
      'You can use your Wild Shape an unlimited number of times.',
      'Additionally, you can ignore the verbal and somatic components of your druid spells, as well as any material components that lack a cost and aren’t consumed by a spell. You gain this benefit in both your normal shape and your beast shape from Wild Shape.',
    ],
    false /* isTrivial */
  ),

  // -------------------
  // Druid: Circle of the Moon
  COMBAT_WILD_SHAPE: new Feat(
    'Combat Wild Shape',
    [
      'You gain the ability to use Wild Shape on your turn as a bonus action, rather than as an action.',
      'Additionally, while you are transformed by Wild Shape, you can use a bonus action to expend one spell slot to regain 1d8 hit points per level of the spell slot expended.',
    ],
    false /* isTrivial */
  ),

  CIRCLE_FORMS: new Feat(
    'Circle Forms',
    [
      'The rites of your circle grant you the ability to transform into more dangerous animal forms. Starting at 2nd level, you can use your Wild Shape to transform into a beast with a challenge rating as high as 1 (you ignore the Max. CR column of the Beast Shapes table, but must abide by the other limitations there).',
      'Starting at 6th level, you can transform into a beast with a challenge rating as high as your druid level divided by 3, rounded down.',
    ],
    false /* isTrivial */
  ),

  PRIMAL_STRIKE: new Feat(
    'Primal Strike',
    [
      'Your attacks in beast form count as magical for the purpose of overcoming resistance and immunity to nonmagical attacks and damage.',
    ],
    false /* isTrivial */
  ),

  ELEMENTAL_WILD_SHAPE: new Feat(
    'Elemental Wild Shape',
    [
      'At 10th level, you can expend two uses of Wild Shape at the same time to transform into an air elemental, an earth elemental, a fire elemental, or a water elemental.',
    ],
    false /* isTrivial */
  ),

  THOUSAND_FORMS: new Feat(
    'Thousand Forms',
    [
      'You have learned to use magic to alter your physical form in more subtle ways. You can cast the alter self spell at will (without using a spell slot).',
    ],
    false /* isTrivial */
  ),

  // -------------------
  // Druid: Circle of the Shepherd
  SPEECH_OF_THE_WOODS: new Feat(
    'Speech of the Woods',
    [
      'You gain the ability to converse with beasts and many fey.',
      'You learn to speak, read, and write Sylvan. In addition, beasts can understand your speech, and you gain the ability to decipher their noises and motions. Most beasts lack the intelligence to convey or understand sophisticated concepts, but a friendly beast could relay what it has seen or heard in the recent past. This ability doesn’t grant you friendship with beasts, though you can combine this ability with gifts to curry favor with them as you would with any nonplayer character.',
    ],
    false /* isTrivial */,
    [Languages.SYLVAN]
  ),

  SPIRIT_TOTEM: new Feat(
    'Spirit Totem',
    [
      'You can call forth nature spirits to influence the world around you. As a bonus action, you can magically summon an incorporeal spirit to a point you can see within 60 feet of you. The spirit creates an aura in a 30-foot radius around that point. It counts as neither a creature nor an object, though it has the spectral appearance of the creature it represents.',
      'As a bonus action, you can move the spirit up to 60 feet to a point you can see.',
      'The spirit persists for 1 minute or until you’re incapacitated. Once you use this feature, you can’t use it again until you finish a short or long rest.',
      'The effect of the spirit’s aura depends on the type of spirit you summon from the options below.',
      '* Bear Spirit. The bear spirit grants you and your allies its might and endurance. Each creature of your choice in the aura when the spirit appears gains temporary hit points equal to 5 + your druid level. In addition, you and your allies gain advantage on Strength checks and Strength saving throws while in the aura.',
      '* Hawk Spirit. The hawk spirit is a consummate hunter, aiding you and your allies with its keen sight. When a creature makes an attack roll against a target in the spirit’s aura, you can use your reaction to grant advantage to that attack roll. In addition, you and your allies have advantage on Wisdom (Perception) checks while in the aura.',
      '* Unicorn Spirit. The unicorn spirit lends its protection to those nearby. You and your allies gain advantage on all ability checks made to detect creatures in the spirit’s aura. In addition, if you cast a spell using a spell slot that restores hit points to any creature inside or outside the aura, each creature of your choice in the aura also regains hit points equal to your druid level.',
    ],
    false /* isTrivial */,
    [Actions.SPIRIT_TOTEM]
  ),

  // MIGHTY_SUMMONER
  // GUARDIAN_SPIRIT
  // FAITHFUL SUMMONS

  // -------------------
  // Druid: Circle of the Land
  NATURAL_RECOVERY: new Feat(
    'Natural Recovery',
    [
      'You can regain some of your magical energy by sitting in meditation and communing with nature. During a short rest, you choose expended spell slots to recover. The spell slots can have a combined level that is equal to or less than half your druid level (rounded up), and none of the slots can be 6th level or higher. You can’t use this feature again until you finish a long rest.',
      'For example, when you are a 4th-level druid, you can recover up to two levels worth of spell slots. You can recover either a 2nd-level slot or two 1st-level slots.',
    ],
    false /* isTrivial */,
    [Actions.NATURAL_RECOVERY]
  ),

  CIRCLE_SPELLS: new Feat(
    'Circle Spells',
    [
      'Your mystical connection to the land infuses you with the ability to cast certain spells.',
      'Once you gain access to a circle spell, you always have it prepared, and it doesn’t count against the number of spells you can prepare each day. If you gain access to a spell that doesn’t appear on the druid spell list, the spell is nonetheless a druid spell for you.',
    ],
    false /* isTrivial */
  ),

  LANDS_STRIDE: new Feat(
    'Land’s Stride',
    [
      'Moving through nonmagical difficult terrain costs you no extra movement. You can also pass through nonmagical plants without being slowed by them and without taking damage from them if they have thorns, spines, or a similar hazard.',
      'In addition, you have advantage on saving throws against plants that are magically created or manipulated to impede movement, such those created by the entangle spell.',
    ],
    false /* isTrivial */
  ),

  NATURES_WARD: new Feat(
    'Nature’s Ward',
    [
      'You can’t be charmed or frightened by elementals or fey, and you are immune to poison and disease.',
    ],
    false /* isTrivial */
  ),

  NATURES_SANCTUARY: new Feat(
    'Nature’s Sanctuary',
    [
      'Creatures of the natural world sense your connection to nature and become hesitant to attack you. When a beast or plant creature attacks you, that creature must make a Wisdom saving throw against your druid spell save DC. On a failed save, the creature must choose a different target, or the attack automatically misses. On a successful save, the creature is immune to this effect for 24 hours.',
      'The creature is aware of this effect before it makes its attack against you.',
    ],
    false /* isTrivial */
  ),

  // -------------------
  // Fighter
  FIGHTING_STYLE_ARCHERY: new Feat(
    'Fighting Style: Archery',
    ['You gain a +2 bonus to attack rolls you make with ranged weapons.'],
    false /* isTrivial */,
    [StaticEffects.RANGED_ATTACK_BONUS(2)]
  ),
  FIGHTING_STYLE_DEFENSE: new Feat(
    'Fighting Style: Defense',
    ['While you are wearing armor, you gain a +1 bonus to AC.'],
    false /* isTrivial */,
    [StaticEffects.ARMORED_AC_BONUS(1)]
  ),
  //FIGHTING_STYLE_DUELING
  //FIGHTING_STYLE_GREAT_WEAPON_FIGHTING
  //FIGHTING_STYLE_PROTECTION
  FIGHTING_STYLE_TWO_WEAPON_FIGHTING: new Feat(
    'Fighting Style: Two-Weapon Fighting',
    [
      'When you engage in two-weapon fighting, you can add your ability modifier to the damage of the second attack.',
    ],
    false /* isTrivial */
  ),
  SECOND_WIND: new Feat(
    'Second Wind',
    [
      'You have a limited well of stamina that you can draw on to protect yourself from harm.',
      'On your turn, you can use a bonus action to regain hit points equal to 1d10 + your fighter level.',
      'Once you use this feature, you must finish a short or long rest before you can use it again.',
    ],
    false /* isTrivial */,
    [Actions.SECOND_WIND]
  ),
  ACTION_SURGE: new Feat(
    'Action Surge',
    [
      'On your turn, you can take one additional action.',
      'Once you use this feature, you must finish a short or long rest before you can use it again.',
    ],
    false /* isTrivial */,
    [Actions.ACTION_SURGE]
  ),
  EXTRA_ATTACK: new Feat(
    'Extra Attack',
    ['You can attack twice, instead of once, whenever you take the Attack action on your turn.'],
    false /* isTrivial */
  ),

  // -------------------
  // Fighter - Homebrew Pact Caster
  HOMEBREW_PACTFIGHTER_SPELLCASTING: new Feat(
    'Spellcasting',
    [
      "Drawing on power from a source you don't yet fully understand, you have gained the ability to cast spells.",
    ],
    true /* isTrivial */
  ),
  WEAPON_BOND: new Feat(
    'Weapon Bond',
    [
      'You can perform a 1 hour ritual to create a magical bond between yourself and one weapon.',
      "Once completed, you can't be disarmed of the weapon unless you are incapacitated, and you can summon it as a bonus action to your hand (if it's on the same plane of existence).",
      'You can have up to two bonded weapons.',
    ],
    false /* isTrivial */,
    [Actions.WEAPON_BOND_SUMMON]
  ),

  // -------------------
  // Ranger
  // Favored Enemey
  // Natural Explorer
  // FIGHTING_STYLE_ARCHERY - above in Fighter
  // FIGHTING_STYLE_DEFENSE - above in Fighter
  RANGER_SPELLCASTING: new Feat(
    'Spellcasting',
    ['You have learned to use the magical essence of nature to cast spells, much as a druid does.'],
    true /* isTrivial */
  ),
  PRIMEVAL_AWARENESS: SPELLS('Primeval Awareness', [Spells.PRIMEVAL_AWARENESS]),
  // LANDS_STRIDE: Included above
  // EXTRA_ATTACK - above in Fighter

  // -------------------
  // Ranger - Beast Master
  RANGERS_COMPANION: new Feat(
    "Ranger's Companion",
    [
      'You gain a beast companion that accompanies you on your adventures and is trained to fight alongside you. Choose a beast that is no larger than Medium and that has a challenge rating of 1/4 or lower. Add your proficiency bonus to the beast’s AC, attack rolls, and damage rolls, as well as to any saving throws and skills it is proficient in. Its hit point maximum equals the hit point number in its stat block or four times your ranger level, whichever is higher. Like any creature, it can spend Hit Dice during a short rest to regain hit points.',
      'The beast obeys your commands as best as it can. It takes its turn on your initiative, though it doesn’t take an action unless you command it to. On your turn, you can verbally command the beast where to move (no action required by you). You can use your action to verbally command it to take the Attack, Dash, Disengage, Dodge, or Help action. Once you have the Extra Attack feature, you can make one weapon attack yourself when you command the beast to take the Attack action.',
      'If you are incapacitated or absent, the beast acts on its own, focusing on protecting you and itself. The beast never requires your command to use its reaction, such as when making an opportunity attack.',
      'While traveling through your favored terrain with only the beast, you can move stealthily at a normal pace.',
      'If the beast dies, you can obtain a new companion by spending 8 hours magically bonding with a beast that isn’t hostile to you and that meets the requirements.',
    ],
    false /* isTrivial */
  ),
  BEAST_EXCEPTIONAL_TRAINING: new Feat(
    'Exceptional Training',
    [
      'On any of your turns when your beast companion doesn’t attack, you can use a bonus action to command the beast to take the Dash, Disengage, Dodge, or Help action on its turn.',
    ],
    false /* isTrivial */
  ),

  // -------------------
  // Sorcerer
  SORCERER_SPELLCASTING: new Feat(
    'Spellcasting',
    [
      'An event in your past, or in the life of a parent or ancestor, left an indelible mark on you, infusing you with arcane magic. This font of magic, whatever its origin, fuels your spells.',
    ],
    true /* isTrivial */
  ),

  // -------------------
  // Wizard
  WIZARD_SPELLCASTING: new Feat(
    'Spellcasting',
    [
      'As a student of arcane magic, you have a spellbook containing spells that show the first glimmerings of your true power.',
    ],
    true /* isTrivial */
  ),
  ARCANE_RECOVERY: new Feat(
    'Arcane Recovery',
    [
      'You have learned to regain some of your magical energy by studying your spellbook. Once per day when you finish a short rest, you can choose expended spell slots to recover. The spell slots can have a combined level that is equal to or less than half your wizard level (rounded up), and none of the slots can be 6th level or higher.',
      'For example, if you’re a 4th-level wizard, you can recover up to two levels worth of spell slots. You can recover either a 2nd-level spell slot or two 1st-level spell slots.',
    ],
    false /* isTrivial */
  ),

  // School of Transmutation
  TRANSMUTATION_SAVANT: new Feat(
    'Transmutation Savant',
    [
      'The gold and time you must spend to copy a transmutation spell into your spellbook is halved.',
    ],
    false /* isTrivial */
  ),
  MINOR_ALCHEMY: new Feat(
    'Minor Alchemy',
    ['You gain the Minor Alchemy power.'],
    false /* isTrivial */,
    [Actions.MINOR_ALCHEMY]
  ),
  TRANSMUTERS_STONE: new Feat(
    "Transmuter's Stone",
    ["You gain the ability to create a Transmuter's Stone."],
    false /* isTrivial */,
    [Actions.TRANSMUTERS_STONE]
  ),

  // Monk
  UNARMORED_DEFENSE_MONK: new Feat(
    'Unarmored Defense',
    [
      'While you are wearing no armor and not wielding a shield, your AC equals 10 + your Dexterity modifier + your Wisdom modifier.',
    ],
    false /* isTrivial */,
    [StaticEffects.UNARMORED_DEFENSE_MONK]
  ),
  MARTIAL_ARTS: new Feat(
    'Martial Arts',
    [
      'Your practice of martial arts gives you mastery of combat styles that use unarmed strikes and monk weapons, which are shortswords and any simple melee weapons that don’t have the two-handed or heavy property.',
      'You gain the following benefits while you are unarmed or wielding only monk weapons and you aren’t wearing armor or wielding a shield:',
      '* You can use Dexterity instead of Strength for the attack and damage rolls of your unarmed strikes and monk weapons.',
      '* You can roll a d4 in place of the normal damage of your unarmed strike or monk weapon. This die changes to a d6 at level 5, a d8 at level 11, and a d10 at level 17.',
      '* When you use the Attack action with an unarmed strike or a monk weapon on your turn, you can make one unarmed strike as a bonus action. For example, if you take the Attack action and attack with a quarterstaff, you can also make an unarmed strike as a bonus action, assuming you haven’t already taken a bonus action this turn.',
    ],
    false /* isTrivial */
  ),
  HOMEBREW_MONKDRUID_MARTIAL_ARTS: new Feat(
    'Martial Arts',
    [
      'Your practice of martial arts gives you mastery of combat styles that use unarmed strikes and any simple melee weapons that don’t have the two-handed or heavy property.',
      'You gain the following benefits while you are unarmed or wielding only martial arts weapons and you aren’t wearing armor or wielding a shield:',
      '* You can use Dexterity instead of Strength for the attack and damage rolls of your unarmed strikes and martial arts weapons.',
      '* You can roll a d4 in place of the normal damage of your unarmed strike or martial arts weapon. This die changes to a d6 at level 5, a d8 at level 11, and a d10 at level 17.',
      '* When you use the Attack action with an unarmed strike or a martial arts weapon on your turn, you can make one unarmed strike as a bonus action. For example, if you take the Attack action and attack with a quarterstaff, you can also make an unarmed strike as a bonus action, assuming you haven’t already taken a bonus action this turn.',
    ],
    false /* isTrivial */
  ),
  HOMEBREW_MONKDRUID_SPELLCASTING: new Feat(
    'Spellcasting',
    [
      'Drawing on the divine essence of nature itself, you can cast spells to shape that essence to your will.',
    ],
    false /* isTrivial */,
    [
      Spells.DRUIDCRAFT,
      // More at future levels
    ]
  ),

  KI: new Feat(
    'Ki',
    [
      'Your training allows you to harness the mystic energy of ki. Your access to this energy is represented by a number of ki points. You can spend these points to fuel various ki features. You start knowing three such features: Flurry of Blows, Patient Defense, and Step of the Wind. You learn more ki features as you gain levels.',
    ],
    false /* isTrivial */,
    [Spells.FLURRY_OF_BLOWS, Spells.PATIENT_DEFENSE, Spells.STEP_OF_THE_WIND]
  ),
  UNARMORED_MOVEMENT: new Feat(
    'Unarmored Movement',
    [
      'Your speed increases by 10 feet while you are not wearing armor or wielding a shield. This bonus increases to 15 at level 6, 20 at level 10, 25 at level 14, and 30 at level 18.',
    ],
    false /* isTrivial */,
    [
      StaticEffects.UNARMORED_MOVEMENT({
        2: 10,
        6: 5,
        10: 5,
        14: 5,
        18: 5,
      }),
    ]
  ),
  UNARMORED_MOVEMENT_BONUS: new Feat(
    'Unarmored Movement Bonus',
    [
      'When you are not wearing armor or wielding a shield, you can move along vertical surfaces and across liquids on your turn without falling during the move.',
    ],
    false /* isTrivial */
  ),

  // Other feats
  ACTOR: new Feat(
    'Actor',
    [
      'Skilled at mimicry and dramatics, you gain the following benefits:',
      '* Increase your Charisma schore by 1, to a maximum of 20.',
      '* You have advantage on Deception and Performance checks when trying to pass yourself off as a different person.',
      '* You can mimic the speech of another person or the sounds made by other creatures. You m ust have heard the person speaking, or heard the creature make the sound, for at least 1 minute. A successful Insight check contested by your Deception check allows a listener to determine that the effect is faked.',
    ],
    false /* isTrivial */,
    [StaticEffects.ABILITY_SCORE_INCREASE(AbilityType.CHA, 1)]
  ),
};

export default Feats;
