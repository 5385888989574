import React from 'react';

function Personality(props) {
  const character = props.character;
  if (!character.personalityTraits || character.personalityTraits.length === 0) {
    return null;
  }

  const personalityTraits = character.personalityTraits.map((trait, index) => (
    <div key={'trait' + index} className="Trait">
      {trait}
    </div>
  ));

  return (
    <div className="section Personality">
      <div className="title">Personality</div>
      {personalityTraits}
    </div>
  );
}

export default Personality;
