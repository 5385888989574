import Time from './Time.js';

const Hours = function(hours) {
  if (hours === 1) {
    return new Time('1 Hour');
  } else {
    return new Time(hours + ' Hours');
  }
};
export default {
  AlwaysActive: new Time('Always Active'),
  DuringAttack: new Time('During Attack'),
  Action: new Time('1 Action'),
  Bonus: new Time('1 Bonus Action'),
  Reaction: new Time('Reaction'),
  ShortRest: new Time('Short Rest'),
  LongRest: new Time('Long Rest'),
  Minutes: function(minutes) {
    if (minutes === 1) {
      return new Time('1 Minute');
    } else {
      return new Time(minutes + ' Minutes');
    }
  },
  Hours,
  HoursPerLevel: function(hours) {
    if (hours === 0.5) {
      return new Time('1 Hour per 2 Levels', 'Hour', 'Hours', 0.5);
    } else if (hours === 1) {
      return new Time('1 Hour per Level', 'Hour', 'Hours', 1);
    } else {
      return new Time(hours + ' Hours per Level', 'Hour', 'Hours', hours);
    }
  },
  Days: function(days) {
    if (days === 1) {
      return Hours(24);
    } else {
      return new Time(days + ' days');
    }
  },
};
