import React from 'react';

function InventoryDetails(props) {
  const character = props.character;
  if (!character.items || character.items.length === 0) {
    return null;
  }

  const itemsAttuned = [];
  const itemsWorn = [];
  const itemsCarried = [];
  character.items
    .concat()
    .sort((a, b) => {
      if (a.item.name < b.item.name) {
        return -1;
      } else if (a.item.name > b.item.name) {
        return 1;
      } else {
        return 0;
      }
    })
    .forEach((item, index) => {
      if (item.isAttuned) {
        itemsAttuned.push(<Item key={'attuned' + index} item={item.item} />);
      }
      if (item.isActive) {
        itemsWorn.push(<Item key={'worn' + index} item={item.item} />);
      } else {
        itemsCarried.push(<Item key={'carried' + index} item={item.item} />);
      }
    });
  return (
    <div className="section InventoryDetails">
      <div className="title">Item Details</div>
      {itemsAttuned.length > 0 && (
        <div className="Equipment Attuned">
          <div className="title">Attuned</div>
          {itemsAttuned}
        </div>
      )}
      <div className="Equipment Active">
        <div className="title">Active</div>
        {itemsWorn}
      </div>
      <div className="Equipment Carried">
        <div className="title">Carried</div>
        {itemsCarried}
      </div>
    </div>
  );
}

function Item(props) {
  const item = props.item;
  return (
    <div className="Item">
      <div className="Name">{item.name}</div>
      <div className="Description">{item.description}</div>
    </div>
  );
}

export default InventoryDetails;
