import React from 'react';

import Value from './Value.js';
import Actions from './Actions.js';
import AbilityType from '../Character/AbilityType.js';

function Rest(props) {
  const character = props.character;
  return (
    <div className="section Rest">
      <div className="title">Rest</div>
      <div className="Basics">
        <div className="HitDice">
          <Value
            value={
              '1d' +
              character.characterClass.hitDiceSides +
              ' + ' +
              character.abilityModifier(AbilityType.CON)
            }
          />
        </div>
        <div className="MaxHitDice">
          <Value value={character.level} />
        </div>
      </div>
      <Actions
        character={character}
        includeCombat={false}
        includeNonCombat={false}
        includeRest={true}
        includeDetails={true}
      />
    </div>
  );
}

export default Rest;
