import Time from './Time.js';
import ActionTimes from './Times.js';

export default {
  NA: new Time(''),
  Instantaneous: new Time('Instantaneous'),
  UntilDispelled: new Time('Until Dispelled'),
  Rounds: function(rounds) {
    if (rounds === 1) {
      return new Time('1 Round');
    } else {
      return new Time(rounds + ' Rounds');
    }
  },
  Minutes: ActionTimes.Minutes,
  Hours: ActionTimes.Hours,
  HoursPerLevel: ActionTimes.HoursPerLevel,
  Days: ActionTimes.Days,
};
