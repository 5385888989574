import { UnfoldArray } from '../Common/ArrayUtilities.js';
import CharacterEnhancement, { EnhancementTypes } from './CharacterEnhancement.js';

class CharacterEnhancer {
  constructor(opt_enhancements) {
    this._actions = [];
    this._effects = [];
    this._proficiencies = [];
    this._skills = [];
    this._languages = [];
    const enhancements = UnfoldArray(opt_enhancements, CharacterEnhancement);
    if (enhancements) {
      enhancements.forEach((enhancement) => {
        if (enhancement.enhancementType === EnhancementTypes.Action) {
          this._actions.push(enhancement);
        } else if (enhancement.enhancementType === EnhancementTypes.Effect) {
          this._effects.push(enhancement);
        } else if (enhancement.enhancementType === EnhancementTypes.Proficiency) {
          this._proficiencies.push(enhancement);
        } else if (enhancement.enhancementType === EnhancementTypes.Skill) {
          this._skills.push(enhancement);
        } else if (enhancement.enhancementType === EnhancementTypes.Language) {
          this._languages.push(enhancement);
        } else {
          console.dir(enhancement);
          throw new Error(
            'CharacterEnhancer given a non-action, non-effect, non-proficiency, non-skill enhancement'
          );
        }
      });
    }
  }

  get actions() {
    return this._actions;
  }

  get effects() {
    return this._effects;
  }

  get proficiencies() {
    return this._proficiencies;
  }

  get skills() {
    return this._skills;
  }

  get languages() {
    return this._languages;
  }
}

export default CharacterEnhancer;
