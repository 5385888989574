import TextUtilities from '../../Common/TextUtilities.js';

import Action from '../Actions/Action.js';
import Types from '../Actions/Types.js';
import Sort from '../Actions/Sort.js';

import { C, R } from './Components.js';
import PointType from './PointType.js';

import { USE_SPELL_POINTS } from '../../Settings.js';

function spellLevelToType(levelOrPointCost, pointType) {
  var type;
  if (pointType === PointType.AtWill) {
    type = 'At Will Spell';
  } else if (pointType === PointType.SpellSlot) {
    if (levelOrPointCost === 0) {
      type = 'Cantrip (Simple Spell)';
    } else {
      var level = TextUtilities.Nth(levelOrPointCost) + ' Level Spell';
      if (USE_SPELL_POINTS) {
        level += ' (' + Spell.spellLevelToPoints(levelOrPointCost) + ' SP)';
      }
      type = level;
    }
  } else {
    type = levelOrPointCost + ' ' + pointType;
  }
  return Types.Spell(type);
}

function spellLevelToSortScore(levelOrPointCost, pointType) {
  var extra;
  if (pointType === PointType.AtWill) {
    extra = 0;
  } else if (pointType === PointType.SpellSlot) {
    extra = 200;
  } else {
    extra = 100;
  }
  return Sort.SpellStart + (levelOrPointCost || 0) + extra;
}

class Spell extends Action {
  constructor(
    name,
    levelOrPointCost,
    pointType,
    description,
    utilities,
    time,
    duration,
    range,
    areaOfEffect,
    toHit,
    effect,
    requires,
    school
  ) {
    super(
      name,
      spellLevelToType(levelOrPointCost, pointType),
      spellLevelToSortScore(levelOrPointCost, pointType),
      description,
      utilities,
      time,
      duration,
      range,
      areaOfEffect,
      toHit,
      effect,
      requires
    );

    this._levelOrPointCost = levelOrPointCost;
    this._pointType = pointType;
    this._school = school;
  }

  get concentration() {
    return this.requires.indexOf(C) > -1;
  }

  get ritual() {
    return this.requires.indexOf(R) > -1;
  }

  static spellSlotsToPoints(spellSlots) {
    var points = 0;
    if (spellSlots) {
      for (var spellLevel = 1; spellLevel <= 9; spellLevel++) {
        if (spellSlots[spellLevel]) {
          points += spellSlots[spellLevel] * Spell.spellLevelToPoints(spellLevel);
        }
      }
    }
    return points;
  }

  static spellLevelToPoints(spellLevel) {
    switch (spellLevel) {
      case 1:
        return 2;
      case 2:
        return 3;
      case 3:
        return 5;
      case 4:
        return 6;
      case 5:
        return 7;
      case 6:
        return 9;
      case 7:
        return 10;
      case 8:
        return 11;
      case 9:
        return 13;
      default:
        return 999;
    }
  }
}

export { Spell as default };
