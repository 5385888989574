import Language from './Language.js';

const Languages = {
  ABYSSAL: new Language('Abyssal'),
  CELESTIAL: new Language('Celestial'),
  COMMON: new Language('Common'),
  DEEP_SPEECH: new Language('Deep Speech'),
  DRACONIC: new Language('Draconic'),
  DRUIDIC: new Language('Druidic', 'Druidic is the secret language of druids.'),
  DWARVISH: new Language(
    'Dwarvish',
    'Dwarvish is full of hard consonants and guttural sounds, and those characteristics spill over into whatever other language a dwarf might speak.'
  ),
  ELVISH: new Language(
    'Elvish',
    'Elvish is fluid, with subtle intonations and intricate grammar. Elven literature is rich and varied, and their songs and poems are famous among other races. Many bards learn their language so they can add Elvish ballads to their repertoires.'
  ),
  GIANT: new Language('Giant'),
  GNOMISH: new Language(
    'Gnomish',
    'The Gnomish language, which uses the Dwarvish script, is renowned for its technical treatises and its catalogs of knowledge about the natural world.'
  ),
  GOBLIN: new Language('Goblin'),
  HALFLING: new Language('Halfling'),
  INFERNAL: new Language('Infernal'),
  ORC: new Language('Orc'),
  PRIMORDIAL: new Language('Primordial'),
  SYLVAN: new Language('Sylvan'),
  UNDERCOMMON: new Language('Undercommon'),
};

export default Languages;
