import { UnfoldArray } from '../Common/ArrayUtilities.js';

import Feat from './Feat.js';
import Language from './Language.js';

class Race {
  constructor(name, speed, size, feats, languages) {
    this._name = name;
    this._speed = speed;
    this._size = size;
    this._feats = UnfoldArray(feats, Feat);
    this._languages = UnfoldArray(languages, Language);
  }
  get name() {
    return this._name;
  }

  get speed() {
    return this._speed;
  }

  get size() {
    return this._size;
  }

  get feats() {
    return this._feats;
  }

  get languages() {
    return this._languages;
  }
}

export default Race;
