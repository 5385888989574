class DamageType {
  constructor(name) {
    this._name = name;
  }

  get name() {
    return this._name;
  }
}

const DamageTypes = {
  Bludgeoning: new DamageType('Bludgeoning'),
  Piercing: new DamageType('Piercing'),
  Slashing: new DamageType('Slashing'),

  Acid: new DamageType('Acid'),
  Cold: new DamageType('Cold'),
  Fire: new DamageType('Fire'),
  Force: new DamageType('Force'),
  Lightning: new DamageType('Lightning'),
  Necrotic: new DamageType('Necrotic'),
  Poison: new DamageType('Poison'),
  Psychic: new DamageType('Psychic'),
  Radiant: new DamageType('Radiant'),
  Thunder: new DamageType('Thunder'),

  Healing: new DamageType('Healing'),
  Inspiration: new DamageType('Inspiration Die'),
  Rage: new DamageType('Rage'),
};

export { DamageType as default, DamageTypes };
