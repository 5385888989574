import React, { Component } from 'react';
import './App.scss';
import Characters from './Characters.js';
import PrintableCharacterSheet from './PrintableCharacterSheet/CharacterSheet.js';
import CharacterSheet from './CharacterSheet/CharacterSheet.js';
import MissingPortrait from './Portraits/MissingPortrait.png';

const SPLIT_ITEMS = false;

class App extends Component {
  constructor(props) {
    super(props);
    var selected = this.getCharacterFromHash() || Characters.ron;
    this.state = {
      updateAvailable: false,
      selected: selected,
      printable: false,
      quad: window.location.search === '?quad',
    };
    this.updateHash(selected);
    window.addEventListener(
      'hashchange',
      () => {
        this.selectFromHash();
      },
      false
    );
    window.addEventListener(
      'update-available',
      () => {
        this.setState({ updateAvailable: true });
      },
      false
    );
  }

  updateHash(character) {
    window.location.hash = character.id;
    document.querySelector('link[rel="shortcut icon"]').href =
      character.portrait || MissingPortrait;
    document.title = character.name + ' - Teoxia Character Sheets';
  }

  getCharacterFromHash() {
    var selected = null;
    var hash = window.location.hash;
    if (hash !== null) {
      hash = hash.substring(1, hash.length);
      selected = Characters.list.find((character) => {
        return character.id === hash;
      });
    }
    return selected;
  }

  selectFromHash() {
    var selected = this.getCharacterFromHash();
    if (selected) {
      this.setState({
        selected: selected,
      });
      this.updateHash(selected);
    }
  }

  select(character) {
    if (this.state.selected !== character) {
      console.log('Select ' + character.name);
      this.setState({
        selected: character,
      });
      this.updateHash(character);
    }
  }

  render() {
    if (this.state.quad) {
      return (
        <div className="App grid">
          {this.state.updateAvailable && (
            <div className="updateNotice">
              This page is currently outdated. Please refresh for the latest details.
            </div>
          )}
          <div className="cell">
            <CharacterSheet character={Characters.ron} splitItems={SPLIT_ITEMS} />
          </div>
          <div className="cell">
            <CharacterSheet character={Characters.jan} splitItems={SPLIT_ITEMS} />
          </div>
          <div className="cell">
            <CharacterSheet character={Characters.crystal} splitItems={SPLIT_ITEMS} />
          </div>
          <div className="cell">
            <CharacterSheet character={Characters.kim} splitItems={SPLIT_ITEMS} />
          </div>
        </div>
      );
    }
    const buttons = Characters.list.map((character) => {
      const handleClick = function(e) {
        this.select(character);
      }.bind(this);
      const className = this.state.selected === character ? 'selected' : '';
      return (
        <button key={character.name} className={className} onClick={handleClick}>
          {character.name}
        </button>
      );
    });
    const printToggle = () => {
      this.setState({
        printable: !this.state.printable,
      });
    };
    buttons.push(
      <button
        key="printableToggle"
        className={'printableToggle ' + (this.state.printable ? 'on' : 'off')}
        onClick={printToggle}
      >
        Printer Version
      </button>
    );

    return (
      <div className="App">
        {this.state.updateAvailable && (
          <div className="updateNotice">
            This page is currently outdated. Please refresh for the latest details.
          </div>
        )}
        <div className="Buttons">{buttons}</div>
        {!this.state.printable && this.state.selected && (
          <CharacterSheet character={this.state.selected} splitItems={SPLIT_ITEMS} />
        )}
        {this.state.printable && this.state.selected && (
          <PrintableCharacterSheet character={this.state.selected} splitItems={SPLIT_ITEMS} />
        )}
      </div>
    );
  }
}

export default App;
