class CharacterEnhancement {
  constructor(enhancementType) {
    this._enhancementType = enhancementType;
  }

  get enhancementType() {
    return this._enhancementType;
  }
}

const EnhancementTypes = {
  Action: 'action',
  Effect: 'effect',
  Proficiency: 'proficiency',
  Skill: 'skill',
  Language: 'language',
};

export { CharacterEnhancement as default, EnhancementTypes };
