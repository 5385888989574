import AbilityType from '../Character/AbilityType.js';
import CarriedItem from '../Character/CarriedItem.js';
import Character from '../Character/Character.js';
import Classes from '../Character/Classes.js';
import Feats from '../Character/Feats.js';
import Items from '../Character/Items.js';
import Proficiencies from '../Character/Proficiencies.js';
import SkillType from '../Character/SkillType.js';
import Spells from '../Character/Spells/Spells.js';

import JanPortrait from '../Portraits/JanPortrait.png';

import { LANGUAGES, RACE, LAST_NAME, WA_PREFIX, WA_SUFFIX, LEVEL } from './Common.js';

// prettier-ignore
const jan = new Character('jan',
    'Clarity' + LAST_NAME, JanPortrait,
    44, '5\' 0"', '100 lbs', // AGE HEIGHT WEIGHT
    'Red', 'Blue', 'Fair', 'Small feather tattoo on right hand between the thumb and forefinger', //	HAIR EYES SKIN MARKS
    WA_PREFIX + 'clarity' + WA_SUFFIX,
    [
        'Saw her parents drown when she was young. They were caught by the tide after losing track of time.',
        'Stayed as an orphan the rest of her childhood, hiding and blending in to avoid being taken in by some strange family.',
        'Her old crystal ball from her childhood is her most prized possession, and acts almost like a child\'s "security blanket" would. Once clear, the crystal ball is now scratched and cloudy. The crystal ball seems to have an almost magical calming effect on people in some situations.',
        'Has a keen eye, and is often able to "read" people.',
        'Very private and secretive of her personal feelings, and is not very trusting of anyone. This makes her hard to get to know.',
        'Wears plain, average looking clothes to avoid standing out in a crowd.',
    ], LANGUAGES,
    RACE, Classes.CIRCLE_OF_THE_LAND_COAST, LEVEL,
    8, 15, 12, 11, 15, 10, // STR DEX CON INT WIS CHA
    [
        // Background Feat
        Feats.HOMEBREW_CITY_SECRETS_ALT_J,

        // Half Elf choices
        Feats.ABILITY_SCORE_INCREASE(AbilityType.DEX, 1),
        Feats.ABILITY_SCORE_INCREASE(AbilityType.INT, 1),

        // Replace Half Elf CHA+2 with WIS+2
        Feats.ABILITY_SCORE_INCREASE(AbilityType.WIS, 2),
        Feats.ABILITY_SCORE_INCREASE(AbilityType.CHA, -2),

        // Other Feats
    ], [
        // Half Elf choices
        SkillType.INSIGHT,
        SkillType.SURVIVAL,

        // Druid Choices
        SkillType.ARCANA,
        SkillType.PERCEPTION,

        // Background Choices
        SkillType.STEALTH,
        SkillType.SLEIGHT_OF_HAND,
    ], [
        // Background Choices
        Proficiencies.DisguiseKit,
        Proficiencies.ThievesTools,
    ], [
        // Prepared Spells
        Spells.CONTROL_FLAMES,
        Spells.PRODUCE_FLAME,
        Spells.CREATE_OR_DESTROY_WATER,
        Spells.CURE_WOUNDS,
        Spells.FOG_CLOUD,
        Spells.HEALING_WORD,

        // Level 2 Spells
        //Spells.FROSTBITE,
        Spells.GUST,
        //Spells.MENDING,
        //Spells.MOLD_EARTH,
        //Spells.SHAPE_WATER,
        //Spells.THUNDERCLAP,
        Spells.DETECT_MAGIC,
        //Spells.EARTH_TREMOR,
        //Spells.FAERIE_FIRE,
        //Spells.ICE_KNIFE,
        //Spells.THUNDERWAVE,
    ], [
        Items.Backpack,
        Items.Bedroll,
        Items.ClothesCommon(5),
        Items.ClothesFine(1),
        Items.Costume(1),
        new CarriedItem(Items.Dagger(), true /* isActive */ ),
        // Actual magical, can cast "Calm Emotions" with a DC of 13, range of 60, anyone who can see
        // 3 charges, recharge 1 charges daily at dawn
        // https://www.dndbeyond.com/spells/calm-emotions
        new CarriedItem(Items.DruidicFocus('Crystal Ball'), true /* isActive */ ),
        Items.Ink,
        Items.InkPen,
        Items.Journal,
        new CarriedItem(Items.LightGambeson(), true /* isActive */ ),
        Items.MessKit,
        Items.Pouch,
        Items.Rations(5),
        Items.Rope50(1),
        new CarriedItem(Items.Sling(), true /* isActive */ ),
        new CarriedItem(Items.SlingBullet(20), true /* isActive */ ),
        Items.Tinderbox,
        Items.Waterskin,
        //new CarriedItem(Items.RingOfMindShielding('AA'), true /* isActive */, true ),
        //new CarriedItem(Items.RingOfMindShielding('AN'), true /* isActive */, false ),
    ]);
jan.expertiseSkills(SkillType.SLEIGHT_OF_HAND);
export default jan;
