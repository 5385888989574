import ActionRequirement from '../Actions/Requirement.js';

export const V = new ActionRequirement('Verbal', 'V');
export const S = new ActionRequirement('Somatic', 'S');
export const M = function(name, hasCost) {
  return new ActionRequirement('Material (' + name + ')', 'M' + (hasCost ? '*' : ''));
};
export const C = new ActionRequirement('Concentration', 'C');
export const R = new ActionRequirement('Can be cast as a Ritual', 'R');

export const VS = [V, S];
export const VSM = function(name, hasCost) {
  return [V, S, M(name, hasCost)];
};
export const VSMC = function(name, hasCost) {
  return [V, S, M(name, hasCost), C];
};
export const VSMCR = function(name, hasCost) {
  return [V, S, M(name, hasCost), C, R];
};
export const VSMR = function(name, hasCost) {
  return [V, S, M(name, hasCost), R];
};
export const VSC = [V, S, C];
export const VSCR = [V, S, C, R];
export const VSR = [V, S, R];

export const VM = function(name, hasCost) {
  return [V, M(name, hasCost)];
};
export const VMC = function(name, hasCost) {
  return [V, M(name, hasCost), C];
};
// VMCR
// VMR
export const VC = [V, C];
// VCR
// VR

export const SM = function(name, hasCost) {
  return [S, M(name, hasCost)];
};
export const SMC = function(name, hasCost) {
  return [S, M(name, hasCost), C];
};
// SMCR
export const SMR = function(name, hasCost) {
  return [S, M(name, hasCost), R];
};
export const SC = [S, C];
// SCR
// SR

// MC
// MCR
// MR
