import React from 'react';

import Modifier from './Modifier.js';
import Value from './Value.js';
import Actions from './Actions.js';
import SpellSlots from './SpellSlots.js';

function Combat(props) {
  const character = props.character;
  return (
    <div className="section Combat">
      <div className="title">Combat</div>
      <div className="Basics">
        <div className="Initiative">
          <Modifier value={character.initiative} />
        </div>
        <div className="Speed">
          <Value value={character.speed + ' ft'} />
        </div>
        <div className="AC">
          <Value value={character.ac} />
        </div>
        <div className="MaxHP">
          <Value value={character.maxHP} />
        </div>
      </div>
      <SpellSlots character={character} includeDetails={false} />
      <Actions
        character={character}
        includeCombat={true}
        includeNonCombat={false}
        includeDetails={false}
      />
    </div>
  );
}

export default Combat;
