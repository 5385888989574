import Action from '../Action.js';
import AreaOfEffects from '../AreaOfEffects.js';
import Durations from '../Durations.js';
import Effects from '../Effects.js';
import Ranges from '../Ranges.js';
import Sort from '../Sort.js';
import Times from '../Times.js';
import ToHits from '../ToHits.js';
import Types from '../Types.js';
import Utilities from '../Utilities.js';

export default new Action(
  'Long Rest',
  Types.OncePerShortRestPower,
  Sort.First,
  [
    'A long rest is a period of extended downtime, at least 8 hours long, during which your character sleeps for at least 6 hours and performs no more than 2 hours of light acivity, such as reading, talking, eating, or standing watch.',
    'If the rest is interrupted by a period of strenuous activity - at least one hour of walking, fighting, casting spells, etc - you must begin the rest again to gain any benefit from it.',
    'At the end of a long rest, you regain all lost hit points and up to half your level worth of spent hit dice.',
    'You cannot benefit from more than one long rest in a 24-hour period.',
  ],
  [Utilities.Rest],
  Times.Hours(8),
  Durations.Instantaneous,
  Ranges.Self,
  AreaOfEffects.SingleTarget,
  ToHits.AlwaysWorks,
  Effects.Recover(true),
  null /* requires */
);
