import { SC } from '../Components.js';
import PointType from '../PointType.js';
import Spell from '../Spell.js';

import AreaOfEffects from '../../Actions/AreaOfEffects.js';
import Durations from '../../Actions/Durations.js';
import Effects from '../../Actions/Effects.js';
import Ranges from '../../Actions/Ranges.js';
import Times from '../../Actions/Times.js';
import ToHits from '../../Actions/ToHits.js';
import Utilities from '../../Actions/Utilities.js';

export default new Spell(
  'True Strike',
  0,
  PointType.SpellSlot,
  [
    "You extend your hand and point a finger at a target in range. Your magic grants you a brief insight into the target's defenses. On your next turn, you gain advantage on your first attack roll against the target, provided that this spell hasn't ended.",
  ],
  Utilities.Combat,
  Times.Action,
  Durations.Rounds(1),
  Ranges.Range(30),
  AreaOfEffects.SingleTarget,
  ToHits.AlwaysWorks,
  Effects.Foreknowledge(),
  SC,
  'Divination'
);
