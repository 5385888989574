import CharacterEnhancer from './CharacterEnhancer.js';

class Item extends CharacterEnhancer {
  constructor(name, count, description, weight, opt_actionsAndEffects, opt_requiresAttunement) {
    super(opt_actionsAndEffects);
    this._name = name;
    this._count = count;
    this._description = description;
    this._weight = weight;
    this._requiresAttumentment = !!opt_requiresAttunement;
  }

  get name() {
    return this._name;
  }

  get description() {
    return this._description.join('\n');
  }

  get count() {
    return this._count;
  }

  get totalWeight() {
    return this._count * this._weight;
  }

  get requiresAttunement() {
    return this._requiresAttumentment;
  }
}

export default Item;
