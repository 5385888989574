export default class Range {
  constructor(name) {
    this._name = name;
  }
  get name() {
    return this._name;
  }
  get shortName() {
    return this._name;
  }
}
