export default class Requirement {
  constructor(name, shortName) {
    this._name = name;
    this._shortName = shortName;
  }

  get name() {
    return this._name;
  }

  get shortName() {
    return this._shortName;
  }
}
