import assert from '../Common/Assert.js';

function UnfoldArray(array, expectedTypeOrTypes) {
  const expectedTypes = [].concat(expectedTypeOrTypes);
  const validateType = function(element) {
    assert(
      expectedTypes.some((type) => {
        return element instanceof type;
      }),
      'Invalid argument type in complex array',
      element
    );
  };

  if (!array) {
    return null;
  }
  if (Array.isArray(array)) {
    var ret = [];
    array.forEach((element) => {
      if (Array.isArray(element)) {
        ret = ret.concat(UnfoldArray(element, expectedTypes));
      } else {
        validateType(element);
        ret.push(element);
      }
    });
    return ret;
  } else {
    validateType(array);
    return [array];
  }
}

export { UnfoldArray };
