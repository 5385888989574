import AbilityType from '../AbilityType.js';
import { DamageTypes } from '../DamageType.js';
import Roll from '../Roll.js';
import SkillType from '../SkillType.js';

import AreaOfEffects from '../Actions/AreaOfEffects.js';
import Durations from '../Actions/Durations.js';
import Effects from '../Actions/Effects.js';
import Ranges from '../Actions/Ranges.js';
import Times from '../Actions/Times.js';
import ToHits from '../Actions/ToHits.js';
import Utilities from '../Actions/Utilities.js';

import {
  V,
  S,
  VS,
  VSM,
  VSMC,
  VSMCR,
  VSMR,
  VSC,
  VSCR,
  VSR,
  VM,
  VMC,
  VC,
  SM,
  SMR,
} from './Components.js';
import PointType from './PointType.js';
import Spell from './Spell.js';

import BLADE_WARD from './Cantrips/BladeWard.js';
import DANCING_LIGHTS from './Cantrips/DancingLights.js';
import FRIENDS from './Cantrips/Friends.js';
import LIGHT from './Cantrips/Light.js';
import MAGE_HAND from './Cantrips/MageHand.js';
import MENDING from './Cantrips/Mending.js';
import MESSAGE from './Cantrips/Message.js';
import MINOR_ILLUSION from './Cantrips/MinorIllusion.js';
import PRESTIDIGITATION from './Cantrips/Prestidigitation.js';
import THUNDERCLAP from './Cantrips/Thunderclap.js';
import TRUE_STRIKE from './Cantrips/TrueStrike.js';
import VICIOUS_MOCKERY from './Cantrips/ViciousMockery.js';

// ’ vs \'

const Spells = {
  // Cantrips
  BLADE_WARD,
  DANCING_LIGHTS,
  FRIENDS,
  LIGHT,
  MAGE_HAND,
  MENDING,
  MESSAGE,
  MINOR_ILLUSION,
  PRESTIDIGITATION,
  THUNDERCLAP,
  TRUE_STRIKE,
  VICIOUS_MOCKERY,

  // -------------------
  // Bard - 1
  // new Spell('SPELL', 1, PointType.SpellSlot, [
  //         'DESCRIPTION',
  //     ], [Utilities.Combat, Utilities.NonCombat],
  //     Times.%, Durations.%, Ranges.%, AreaOfEffects.%,
  //     ToHits.%, Effects.%,
  //     REQS, 'SCHOOL')
  ANIMAL_FRIENDSHIP: new Spell(
    'Animal Friendship',
    1,
    PointType.SpellSlot,
    [
      "This spell lets you convince a beast that you mean it no harm. Choose a beast that you can see within range. It must see and hear you. If the beast's Intelligence is 4 or higher, the spell fails. Otherwise, the beast must succeed on a Wisdom saving throw or be charmed by you for the spell's duration. If you or one of your companions harms the target, the spells ends.",
      'At Higher Levels. When you cast this spell using a spell slot of 2nd level or higher, you can affect one additional beast level above 1st.',
    ],
    [Utilities.Combat, Utilities.NonCombat],
    Times.Action,
    Durations.Hours(24),
    Ranges.Range(30),
    AreaOfEffects.SingleTarget,
    ToHits.SpellSave(AbilityType.WIS, true),
    Effects.Charm(),
    VSM('a morsel of food'),
    'Enchantment'
  ),

  BANE: new Spell(
    'Bane',
    1,
    PointType.SpellSlot,
    [
      'Up to three creatures of your choice that you can see within range must make Charisma saving throws. Whenever a target that fails this saving throw makes an attack roll or a saving throw before the spell ends, the target must roll a d4 and subtract the number rolled from the attack roll or saving throw.',
      'At Higher Levels. When you cast this spell using a spell slot of 2nd level or higher, you can target one additional creature for each slot level above 1st.',
    ],
    Utilities.Combat,
    Times.Action,
    Durations.Minutes(1),
    Ranges.Range(30),
    AreaOfEffects.MultipleTargets,
    ToHits.SpellSave(AbilityType.CHA),
    Effects.Debuff(),
    VSMC('a drop of blood'),
    'Enchantment'
  ),

  CHARM_PERSON: new Spell(
    'Charm Person',
    1,
    PointType.SpellSlot,
    [
      'You attempt to charm a humanoid you can see within range. It must make a Wisdom saving throw, and does so with advantage if you or your companions are fighting it. If it fails the saving throw, it is charmed by you until the spell ends or until you or your companions do anything harmful to it. The charmed creature regards you as a friendly acquaintance. When the spell ends, the creature knows it was charmed by you.',
      'At Higher Levels. When you cast this spell using a spell slot of 2nd level or higher, you can target one additional creature for each slot level above 1st. The creatures must be within 30 feet of each other when you target them.',
    ],
    [Utilities.Combat, Utilities.NonCombat],
    Times.Action,
    Durations.Hours(1),
    Ranges.Range(30),
    AreaOfEffects.SingleTarget,
    ToHits.SpellSave(AbilityType.WIS, true),
    Effects.Charm(),
    VS,
    'Enchantment'
  ),

  COMPREHEND_LANGUAGES: new Spell(
    'Comprehend Languages',
    1,
    PointType.SpellSlot,
    [
      'For the duration, you understand the literal meaning of any spoken language that you hear. You also understand any written language that you see, but you must be touching the surface on which the words are written. It takes about 1 minute to read one page of text.',
      "This spell doesn't decode secret messages in a text or a glyph, such as an arcane sigil, that isn't part of a written language.",
    ],
    Utilities.NonCombat,
    Times.Action,
    Durations.Hours(1),
    Ranges.Self,
    AreaOfEffects.SingleTarget,
    ToHits.AlwaysWorks,
    Effects.Social(),
    VSMR('a punch of soot and salt'),
    'Divination'
  ),

  CURE_WOUNDS: new Spell(
    'Cure Wounds',
    1,
    PointType.SpellSlot,
    [
      'A creature you touch regains a number of hit points equal to 1d8 + your spellcasting ability modifier. This spell has no effect on undead or constructs.',
      'When you cast this spell using a spell slot of 2nd level or higher, the healing increases by 1d8 for each slot level above 1st.',
    ],
    [Utilities.Combat, Utilities.NonCombat],
    Times.Action,
    Durations.Instantaneous,
    Ranges.Touch,
    AreaOfEffects.SingleTarget,
    ToHits.AlwaysWorks,
    Effects.Heal(Roll.d(8), true /* useSpellModifier */, true /* extraDetails */),
    VS,
    'Evocation'
  ),

  DETECT_MAGIC: new Spell(
    'Detect Magic',
    1,
    PointType.SpellSlot,
    [
      'For the duration, you sense the presence of magic within 30 feet of you. If you sense magic in this way, you can use your action to see a faint aura around any visible creature or object in the area that bears magic, and you learn its school of magic, if any.',
      'The spell can penetrate most barriers, but it is blocked by 1 foot of stone, 1 inch of common metal, a thin sheet of lead, or 3 feet of wood or dirt.',
    ],
    [Utilities.NonCombat],
    Times.Action,
    Durations.Minutes(10),
    Ranges.Self,
    AreaOfEffects.Radius(30),
    ToHits.AlwaysWorks,
    Effects.Detect(),
    VSCR,
    'Divination'
  ),

  DISGUISE_SELF: new Spell(
    'Disguise Self',
    1,
    PointType.SpellSlot,
    [
      "You make yourself--including your clothing, armor, weapons, and other belongings on your person--look different until the spell ends or until you use your action to dismiss it. You can seem 1 foot shorter or taller and can appear thin, fat, or in between. You can't change your body type, so you must adopt a form that has the same basic arrangement of limbs. Otherwise, the extent of the illusion is up to you.",
      'The changes wrought by this spell fail to hold up to physical inspection. For example, if you use this spell to add a hat to your outfit, objects pass through the hat, and anyone who touches it would feel nothing or would feel your head and hair. If you use this spell to appear thinner than you are, the hand of someone who reaches out to touch you would bump into you while it was seemingly still in midair.',
      'To discern that you are disguised, a creature can use its action to inspect your appearance and must succeed on an Intelligence (Investigation) check against your spell save DC.',
    ],
    Utilities.NonCombat,
    Times.Action,
    Durations.Hours(1),
    Ranges.Self,
    AreaOfEffects.SingleTarget,
    ToHits.SpellSave(SkillType.INVESTIGATION),
    Effects.Shapechange(),
    VS,
    'Illusion'
  ),

  DISSONANT_WHISPERS: new Spell(
    'Dissonant Whispers',
    1,
    PointType.SpellSlot,
    [
      'You whisper a discordant melody that only one creature of your choice within range can hear, wracking it with terrible pain. The target must make a Wisdom saving throw. On a failed save, it takes 3d6 psychic damage and must immediately use its reaction , if available, to move as far as its speed allows away from you. The creature doesn’t move into obviously dangerous ground, such as a fire or a pit. On a successful save, the target takes half as much damage and doesn’t have to move away. A deafened creature automatically succeeds on the save.',
      'At Higher Levels. When you cast this spell using a spell slot of 2nd level or higher, the damage increases by 1d6 for each slot level above 1st.',
    ],
    Utilities.Combat,
    Times.Action,
    Durations.Instantaneous,
    Ranges.Range(60),
    AreaOfEffects.SingleTarget,
    ToHits.SpellSave(AbilityType.WIS),
    Effects.SpellDamage(Roll.nd(3, 6), DamageTypes.Psychic, true),
    V,
    'Enchantment'
  ),

  EARTH_TREMOR: new Spell(
    'Earth Tremor',
    1,
    PointType.SpellSlot,
    [
      'You cause a tremor in the ground in a 10-foot radius. Each creature other than you in that area must make a Dexterity saving throw. On a failed save, a creature takes 1d6 bludgeoning damage and is knocked prone. If the ground in that area is loose earth or stone, it becomes difficult terrain until cleared.',
      'At Higher Levels. When you cast this spell using a spell slot of 2nd level or higher, the damage increases by 1d6 for each slot level above 1st.',
    ],
    Utilities.Combat,
    Times.Action,
    Durations.Instantaneous,
    Ranges.Self,
    AreaOfEffects.Radius(10),
    ToHits.SpellSave(AbilityType.DEX),
    Effects.SpellDamage(Roll.d(6), DamageTypes.Bludgeoning, true),
    VS,
    'Evocation'
  ),

  FAERIE_FIRE: new Spell(
    'Faerie Fire',
    1,
    PointType.SpellSlot,
    [
      'Each object in a 20-foot cube within range is outlined in blue, green, or violet light (your choice). Any creature in the area when the spell is cast is also outlined in light if it fails a Dexterity saving throw. For the duration, objects and affected creatures shed dim light in a 10-foot radius.',
      "Any attack roll against an affected creature or object has advantage if the attacker can see it, and the affected creature or object can't benefit from being invisible.",
    ],
    Utilities.Combat,
    Times.Action,
    Durations.Minutes(1),
    Ranges.Range(60),
    AreaOfEffects.Cube(20),
    ToHits.SpellSave(AbilityType.DEX),
    Effects.Debuff(),
    VC,
    'Evocation'
  ),

  FEATHER_FALL: new Spell(
    'Feather Fall',
    1,
    PointType.SpellSlot,
    [
      "Choose up to five falling creatures within range. A falling creature's rate of descent slows to 60 feet per round until the spell ends. If the creature lands before the spell ends, it takes no falling damage and can land on its feet, and the spell ends for that creature.",
    ],
    Utilities.NonCombat,
    Times.Reaction,
    Durations.Minutes(1),
    Ranges.Range(60),
    AreaOfEffects.MultipleTargets,
    ToHits.AlwaysWorks,
    Effects.Movement(),
    VM('a small feather or piece of down'),
    'Transmutation'
  ),

  HEALING_WORD: new Spell(
    'Healing Word',
    1,
    PointType.SpellSlot,
    [
      'A creature of your choice that you can see within range regains hit points equal to 1d4 + your spellcasting ability modifier. This spell has no effect on undead or constructs.',
      'When you cast this spell using a spell slot of 2nd level or higher, the healing increases by 1d4 for each slot level above 1st.',
    ],
    [Utilities.Combat, Utilities.NonCombat],
    Times.Bonus,
    Durations.Instantaneous,
    Ranges.Range(60),
    AreaOfEffects.SingleTarget,
    ToHits.AlwaysWorks,
    Effects.Heal(Roll.d(4), true /* useSpellModifier */, true /* extraDetails */),
    V,
    'Evocation'
  ),

  HEROISM: new Spell(
    'Heroism',
    1,
    PointType.SpellSlot,
    [
      'A willing creature you touch is imbued with bravery. Until the spell ends, the creature is immune to being frightened and gains temporary hit points equal to your spellcasting ability modifier at the start of each of its turns. When the spell ends, the target loses any remaining temporary hit points from this spell.',
      'At Higher Levels. When you cast this spell using a spell slot of 2nd level or higher, you can target one additional creature for each slot level above 1st.',
    ],
    Utilities.Combat,
    Times.Action,
    Durations.Minutes(1),
    Ranges.Touch,
    AreaOfEffects.SingleTarget,
    ToHits.AlwaysWorks,
    Effects.Buff(true),
    VSC,
    'Enchantment'
  ),

  HIDEOUS_LAUGHTER: new Spell(
    'Hideous Laughter',
    1,
    PointType.SpellSlot,
    [
      "A creature of your choice that you can see within range perceives everything as hilariously funny and falls into fits of laughter if this spell affects it. The target must succeed on a Wisdom saving throw or fall prone, becoming incapacitated and unable to stand up for the duration. A creature with an Intelligence score of 4 or less isn't affected.",
      "At the end of each of its turns, and each time it takes damage, the target can make another Wisdom saving throw. The target has advantage on the saving throw if it's triggered by damage. On a success, the spell ends.",
    ],
    [Utilities.Combat, Utilities.NonCombat],
    Times.Action,
    Durations.Minutes(1),
    Ranges.Range(30),
    AreaOfEffects.SingleTarget,
    ToHits.SpellSave(AbilityType.WIS, true),
    Effects.Prone(true),
    VSMC('tiny tarts and a feather that is waved in the air'),
    'Enchantment'
  ),

  IDENTIFY: new Spell(
    'Identify',
    1,
    PointType.SpellSlot,
    [
      'You choose one object that you must touch throughout the casting of the spell. If it is a magic item or some other magic-imbued object, you learn its properties and how to use them, whether it requires attunement to use, and how many charges it has, if any. You learn whether any spells are affecting the item and what they are. If the item was created by a spell, you learn which spell created it.',
      'If you instead touch a creature throughout the casting, you learn what spells, if any, are currently affecting it.',
    ],
    Utilities.NonCombat,
    Times.Minutes(1),
    Durations.Instantaneous,
    Ranges.Touch,
    AreaOfEffects.SingleTarget,
    ToHits.AlwaysWorks,
    Effects.Detect(),
    VSMR('a pearl worth at least 100 gp and an owl feather', true),
    'Divination'
  ),

  ILLUSORY_SCRIPT: new Spell(
    'Illusory Script',
    1,
    PointType.SpellSlot,
    [
      'You write on parchment, paper, or some other suitable writing material and imbue it with a potent illusion that lasts for the duration.',
      'To you and any creatures you designate when you cast the spell, the writing appears normal, written in your hand, and conveys whatever meaning you intended when you wrote the text. To all others, the writing appears as if it were written in an unknown or magical script that is unintelligible. Alternatively, you can cause the writing to appear to be an entirely different message, written in a different hand and language, though the language must be one you know.',
      'Should the spell be dispelled, the original script and the illusion both disappear.',
      'A creature with truesight can read the hidden message.',
    ],
    Utilities.NonCombat,
    Times.Minutes(1),
    Durations.Days(10),
    Ranges.Touch,
    AreaOfEffects.SingleTarget,
    ToHits.AlwaysWorks,
    Effects.Communicate(true),
    SMR('a lead-based ink worth at least 10 gp, which the spell consumes', true),
    'Illusion'
  ),

  LONGSTRIDER: new Spell(
    'Longstrider',
    1,
    PointType.SpellSlot,
    [
      "You touch a creature. The target's speed increases by 10 feet until the spell ends.",
      'When you cast this spell using a spell slot of 2nd level or higher, you can target one additional creature for each slot level above 1st.',
    ],
    Utilities.NonCombat,
    Times.Action,
    Durations.Hours(1),
    Ranges.Touch,
    AreaOfEffects.SingleTarget,
    ToHits.AlwaysWorks,
    Effects.Buff(),
    VSM('a pinch of dirt'),
    'Transmutation'
  ),

  SILENT_IMAGE: new Spell(
    'Silent Image',
    1,
    PointType.SpellSlot,
    [
      "You create the image of an object, a creature, or some other visible phenomenon that is no larger than a 15-foot cube. The image appears at a spot within range and lasts for the duration. The image is purely visual; it isn't accompanied by sound, smell, or other sensory effects.",
      'You can use your action to cause the image to move to any spot within range. As the image changes location, you can alter its appearance so that its movements appear natural for the image. For example, if you create an image of a creature and move it, you can alter the image so that it appears to be walking.',
      'Physical interaction with the image reveals it to be an illusion, because things can pass through it. A creature that uses its action to examine the image can determine that it is an illusion with a successful Intelligence (Investigation) check against your spell save DC. If a creature discerns the illusion for what it is, the creature can see through the image.',
    ],
    Utilities.NonCombat,
    Times.Action,
    Durations.Minutes(10),
    Ranges.Range(60),
    AreaOfEffects.Cube(15),
    ToHits.SpellSave(SkillType.INVESTIGATION),
    Effects.Control(true),
    VSMC('a bit of fleece'),
    'Illusion'
  ),

  SLEEP: new Spell(
    'Sleep',
    1,
    PointType.SpellSlot,
    [
      'This spell sends creatures into a magical slumber. Roll 5d8; the total is how many hit points of creatures this spell can affect. Creatures within 20 feet of a point you choose within range are affected in ascending order of their current hit points (ignoring unconscious creatures).',
      "Starting with the creature that has the lowest current hit points, each creature affected by this spell falls unconscious until the spell ends, the sleeper takes damage, or someone uses an action to shake or slap the sleeper awake. Subtract each creature's hit points from the total before moving on to the creature with the next lowest hit points. A creature's hit points must be equal to or less than the remaining total for that creature to be affected.",
      "Undead and creatures immune to being charmed aren't affected by this spell.",
      'At Higher Levels. When you cast this spell using a spell slot of 2nd level or higher, roll an additional 2d8 for each slot level above 1st.',
    ],
    [Utilities.Combat, Utilities.NonCombat],
    Times.Action,
    Durations.Minutes(1),
    Ranges.Range(90),
    AreaOfEffects.Radius(20),
    ToHits.Special,
    Effects.Sleep(),
    VSM('a pinch of fine sand, rose petals, or a cricket'),
    'Enchantment'
  ),

  SPEAK_WITH_ANIMALS: new Spell(
    'Speak with Animals',
    1,
    PointType.SpellSlot,
    [
      "You gain the ability to comprehend and verbally communicate with beasts for the duration. The knowledge and awareness of many beasts is limited by their intelligence, but at minimum, beasts can give you information about nearby locations and monsters, including whatever they can perceive or have perceived within the past day. You might be able to persuade a beast to perform a small favor for you, at the GM's discretion.",
    ],
    Utilities.NonCombat,
    Times.Action,
    Durations.Minutes(10),
    Ranges.Self,
    AreaOfEffects.SingleTarget,
    ToHits.AlwaysWorks,
    Effects.Communicate(true),
    VSR,
    'Divination'
  ),

  // TASHAS_HIDEOUS_LAUGHTER: See "HIDEOUS_LAUGHTER"

  THUNDERWAVE: new Spell(
    'Thunderwave',
    1,
    PointType.SpellSlot,
    [
      "A wave of thunderous force sweeps out from you. Each creature in a 15-foot cube originating from you must make a Constitution saving throw. On a failed save, a creature takes 2d8 thunder damage and is pushed 10 feet away from you. On a successful save, the creature takes half as much damage and isn't pushed.",
      "In addition, unsecured objects that are completely within the area of effect are automatically pushed 10 feet away from you by the spell's effect, and the spell emits a thunderous boom audible out to 300 feet.",
      'At Higher Levels. When you cast this spell using a spell slot of 2nd level or higher, the damage increases by 1d8 for each slot level above 1st.',
    ],
    Utilities.Combat,
    Times.Action,
    Durations.Instantaneous,
    Ranges.Self,
    AreaOfEffects.Cube(15),
    ToHits.SpellSave(AbilityType.CON),
    Effects.SpellDamage(Roll.nd(2, 8), DamageTypes.Thunder, true),
    VS,
    'Evocation'
  ),

  UNSEEN_SERVANT: new Spell(
    'Unseen Servant',
    1,
    PointType.SpellSlot,
    [
      "This spell creates an invisible, mindless, shapeless force that performs simple tasks at your command until the spell ends. The servant springs into existence in an unoccupied space on the ground within range. It has AC 10, 1 hit point, and a Strength of 2, and it can't attack. If it drops to 0 hit points, the spell ends.",
      'Once on each of your turns as a bonus action, you can mentally command the servant to move up to 15 feet and interact with an object. The servant can perform simple tasks that a human servant could do, such as fetching things, cleaning, mending, folding clothes, lighting fires, serving food, and pouring wine. Once you give the command, the servant performs the task to the best of its ability until it completes the task, then waits for your next command.',
      'If you command the servant to perform a task that would move it more than 60 feet away from you, the spell ends.',
    ],
    Utilities.NonCombat,
    Times.Action,
    Durations.Hours(1),
    Ranges.Range(60),
    AreaOfEffects.SingleTarget,
    ToHits.AlwaysWorks,
    Effects.Summon(true),
    VSMR('a piece of string and a bit of wood'),
    'Conjuration'
  ),

  // -------------------
  // Bard - 2
  // new Spell('SPELL', 2, PointType.SpellSlot, [
  //         'DESCRIPTION',
  //     ], [Utilities.Combat, Utilities.NonCombat],
  //     Times.%, Durations.%, Ranges.%, AreaOfEffects.%,
  //     ToHits.%, Effects.%,
  //     REQS, 'SCHOOL')
  ANIMAL_MESSENGER: new Spell(
    'Animal Messenger',
    2,
    PointType.SpellSlot,
    [
      'By means of this spell, you use an animal to deliver a message. Choose a Tiny beast you can see within range, such as a squirrel, a blue jay, or a bat. You specify a location, which you must have visited, and a recipient who matches a general description, such as "a man or woman dressed in the uniform of the town guard" or "a red-haired dwarf wearing a pointed hat." You also speak a message of up to twenty-five words. The target beast travels for the duration of the spell toward the specified location, covering about 50 miles per 24 hours for a flying messenger, or 25 miles for other animals.',
      "When the messenger arrives, it delivers your message to the creature that you described, replicating the sound of your voice. The messenger speaks only to a creature matching the description you gave. If the messenger doesn't reach its destination before the spell ends, the message is lost, and the beast makes its way back to where you cast this spell.",
      'At Higher Levels. If you cast this spell using a spell slot of 3rd level or higher, the duration of the spell increases by 48 hours for each slot level above 2nd.',
    ],
    Utilities.NonCombat,
    Times.Action,
    Durations.Hours(24),
    Ranges.Range(30),
    AreaOfEffects.SingleTarget,
    ToHits.AlwaysWorks,
    Effects.Communicate(true),
    VSMR('a morsel of food'),
    'Enchantment'
  ),

  BLINDNESS_DEAFNESS: [
    new Spell(
      'Blindness',
      2,
      PointType.SpellSlot,
      [
        'You can blind a foe. Choose one creature that you can see within range to make a Constitution saving throw. If it fails, the target is blinded for the duration. At the end of each of its turns, the target can make a Constitution saving throw. On a success, the spell ends.',
        'At Higher Levels. When you cast this spell using a spell slot of 3rd level or higher, you can target one additional creature for each slot level above 2nd.',
      ],
      Utilities.Combat,
      Times.Action,
      Durations.Minutes(1),
      Ranges.Range(30),
      AreaOfEffects.SingleTarget,
      ToHits.SpellSave(AbilityType.CON, true),
      Effects.Blind(),
      V,
      'Necromancy'
    ),
    new Spell(
      'Deafness',
      2,
      PointType.SpellSlot,
      [
        'You can deafen a foe. Choose one creature that you can see within range to make a Constitution saving throw. If it fails, the target is deafened for the duration. At the end of each of its turns, the target can make a Constitution saving throw. On a success, the spell ends.',
        'At Higher Levels. When you cast this spell using a spell slot of 3rd level or higher, you can target one additional creature for each slot level above 2nd.',
      ],
      Utilities.Combat,
      Times.Action,
      Durations.Minutes(1),
      Ranges.Range(30),
      AreaOfEffects.SingleTarget,
      ToHits.SpellSave(AbilityType.CON, true),
      Effects.Deafen(),
      V,
      'Necromancy'
    ),
  ],

  CALM_EMOTIONS: new Spell(
    'Calm Emotions',
    2,
    PointType.SpellSlot,
    [
      'You attempt to suppress strong emotions in a group of people. Each humanoid in a 20-foot-radius sphere centered on a point you choose within range must make a Charisma saving throw; a creature can choose to fail this saving throw if it wishes. If a creature fails its saving throw, choose one of the following two effects.',
      'You can suppress any effect causing a target to be charmed or frightened. When this spell ends, any suppressed effect resumes, provided that its duration has not expired in the meantime.',
      'Alternatively, you can make a target indifferent about creatures of your choice that it is hostile toward. This indifference ends if the target is attacked or harmed by a spell or if it witnesses any of its friends being harmed. When the spell ends, the creature becomes hostile again, unless the GM rules otherwise.',
    ],
    [Utilities.Combat, Utilities.NonCombat],
    Times.Action,
    Durations.Minutes(1),
    Ranges.Range(60),
    AreaOfEffects.Radius(20),
    ToHits.SpellSave(AbilityType.CHA),
    Effects.Charm(true),
    VSC,
    'Enchantment'
  ),

  CLOUD_OF_DAGGERS: new Spell(
    'Cloud of Daggers',
    2,
    PointType.SpellSlot,
    [
      'You fill the air with spinning daggers in a cube 5 feet on each side, centered on a point you choose within range. A creature takes 4d4 slashing damage when it enters the spell’s area for the first time on a turn or starts its turn there.',
      'At Higher Levels. When you cast this spell using a spell slot of 3rd level or higher, the damage increases by 2d4 for each slot level above 2nd.',
    ],
    Utilities.Combat,
    Times.Action,
    Durations.Minutes(1),
    Ranges.Range(60),
    AreaOfEffects.Cube(5),
    ToHits.AlwaysWorks,
    Effects.SpellDamage(Roll.nd(4, 4), DamageTypes.Slashing, true),
    VSMC('a sliver of glass'),
    'Conjuration'
  ),

  CROWN_OF_MADNESS: new Spell(
    'Crown of Madness',
    2,
    PointType.SpellSlot,
    [
      'One humanoid of your choice that you can see within range must succeed on a Wisdom saving throw or become charmed by you for the duration.',
      'While the target is charmed in this way, a twisted crown of jagged iron appears on its head, and a madness glows in its eyes.',
      'The charmed target must use its action before moving on each of its turns to make a melee attack against a creature other than itself that you mentally choose. The target can act normally on its turn if you choose no creature or if none are within its reach.',
      'On your subsequent turns, you must use your action to maintain control over the target, or the spell ends. Also, the target can make a Wisdom saving throw at the end of each of its turns. On a success, the spell ends.',
    ],
    Utilities.Combat,
    Times.Action,
    Durations.Minutes(1),
    Ranges.Range(120),
    AreaOfEffects.SingleTarget,
    ToHits.SpellSave(AbilityType.WIS, true),
    Effects.Charm(true),
    VSC,
    'Enchantment'
  ),

  DETECT_THOUGHTS: new Spell(
    'Detect Thoughts',
    2,
    PointType.SpellSlot,
    [
      "For the duration, you can read the thoughts of certain creatures. When you cast the spell and as your action on each turn until the spell ends, you can focus your mind on any one creature that you can see within 30 feet of you. If the creature you choose has an Intelligence of 3 or lower or doesn't speak any language, the creature is unaffected.",
      "You initially learn the surface thoughts of the creature--what is most on its mind in that moment. As an action, you can either shift your attention to another creature's thoughts or attempt to probe deeper into the same creature's mind. If you probe deeper, the target must make a Wisdom saving throw. If it fails, you gain insight into its reasoning (if any), its emotional state, and something that looms large in its mind (such as something it worries over, loves, or hates). If it succeeds, the spell ends. Either way, the target knows that you are probing into its mind, and unless you shift your attention to another creature's thoughts, the creature can use its action on its turn to make an Intelligence check contested by your Intelligence check; if it succeeds, the spell ends.",
      'Questions verbally directed at the target creature naturally shape the course of its thoughts, so this spell is particularly effective as part of an interrogation.',
      "You can also use this spell to detect the presence of thinking creatures you can't see. When you cast the spell or as your action during the duration, you can search for thoughts within 30 feet of you. The spell can penetrate barriers, but 2 feet of rock, 2 inches of any metal other than lead, or a thin sheet of lead blocks you. You can't detect a creature with an Intelligence of 3 or lower or one that doesn't speak any language.",
      "Once you detect the presence of a creature in this way, you can read its thoughts for the rest of the duration as described above, even if you can't see it, but it must still be within range.",
    ],
    Utilities.NonCombat,
    Times.Action,
    Durations.Minutes(1),
    Ranges.Range(30),
    AreaOfEffects.SingleTarget,
    ToHits.SpellSave(AbilityType.WIS, true),
    Effects.Detect(true),
    VSMC('a copper piece'),
    'Detect Thoughts'
  ),

  ENHANCE_ABILITY: new Spell(
    'Enhance Ability',
    2,
    PointType.SpellSlot,
    [
      'You touch a creature and bestow upon it a magical enhancement. Choose one of the following effects; the target gains that effect until the spell ends.',
      "Bear's Endurance. The target has advantage on Constitution checks. It also gains 2d6 temporary hit points, which are lost when the spell ends.",
      "Bull's Strength. The target has advantage on Strength checks, and his or her carrying capacity doubles.",
      "Cat's Grace. The target has advantage on Dexterity checks. It also doesn't take damage from falling 20 feet or less if it isn't incapacitated.",
      "Eagle's Splendor. The target has advantage on Charisma checks.",
      "Fox's Cunning. The target has advantage on Intelligence checks.",
      "Owl's Wisdom. The target has advantage on Wisdom checks.",
      'At Higher Levels. When you cast this spell using a spell slot of 3rd level or higher, you can target one additional creature for each slot level above 2nd.',
    ],
    Utilities.NonCombat,
    Times.Action,
    Durations.Hours(1),
    Ranges.Touch,
    AreaOfEffects.SingleTarget,
    ToHits.AlwaysWorks,
    Effects.Buff(true),
    VSMC('fur or a feather from a beast'),
    'Transmutation'
  ),

  ENTHRALL: new Spell(
    'Enthrall',
    2,
    PointType.SpellSlot,
    [
      "You weave a distracting string of words, causing creatures of your choice that you can see within range and that can hear you to make a Wisdom saving throw. Any creature that can't be charmed succeeds on this saving throw automatically, and if you or your companions are fighting a creature, it has advantage on the save. On a failed save, the target has disadvantage on Wisdom (Perception) checks made to perceive any creature other than you until the spell ends or until the target can no longer hear you. The spell ends if you are incapacitated or can no longer speak.",
    ],
    Utilities.NonCombat,
    Times.Action,
    Durations.Minutes(1),
    Ranges.Range(60),
    AreaOfEffects.MultipleTargets,
    ToHits.SpellSave(AbilityType.WIS),
    Effects.Control(true),
    VS,
    'Enchantment'
  ),

  HEAT_METAL: new Spell(
    'Heat Metal',
    2,
    PointType.SpellSlot,
    [
      'Choose a manufactured metal object, such as a metal weapon or a suit of heavy or medium metal armor, that you can see within range. You cause the object to glow red-hot. Any creature in physical contact with the object takes 2d8 fire damage when you cast the spell. Until the spell ends, you can use a bonus action on each of your subsequent turns to cause this damage again.',
      "If a creature is holding or wearing the object and takes the damage from it, the creature must succeed on a Constitution saving throw or drop the object if it can. If it doesn't drop the object, it has disadvantage on attack rolls and ability checks until the start of your next turn.",
      'At Higher Levels. When you cast this spell using a spell slot of 3rd level or higher, the damage increases by 1d8 for each slot level above 2nd.',
    ],
    [Utilities.Combat, Utilities.NonCombat],
    Times.Action,
    Durations.Minutes(1),
    Ranges.Range(60),
    AreaOfEffects.SingleTarget,
    ToHits.SpellSave(AbilityType.CON, true),
    Effects.SpellDamage(Roll.nd(2, 8), DamageTypes.Fire),
    VSMC('a piece of iron and a flame'),
    'Transmutation'
  ),

  HOLD_PERSON: new Spell(
    'Hold Person',
    2,
    PointType.SpellSlot,
    [
      'Choose a humanoid that you can see within range. The target must succeed on a Wisdom saving throw or be paralyzed for the duration. At the end of each of its turns, the target can make another Wisdom saving throw. On a success, the spell ends on the target.',
      'At Higher Levels. When you cast this spell using a spell slot of 3rd level or higher, you can target one additional humanoid for each slot level above 2nd. The humanoids must be within 30 feet of each other when you target them.',
    ],
    Utilities.Combat,
    Times.Action,
    Durations.Minutes(1),
    Ranges.Range(60),
    AreaOfEffects.SingleTarget,
    ToHits.SpellSave(AbilityType.WIS, true),
    Effects.Paralyze(),
    VSMC('a small, straight piece of iron'),
    'Enchantment'
  ),

  INVISIBILITY: new Spell(
    'Invisibility',
    2,
    PointType.SpellSlot,
    [
      "A creature you touch becomes invisible until the spell ends. Anything the target is wearing or carrying is invisible as long as it is on the target's person. The spell ends for a target that attacks or casts a spell.",
      'When you cast this spell using a spell slot of 3rd level or higher, you can target one additional creature for each slot level above 2nd',
    ],
    [Utilities.Combat, Utilities.NonCombat],
    Times.Action,
    Durations.Hours(1),
    Ranges.Touch,
    AreaOfEffects.SingleTarget,
    ToHits.AlwaysWorks,
    Effects.Invisibility(),
    VSMC('an eyelash encased in gum arabic'),
    'Illusion'
  ),

  KNOCK: new Spell(
    'Knock',
    2,
    PointType.SpellSlot,
    [
      'Choose an object that you can see within range. The object can be a door, a box, a chest, a set of manacles, a padlock, or another object that contains a mundane or magical means that prevents access.',
      'A target that is held shut by a mundane lock or that is stuck or barred becomes unlocked, unstuck, or unbarred. If the object has multiple locks, only one of them is unlocked.',
      'If you choose a target that is held shut with arcane lock, that spell is suppressed for 10 minutes, during which time the target can be opened and shut normally.',
      'When you cast the spell, a loud knock, audible from as far away as 300 feet, emanates from the target object.',
    ],
    Utilities.NonCombat,
    Times.Action,
    Durations.Instantaneous,
    Ranges.Range(60),
    AreaOfEffects.SingleTarget,
    ToHits.AlwaysWorks,
    Effects.Utility(true),
    V,
    'Transmutation'
  ),

  LESSER_RESTORATION: new Spell(
    'Lesser Restoration',
    2,
    PointType.SpellSlot,
    [
      'You touch a creature and can end either one disease or one condition afflicting it. The condition can be blinded, deafened, paralyzed, or poisoned.',
    ],
    [Utilities.Combat, Utilities.NonCombat],
    Times.Action,
    Durations.Instantaneous,
    Ranges.Touch,
    AreaOfEffects.SingleTarget,
    ToHits.AlwaysWorks,
    Effects.Cure(true),
    VS,
    'Abjuration'
  ),

  LOCATE_ANIMALS_OR_PLANTS: new Spell(
    'Locate Animals or Plants',
    2,
    PointType.SpellSlot,
    [
      'Describe or name a specific kind of beast or plant. Concentrating on the voice of nature in your surroundings, you learn the direction and distance to the closest creature or plant of that kind within 5 miles, if any are present.',
    ],
    Utilities.NonCombat,
    Times.Action,
    Durations.Instantaneous,
    Ranges.Self,
    AreaOfEffects.RadiusMiles(5),
    ToHits.AlwaysWorks,
    Effects.Detect(true),
    VSMR('a bit of fur from a bloodhound'),
    'Divination'
  ),

  LOCATE_OBJECT: new Spell(
    'Locate Object',
    2,
    PointType.SpellSlot,
    [
      "Describe or name an object that is familiar to you. You sense the direction to the object's location, as long as that object is within 1,000 feet of you. If the object is in motion, you know the direction of its movement.",
      'The spell can locate a specific object known to you, as long as you have seen it up close--within 30 feet--at least once. Alternatively, the spell can locate the nearest object of a particular kind, such as a certain kind of apparel, jewelry, furniture, tool, or weapon.',
      "This spell can't locate an object if any thickness of lead, even a thin sheet, blocks a direct path between you and the object.",
    ],
    Utilities.NonCombat,
    Times.Action,
    Durations.Minutes(10),
    Ranges.Range(1000),
    AreaOfEffects.SingleTarget,
    ToHits.AlwaysWorks,
    Effects.Detect(true),
    VSMC('a forked twig'),
    'Locate Object'
  ),

  MAGIC_MOUTH: new Spell(
    'Magic Mouth',
    2,
    PointType.SpellSlot,
    [
      "You implant a message within an object in range, a message that is uttered when a trigger condition is met. Choose an object that you can see and that isn't being worn or carried by another creature. Then speak the message, which must be 25 words or less, though it can be delivered over as long as 10 minutes. Finally, determine the circumstance that will trigger the spell to deliver your message.",
      "When that circumstance occurs, a magical mouth appears on the object and recites the message in your voice and at the same volume you spoke. If the object you chose has a mouth or something that looks like a mouth (for example, the mouth of a statue), the magical mouth appears there so that the words appear to come from the object's mouth. When you cast this spell, you can have the spell end after it delivers its message, or it can remain and repeat its message whenever the trigger occurs.",
      'The triggering circumstance can be as general or as detailed as you like, though it must be based on visual or audible conditions that occur within 30 feet of the object. For example, you could instruct the mouth to speak when any creature moves within 30 feet of the object or when a silver bell rings within 30 feet of it.',
    ],
    Utilities.NonCombat,
    Times.Minutes(1),
    Durations.UntilDispelled,
    Ranges.Range(30),
    AreaOfEffects.SingleTarget,
    ToHits.AlwaysWorks,
    Effects.Communicate(true),
    VSMR('a small bit of honeycomb and jade dust worth at least 10 gp', true),
    'Illusion'
  ),

  PHANTASMAL_FORCE: new Spell(
    'Phantasmal Force',
    2,
    PointType.SpellSlot,
    [
      'You craft an illusion that takes root in the mind of a creature that you can see within range. The target must make an Intelligence saving throw. On a failed save, you create a phantasmal object, creature, or other visible phenomenon of your choice that is no larger than a 10-foot cube and that is perceivable only to the target for the duration. This spell has no effect on undead or constructs.',
      'The phantasm includes sound, temperature, and other stimuli, also evident only to the creature.',
      'The target can use its action to examine the phantasm with an Intelligence (Investigation) check against your spell save DC. If the check succeeds, the target realizes that the phantasm is an illusion, and the spell ends.',
      'While a target is affected by the spell, the target treats the phantasm as if it were real. The target rationalizes any illogical outcomes from interacting with the phantasm. For example, a target attempting to walk across a phantasmal bridge that spans a chasm falls once it steps onto the bridge. If the target survives the fall, it still believes that the bridge exists and comes up with some other explanation for its fallit was pushed, it slipped, or a strong wind might have knocked it off.',
      'An affected target is so convinced of the phantasm’s reality that it can even take damage from the illusion. A phantasm created to appear as a creature can attack the target. Similarly, a phantasm created to appear as fire, a pool of acid, or lava can burn the target. Each round on your turn, the phantasm can deal 1d6 psychic damage to the target if it is in the phantasm’s area or within 5 feet of the phantasm, provided that the illusion is of a creature or hazard that could logically deal damage, such as by attacking. The target perceives the damage as a type appropriate to the illusion.',
    ],
    [Utilities.Combat, Utilities.NonCombat],
    Times.Action,
    Durations.Minutes(1),
    Ranges.Range(60),
    AreaOfEffects.Cube(10),
    ToHits.SpellSave(AbilityType.INT, true),
    Effects.SpellDamage(Roll.d(6), DamageTypes.Psychic, true),
    VSMC('a bit of fleece'),
    'Illusion'
  ),

  PYROTECHNICS: new Spell(
    'Pyrotechnics',
    2,
    PointType.SpellSlot,
    [
      'Choose an area of flame that you can see and that can fit within a 5-foot cube within range. You can extinguish the fire in that area, and you create either fireworks or smoke.',
      'Fireworks. The target explodes with a dazzling display of colors. Each creature within 10 feet of the target must succeed on a Constitution saving throw or become blinded until the end of your next turn.',
      'Smoke. Thick black smoke spreads out from the target in a 20-foot radius, moving around corners.',
      'The area of the smoke is heavily obscured. The smoke persists for 1 minute or until a strong wind disperses it.',
    ],
    [Utilities.Combat, Utilities.NonCombat],
    Times.Action,
    Durations.Instantaneous,
    Ranges.Range(60),
    AreaOfEffects.Cube(5),
    ToHits.SpellSave(AbilityType.CON, true),
    Effects.Blind(true),
    VS,
    'Transmutation'
  ),

  SEE_INVISIBILITY: new Spell(
    'See Invisibility',
    2,
    PointType.SpellSlot,
    [
      'For the duration, you see invisible creatures and objects as if they were visible, and you can see into the Ethereal Plane. Ethereal creatures and objects appear ghostly and translucent.',
    ],
    Utilities.NonCombat,
    Times.Action,
    Durations.Hours(1),
    Ranges.Self,
    AreaOfEffects.SingleTarget,
    ToHits.AlwaysWorks,
    Effects.Detect(true),
    VSM('a pinch of talc and a small sprinkling of powdered silver'),
    'Divination'
  ),

  SHATTER: new Spell(
    'Shatter',
    2,
    PointType.SpellSlot,
    [
      'A sudden loud ringing noise, painfully intense, erupts from a point of your choice within range. Each creature in a 10-foot-radius sphere centered on that point must make a Constitution saving throw. A creature takes 3d8 thunder damage on a failed save, or half as much damage on a successful one. A creature made of inorganic material such as stone, crystal, or metal has disadvantage on this saving throw.',
      "A nonmagical object that isn't being worn or carried also takes the damage if it's in the spell's area.",
      'At Higher Levels. When you cast this spell using a spell slot of 3rd level or higher, the damage increases by 1d8 for each slot level above 2nd.',
    ],
    Utilities.Combat,
    Times.Action,
    Durations.Instantaneous,
    Ranges.Range(60),
    AreaOfEffects.Radius(10),
    ToHits.SpellSave(AbilityType.CON),
    Effects.SpellDamage(Roll.nd(3, 8), DamageTypes.Thunder, true),
    VSM('a chip of mica'),
    'Evocation'
  ),

  SILENCE: new Spell(
    'Silence',
    2,
    PointType.SpellSlot,
    [
      'For the duration, no sound can be created within or pass through a 20-foot-radius sphere centered on a point you choose within range. Any creature or object entirely inside the sphere is immune to thunder damage, and creatures are deafened while entirely inside it. Casting a spell that includes a verbal component is impossible there.',
    ],
    [Utilities.Combat, Utilities.NonCombat],
    Times.Action,
    Durations.Minutes(10),
    Ranges.Range(120),
    AreaOfEffects.Radius(20),
    ToHits.AlwaysWorks,
    Effects.Control(true),
    VSCR,
    'Illusion'
  ),

  SKYWRITE: new Spell(
    'Skywrite',
    2,
    PointType.SpellSlot,
    [
      'You cause up to ten words to form in a part of the sky you can see. The words appear to be made of cloud and remain in place for the spell’s duration. The words dissipate when the spell ends. A strong wind can disperse the clouds and end the spell early.',
    ],
    Utilities.NonCombat,
    Times.Action,
    Durations.Hours(1),
    Ranges.Sight,
    AreaOfEffects.SingleTarget,
    ToHits.AlwaysWorks,
    Effects.Communicate(true),
    VSCR,
    'Transmutation'
  ),

  SUGGESTION: new Spell(
    'Suggestion',
    2,
    PointType.SpellSlot,
    [
      "You suggest a course of activity (limited to a sentence or two) and magically influence a creature you can see within range that can hear and understand you. Creatures that can't be charmed are immune to this effect. The suggestion must be worded in such a manner as to make the course of action sound reasonable. Asking the creature to stab itself, throw itself onto a spear, immolate itself, or do some other obviously harmful act ends the spell.",
      'The target must make a Wisdom saving throw. On a failed save, it pursues the course of action you described to the best of its ability. The suggested course of action can continue for the entire duration. If the suggested activity can be completed in a shorter time, the spell ends when the subject finishes what it was asked to do.',
      "You can also specify conditions that will trigger a special activity during the duration. For example, you might suggest that a knight give her warhorse to the first beggar she meets. If the condition isn't met before the spell expires, the activity isn't performed.",
      'If you or any of your companions damage the target, the spell ends.',
    ],
    Utilities.NonCombat,
    Times.Action,
    Durations.Hours(8),
    Ranges.Range(30),
    AreaOfEffects.SingleTarget,
    ToHits.SpellSave(AbilityType.WIS),
    Effects.Charm(true),
    VMC("a snake's tongue and either a bit of honeycomb or a drop of sweet oil"),
    'Enchantment'
  ),

  WARDING_WIND: new Spell(
    'Warding Wind',
    2,
    PointType.SpellSlot,
    [
      'A strong wind (20 miles per hour) blows around you in a 10-foot radius and moves with you, remaining centered on you. The wind lasts for the spell’s duration.',
      'The wind has the following effects:',
      '* It deafens you and other creatures in its area.',
      '* It extinguishes unprotected flames in its area that are torch-sized or smaller.',
      '* The area is difficult terrain for creatures other than you.',
      '* The attack rolls of ranged weapon attacks have disadvantage if they pass in or out of the wind.',
      '* It hedges out vapor, gas, and fog that can be dispersed by strong wind.',
    ],
    [Utilities.Combat, Utilities.NonCombat],
    Times.Action,
    Durations.Minutes(10),
    Ranges.Self,
    AreaOfEffects.Radius(10),
    ToHits.AlwaysWorks,
    Effects.Deafen(true),
    VC,
    'Evocation'
  ),

  ZONE_OF_TRUTH: new Spell(
    'Zone of Truth',
    2,
    PointType.SpellSlot,
    [
      "You create a magical zone that guards against deception in a 15-foot-radius sphere centered on a point of your choice within range. Until the spell ends, a creature that enters the spell's area for the first time on a turn or starts its turn there must make a Charisma saving throw. On a failed save, a creature can't speak a deliberate lie while in the radius. You know whether each creature succeeds or fails on its saving throw.",
      'An affected creature is aware of the spell and can thus avoid answering questions to which it would normally respond with a lie. Such a creature can be evasive in its answers as long as it remains within the boundaries of the truth.',
    ],
    Utilities.NonCombat,
    Times.Action,
    Durations.Minutes(10),
    Ranges.Range(60),
    AreaOfEffects.Radius(15),
    ToHits.SpellSave(AbilityType.CHA),
    Effects.Control(true),
    VS,
    'Enchantment'
  ),

  // -------------------
  // Bard - 3
  // new Spell('SPELL', 3, PointType.SpellSlot, [
  //         'DESCRIPTION',
  //     ], [Utilities.Combat, Utilities.NonCombat],
  //     Times.%, Durations.%, Ranges.%, AreaOfEffects.%,
  //     ToHits.%, Effects.%,
  //     REQS, 'SCHOOL')
  // -------------------
  // Bard - 4
  // -------------------
  // Bard - 5
  // -------------------
  // Bard - 6
  // -------------------
  // Bard - 7
  // -------------------
  // Bard - 8
  // -------------------
  // Bard - 9

  // -------------------
  // Cleric - Cantrips
  GUIDANCE: new Spell(
    'Guidance',
    0,
    PointType.SpellSlot,
    [
      'You touch one willing creature. Once before the spell ends, the target can roll a d4 and add the number rolled to one ability check of its choice. It can roll the die before or after making the ability check. The spell then ends.',
    ],
    [Utilities.Combat, Utilities.NonCombat],
    Times.Action,
    Durations.Minutes(1),
    Ranges.Touch,
    AreaOfEffects.SingleTarget,
    ToHits.AlwaysWorks,
    Effects.Buff(),
    VSC,
    'Divination'
  ),

  // LIGHT: Included above

  // MENDING: Included above

  RESISTANCE: new Spell(
    'Resistance',
    0,
    PointType.SpellSlot,
    [
      'You touch one willing creature. Once before the spell ends, the target can roll a d4 and add the number rolled to one saving throw of its choice. It can roll the die before or after making the saving throw. The spell then ends.',
    ],
    [Utilities.Combat, Utilities.NonCombat],
    Times.Action,
    Durations.Minutes(1),
    Ranges.Touch,
    AreaOfEffects.SingleTarget,
    ToHits.AlwaysWorks,
    Effects.Buff(),
    VSMC('a miniature cloak'),
    'Abjuration'
  ),

  SACRED_FLAME: new Spell(
    'Sacred Flame',
    0,
    PointType.SpellSlot,
    [
      'Flame-like radiance descends on a creature that you can see within range. The target must succeed on a Dexterity saving throw or take 1d8 radiant damage. The target gains no benefit from cover for this saving throw.',
      "The spell's damage increases by 1d8 when you reach 5th level (2d8), 11th level (3d8), and 17th level (4d8).",
    ],
    Utilities.Combat,
    Times.Action,
    Durations.Instantaneous,
    Ranges.Range(60),
    AreaOfEffects.SingleTarget,
    ToHits.SpellSave(AbilityType.DEX, true),
    Effects.SpellDamageByCasterLevel(Roll.d(8), DamageTypes.Radiant, {
      5: Roll.d(8),
      11: Roll.d(8),
      17: Roll.d(8),
    }),
    VS,
    'Evocation'
  ),

  SPARE_THE_DYING: new Spell(
    'Spare the Dying',
    0,
    PointType.SpellSlot,
    [
      'You touch a living creature that has 0 hit points. The creature becomes stable. This spell has no effect on undead or constructs.',
    ],
    [Utilities.Combat, Utilities.NonCombat],
    Times.Action,
    Durations.Instantaneous,
    Ranges.Touch,
    AreaOfEffects.SingleTarget,
    ToHits.AlwaysWorks,
    Effects.Stablize(),
    VS,
    'Necromancy'
  ),

  THAUMATURGY: new Spell(
    'Thaumaturgy',
    0,
    PointType.SpellSlot,
    [
      'You manifest a minor wonder, a sign of supernatural power, within range. You create one of the following magical effects within range:',
      '* Your voice booms up to three times as loud as normal for 1 minute.',
      '* You cause flames to flicker, brighten, dim, or change color for 1 minute.',
      '* You cause harmless tremors in the ground for 1 minute.',
      '* You create an instantaneous sound that originates from a point of your choice within range, such as a rumble of thunder, the cry of a raven, or ominous whispers.',
      '* You instantaneously cause an unlocked door or window to fly open or slam shut.',
      '* You alter the appearance of your eyes for 1 minute.',
      'If you cast this spell multiple times, you can have up to three of its 1-minute effects active at a time, and you can dismiss such an effect as an action.',
    ],
    Utilities.NonCombat,
    Times.Action,
    Durations.Minutes(1),
    Ranges.Range(30),
    AreaOfEffects.SingleTarget,
    ToHits.AlwaysWorks,
    Effects.Control(true),
    V,
    'Thaumaturgy'
  ),

  // -------------------
  // Cleric - 1
  // new Spell('SPELL', 1, PointType.SpellSlot, [
  //         'DESCRIPTION',
  //     ], [Utilities.Combat, Utilities.NonCombat],
  //     Times.%, Durations.%, Ranges.%, AreaOfEffects.%,
  //     ToHits.%, Effects.%,
  //     REQS, 'SCHOOL')
  // BANE: Included above

  BLESS: null, // VSMC

  COMMAND: null, // V

  CREATE_OR_DESTROY_WATER: [
    new Spell(
      'Create Water',
      1,
      PointType.SpellSlot,
      [
        'You create up to 10 gallons of clean water within range in an open container. Alternatively, the water falls as rain in a 30-foot cube within range, extinguishing exposed flames in the area.',
        'When you cast this spell using a spell slot of 2nd level or higher, you create 10 additional gallons of water, or the size of the cube increases by 5 feet, for each slot level above 1st.',
      ],
      Utilities.NonCombat,
      Times.Action,
      Durations.Instantaneous,
      Ranges.Range(30),
      AreaOfEffects.Cube(30),
      ToHits.AlwaysWorks,
      Effects.Create(),
      VSM('a drop of water'),
      'Transmutation'
    ),
    new Spell(
      'Destroy Water',
      1,
      PointType.SpellSlot,
      [
        'You destroy up to 10 gallons of water in an open container within range. Alternatively, you destroy fog in a 30-foot cube within range.',
        'When you cast this spell using a spell slot of 2nd level or higher, you destroy 10 additional gallons of water, or the size of the cube increases by 5 feet, for each slot level above 1st.',
      ],
      Utilities.NonCombat,
      Times.Action,
      Durations.Instantaneous,
      Ranges.Range(30),
      AreaOfEffects.Cube(30),
      ToHits.AlwaysWorks,
      Effects.Destroy(),
      VSM('a few grains of sand'),
      'Transmutation'
    ),
  ],

  // CURE_WOUNDS: Included above

  DETECT_EVIL_AND_GOOD: null, // VSC

  // DETECT_MAGIC: Included above

  DETECT_POISON_AND_DISEASE: new Spell(
    'Detect Poison and Disease',
    1,
    PointType.SpellSlot,
    [
      'For the duration, you can sense the presence and location of poisons, poisonous creatures, and diseases within 30 feet of you. You also identify the kind of poison, poisonous creature, or disease in each case.',
      'The spell can penetrate most barriers, but it is blocked by 1 foot of stone, 1 inch of common metal, a thin sheet of lead, or 3 feet of wood or dirt.',
    ],
    Utilities.NonCombat,
    Times.Action,
    Durations.Minutes(10),
    Ranges.Self,
    AreaOfEffects.Radius(30),
    ToHits.AlwaysWorks,
    Effects.Detect(true),
    VSMCR('a yew leaf'),
    'Divination'
  ),

  GUIDING_BOLT: null, // VS

  // HEALING_WORD: Included above

  INFLICT_WOUNDS: null, // VS

  PROTECTION_FROM_EVIL_AND_GOOD: null, /// VSMC

  PURIFY_FOOD_AND_DRINK: null, // VSR

  SANCTUARY: null, // VSM

  SHIELD_OF_FAITH: null, // VSMC

  // -------------------
  // Cleric - 2
  // new Spell('SPELL', 2, PointType.SpellSlot, [
  //         'DESCRIPTION',
  //     ], [Utilities.Combat, Utilities.NonCombat],
  //     Times.%, Durations.%, Ranges.%, AreaOfEffects.%,
  //     ToHits.%, Effects.%,
  //     REQS, 'SCHOOL')
  AID: null, // VSM

  AUGURY: null, // VSMR

  // BLINDNESS_DEAFNESS: Included above

  // CALM_EMOTIONS: Included above

  CONTINUAL_FLAME: null, // VSM

  // ENHANCE_ABILITY: Included above

  FIND_TRAPS: null, // VS

  GENTLE_REPOSE: null, // VSMR

  // HOLD_PERSON: Included above

  // LESSER_RESTORATION: Included above

  // LOCATE_OBJECT: Included above

  PRAYER_OF_HEALING: null, // V

  PROTECTION_FROM_POISON: null, // VS

  // SILENCE: Included above

  SPIRITUAL_WEAPON: null, // VS

  WARDING_BOND: null, // VSM

  // ZONE_OF_TRUTH: Included above

  // -------------------
  // Cleric - 3
  // new Spell('SPELL', 3, PointType.SpellSlot, [
  //         'DESCRIPTION',
  //     ], [Utilities.Combat, Utilities.NonCombat],
  //     Times.%, Durations.%, Ranges.%, AreaOfEffects.%,
  //     ToHits.%, Effects.%,
  //     REQS, 'SCHOOL')
  // -------------------
  // Cleric - 4
  // -------------------
  // Cleric - 5
  // -------------------
  // Cleric - 6
  // -------------------
  // Cleric - 7
  // -------------------
  // Cleric - 8
  // -------------------
  // Cleric - 9

  // -------------------
  // Druid - Cantrips
  CONTROL_FLAMES: new Spell(
    'Control Flames',
    0,
    PointType.SpellSlot,
    [
      'You choose nonmagical flame that you can see within range and that fits within a 5-foot cube. You affect it in one of the following ways:',
      '* You instantaneously expand the flame 5 feet in one direction, provided that wood or other fuel is present in the new location.',
      '* You instantaneously extinguish the flames within the cube.',
      '* You double or halve the area of bright light and dim light cast by the flame, change its color, or both. The change lasts for 1 hour.',
      '* You cause simple shapes—such as the vague form of a creature, an inanimate object, or a location—to appear within the flames and animate as you like. The shapes last for 1 hour.',
      'If you cast this spell multiple times, you can have up to three of its non-instantaneous effects active at a time, and you can dismiss such an effect as an action.',
    ],
    Utilities.NonCombat,
    Times.Action,
    Durations.Instantaneous,
    Ranges.Range(30),
    AreaOfEffects.Cube(5),
    ToHits.AlwaysWorks,
    Effects.Control(),
    S,
    'Transmutation'
  ),

  CREATE_BONFIRE: new Spell(
    'Create Bonfire',
    0,
    PointType.SpellSlot,
    [
      'You create a bonfire on ground that you can see within range. Until the spells ends, the bonfire fills a 5-foot cube. Any creature in the bonfire’s space when you cast the spell must succeed on a Dexterity saving throw or take 1d8 fire damage. A creature must also make the saving throw when it enters the bonfire’s space for the first time on a turn or ends its turn there.',
      'The spell’s damage increases by 1d8 when you reach 5th level (2d8), 11th level (3d8), and 17th level (4d8).',
    ],
    [Utilities.Combat, Utilities.NonCombat],
    Times.Action,
    Durations.Minutes(1),
    Ranges.Range(60),
    AreaOfEffects.Cube(5),
    ToHits.SpellSave(AbilityType.DEX, true),
    Effects.SpellDamageByCasterLevel(
      Roll.d(8),
      DamageTypes.Fire,
      { 5: Roll.d(8), 11: Roll.d(8), 17: Roll.d(8) },
      true /* hasSpecialDetails */
    ),
    VSC,
    'Conjuration'
  ),

  DRUIDCRAFT: new Spell(
    'Druidcraft',
    0,
    PointType.SpellSlot,
    [
      'Whispering to the spirits of nature, you create one of the following effects within range:',
      '* You create a tiny, harmless sensory effect that predicts what the weather will be at your location for the next 24 hours. The effect might manifest as a golden orb for clear skies, a cloud for rain, falling snowflakes for snow, and so on. This effect persists for 1 round.',
      '* You instantly make a flower blossom, a seed pod open, or a leaf bud bloom.',
      '* You create an instantaneous, harmless sensory effect, such as falling leaves, a puff of wind, the sound of a small animal, or the faint odor of skunk. The effect must fit in a 5-foot cube.',
      '* You instantly light or snuff out a candle, a torch, or a small campfire.',
    ],
    Utilities.NonCombat,
    Times.Action,
    Durations.Instantaneous,
    Ranges.Range(30),
    AreaOfEffects.SingleTarget,
    ToHits.AlwaysWorks,
    Effects.Control(),
    VS,
    'Enchantment'
  ),

  FROSTBITE: new Spell(
    'Frostbite',
    0,
    PointType.SpellSlot,
    [
      'You cause numbing frost to form on one creature that you can see within range. The target must make a Constitution saving throw. On a failed save, the target takes 1d6 cold damage, and it has disadvantage on the next weapon attack roll it makes before the end of its next turn.',
      'The spell’s damage increases by 1d6 when you reach 5th level (2d6), 11th level (3d6), and 17th level (4d6).',
    ],
    Utilities.Combat,
    Times.Action,
    Durations.Instantaneous,
    Ranges.Range(60),
    AreaOfEffects.SingleTarget,
    ToHits.SpellSave(AbilityType.CON),
    Effects.SpellDamageByCasterLevel(
      Roll.d(6),
      DamageTypes.Cold,
      { 5: Roll.d(6), 11: Roll.d(6), 17: Roll.d(6) },
      true /* hasSpecialDetails */
    ),
    VS,
    'Evocation'
  ),

  // GUIDANCE: Included above

  GUST: new Spell(
    'Gust',
    0,
    PointType.SpellSlot,
    [
      'You seize the air and compel it to create one of the following effects at a point you can see within rang',
      '* One Medium or smaller creature that you choose must succeed on a Strength saving throw or be pushed up to 5 feet away from you.',
      '* You create a small blast of air capable of moving one object that is neither held nor carried and that weighs no more than 5 pounds. The object is pushed up to 10 feet away from you. It isn’t pushed with enough force to cause damage.',
      '* You create a harmless sensory affect using air, such as causing leaves to rustle, wind to slam shutters shut, or your clothing to ripple in a breeze.',
    ],
    [Utilities.Combat, Utilities.NonCombat],
    Times.Action,
    Durations.Instantaneous,
    Ranges.Range(30),
    AreaOfEffects.SingleTarget,
    ToHits.SpellSave(AbilityType.STR),
    Effects.Control(),
    VS,
    'Transmutation'
  ),

  MAGIC_STONE: [
    new Spell(
      'Magic Stone (Activate)',
      0,
      PointType.SpellSlot,
      [
        'You touch one to three pebbles and imbue them with magic. You or someone else can make a ranged spell attack with one of the pebbles by throwing it or hurling it with a sling. If thrown, it has a range of 60 feet. If someone else attacks with the pebble, that attacker adds your spellcasting ability modifier, not the attacker’s, to the attack roll. On a hit, the target takes bludgeoning damage equal to 1d6 + your spellcasting ability modifier. Hit or miss, the spell then ends on the stone.',
        'If you cast this spell again, the spell ends early on any pebbles still affected by it.',
      ],
      [Utilities.Combat],
      Times.Bonus,
      Durations.Minutes(1),
      Ranges.Touch,
      AreaOfEffects.SingleTarget,
      ToHits.AlwaysWorks,
      Effects.Create(),
      VS,
      'Transmutation'
    ),
    new Spell(
      'Magic Stone (Throw)',
      0,
      PointType.SpellSlot,
      [
        'You (or someone else) throw one pebble imbued by Magic Stone. See Magic Stone (Activate) for more details.',
      ],
      [Utilities.Combat],
      Times.Action,
      Durations.Instantaneous,
      Ranges.Range(60),
      AreaOfEffects.SingleTarget,
      ToHits.SpellAttackRoll(true),
      Effects.SpellDamageWithSpellModifier(Roll.d(6), DamageTypes.Bludgeoning),
      VS,
      'Transmutation'
    ),
  ],

  // MENDING: Included above

  MOLD_EARTH: new Spell(
    'Mold Earth',
    0,
    PointType.SpellSlot,
    [
      'You choose a portion of dirt or stone that you can see within range and that fits within a 5-foot cube. You manipulate it in one of the following ways:',
      '* If you target an area of loose earth, you can instantaneously excavate it, move it along the ground, and deposit it up to 5 feet away. This movement doesn’t have enough force to cause damage.',
      '* You cause shapes, colors, or both to appear on the dirt or stone, spelling out words, creating images, or shaping patterns. The changes last for 1 hour.',
      '* If the dirt or stone you target is on the ground, you cause it to become difficult terrain. Alternatively, you can cause the ground to become normal terrain if it is already difficult terrain. This change lasts for 1 hour.',
      'If you cast this spell multiple times, you can have no more than two of its non-instantaneous effects active at a time, and you can dismiss such an effect as an action.',
    ],
    [Utilities.NonCombat],
    Times.Action,
    Durations.Instantaneous,
    Ranges.Range(30),
    AreaOfEffects.Cube(5),
    ToHits.AlwaysWorks,
    Effects.Control(),
    S,
    'Transmutation'
  ),

  POISON_SPRAY: null, // VS

  PRODUCE_FLAME: [
    new Spell(
      'Produce Flame (Produce)',
      0,
      PointType.SpellSlot,
      [
        'A flickering flame appears in your hand. The flame remains there for the duration and harms neither you nor your equipment. The flame sheds bright light in a 10-foot radius and dim light for an additional 10 feet. The spell ends if you dismiss it as an action or if you cast it again.',
        'You can also attack with the flame, although doing so ends the spell. When you cast this spell, or as an action on a later turn, you can hurl the flame at a creature within 30 feet of you. Make a ranged spell attack. On a hit, the target takes 1d8 fire damage.',
        "This spell's damage increases by 1d8 when you reach 5th level (2d8), 11th level (3d8), and 17th level (4d8).",
      ],
      Utilities.NonCombat,
      Times.Action,
      Durations.Minutes(10),
      Ranges.Self,
      AreaOfEffects.SingleTarget,
      ToHits.AlwaysWorks,
      Effects.Create(),
      VS,
      'Conjuration'
    ),
    new Spell(
      'Produce Flame (Throw)',
      0,
      PointType.SpellSlot,
      [
        'A flickering flame appears in your hand. The flame remains there for the duration and harms neither you nor your equipment. The flame sheds bright light in a 10-foot radius and dim light for an additional 10 feet. The spell ends if you dismiss it as an action or if you cast it again.',
        'You can also attack with the flame, although doing so ends the spell. When you cast this spell, or as an action on a later turn, you can hurl the flame at a creature within 30 feet of you. Make a ranged spell attack. On a hit, the target takes 1d8 fire damage.',
        "This spell's damage increases by 1d8 when you reach 5th level (2d8), 11th level (3d8), and 17th level (4d8).",
      ],
      Utilities.Combat,
      Times.Action,
      Durations.Minutes(10),
      Ranges.Range(30),
      AreaOfEffects.SingleTarget,
      ToHits.SpellAttackRoll(),
      Effects.SpellDamageByCasterLevel(
        Roll.d(8),
        DamageTypes.Fire,
        { 5: Roll.d(8), 11: Roll.d(8), 17: Roll.d(8) },
        true /* hasSpecialDetails */
      ),
      VS,
      'Conjuration'
    ),
  ],

  // RESISTANCE: Included above

  SHAPE_WATER: new Spell(
    'Shape Water',
    0,
    PointType.SpellSlot,
    [
      'You choose an area of water that you can see within range and that fits within a 5-foot cube. You manipulate it in one of the following ways:',
      "* You instantaneously move or otherwise change the flow of the water as you direct, up to 5 feet in any direction. This movement doesn't have enough force to cause damage.",
      '* You cause the water to form into simple shapes and animate at your direction. This change lasts for 1 hour.',
      "* You change the water's color or opacity. The water must be changed in the same way throughout. This change lasts for 1 hour.",
      '* You freeze the water, provided that there are no creatures in it. The water unfreezes in 1 hour.',
      'If you cast this spell multiple times, you can have no more than two of its non-instantaneous effects active at a time, and you can dismiss such an effect as an action.',
    ],
    Utilities.NonCombat,
    Times.Action,
    Durations.Instantaneous,
    Ranges.Range(30),
    AreaOfEffects.Cube(5),
    ToHits.AlwaysWorks,
    Effects.Control(true),
    S,
    'Transmutation'
  ),

  SHILLELAGH: null, // VSM

  THORN_WHIP: new Spell(
    'Thorn Whip',
    0,
    PointType.SpellSlot,
    [
      'You create a long, vine-like whip covered in thorns that lashes out at your command toward a creature in range. Make a melee spell attack against the target. If the attack hits, the creature takes 1d6 piercing damage, and if the creature is Large or smaller, you pull the creature up to 10 feet closer to you.',
      "The spell's damage increases by 1d6 when you reach 5th level (2d6), 11th level (3d6), and 17th level (4d6).",
    ],
    Utilities.Combat,
    Times.Action,
    Durations.Instantaneous,
    Ranges.Range(30),
    AreaOfEffects.SingleTarget,
    ToHits.SpellAttackRoll(),
    Effects.SpellDamageByCasterLevel(
      Roll.d(6),
      DamageTypes.Piercing,
      { 5: Roll.d(6), 11: Roll.d(6), 17: Roll.d(6) },
      true /* hasSpecialDetails */
    ),
    VSM('the stem of a plant with thorns'),
    'Transmutation'
  ),

  // THUNDERCLAP: Included above

  // -------------------
  // Druid - 1
  // new Spell('SPELL', 1, PointType.SpellSlot, [
  //         'DESCRIPTION',
  //     ], [Utilities.Combat, Utilities.NonCombat],
  //     Times.%, Durations.%, Ranges.%, AreaOfEffects.%,
  //     ToHits.%, Effects.%,
  //     REQS, 'SCHOOL')
  ABSORB_ELEMENTS: null, // S

  // ANIMAL_FRIENDSHIP: Included above

  BEAST_BOND: new Spell(
    'Beast Bond',
    1,
    PointType.SpellSlot,
    [
      'You establish a telepathic link with one beast you touch that is friendly to you or charmed by you. The spell fails if the beast’s Intelligence is 4 or higher. Until the spell ends, the link is active while you and the beast are within line of sight of each other. Through the link, the beast can understand your telepathic messages to it, and it can telepathically communicate simple emotions and concepts back to you. While the link is active, the beast gains advantage on attack rolls against any creature within 5 feet of you that you can see.',
    ],
    [Utilities.NonCombat],
    Times.Action,
    Durations.Minutes(10),
    Ranges.Touch,
    AreaOfEffects.SingleTarget,
    ToHits.AlwaysWorks,
    Effects.Buff(true),
    VSMC('a bit of fur wrapped in a cloth'),
    'Divination'
  ),

  // CHARM_PERSON: Included above,

  // CREATE_OR_DESTROY_WATER: Included above

  // CURE_WOUNDS: Included above

  // DETECT_MAGIC: Included above

  // DETECT_POISON_AND_DISEASE: Included above

  // EARTH_TREMOR: Included above

  ENTANGLE: new Spell(
    'Entangle',
    1,
    PointType.SpellSlot,
    [
      'Grasping weeds and vines sprout from the ground in a 20-foot square starting from a point within range. For the duration, these plants turn the ground in the area into difficult terrain.',
      'A creature in the area when you cast the spell must succeed on a Strength saving throw or be restrained by the entangling plants until the spell ends. A creature restrained by the plants can use its action to make a Strength check against your spell save DC. On a success, it frees itself.',
      'When the spell ends, the conjured plants wilt away.',
    ],
    Utilities.Combat,
    Times.Action,
    Durations.Minutes(1),
    Ranges.Range(90),
    AreaOfEffects.Square(20),
    ToHits.SpellSave(AbilityType.STR, true /* extraDetails */),
    Effects.Restrain(),
    VSC,
    'Conjuration'
  ),

  // FAERIE_FIRE: Included above

  FOG_CLOUD: new Spell(
    'Fog Cloud',
    1,
    PointType.SpellSlot,
    [
      'You create a 20-foot-radius sphere of fog centered on a point within range. The sphere spreads around corners, and its area is heavily obscured. It lasts for the duration or until a wind of moderate or greater speed (at least 10 miles per hour) disperses it.',
      'When you cast this spell using a spell slot of 2nd level or higher, the radius of the fog increases by 20 feet for each slot level above 1st.',
    ],
    [Utilities.Combat, Utilities.NonCombat],
    Times.Action,
    Durations.Hours(1),
    Ranges.Range(120),
    AreaOfEffects.Radius(20),
    ToHits.AlwaysWorks,
    Effects.Control(true),
    VSC,
    'Conjuration'
  ),

  GOODBERRY: [
    new Spell(
      'Goodberry (Create)',
      1,
      PointType.SpellSlot,
      [
        'Up to ten berries appear in your hand and are infused with magic for the duration. A creature can use its action to eat one berry. Eating a berry restores 1 hit point, and the berry provides enough nourishment to sustain a creature for one day.',
        'The berries lose their potency if they have not been consumed within 24 hours of the casting of this spell.',
      ],
      Utilities.NonCombat,
      Times.Action,
      Durations.Instantaneous,
      Ranges.Touch,
      AreaOfEffects.SingleTarget,
      ToHits.AlwaysWorks,
      Effects.Create(),
      VSM('a sprig of mistletoe'),
      'Transmutation'
    ),
    new Spell(
      'Goodberry (Consume)',
      1,
      PointType.SpellSlot,
      [
        'You (or some other creature) eat one goodberry. Eating a berry restores 1 hit point, and the berry provides enough nourishment to sustain a creature for one day.',
        'The berries lose their potency if they have not been consumed within 24 hours of creation.',
      ],
      Utilities.NonCombat,
      Times.Action,
      Durations.Instantaneous,
      Ranges.Touch,
      AreaOfEffects.SingleTarget,
      ToHits.AlwaysWorks,
      Effects.Heal(Roll.constant(1), false /* useSpellModifier */, true /* extraDetails */),
      VSM('a sprig of mistletoe'),
      'Transmutation'
    ),
  ],

  // HEALING_WORD: Included above

  ICE_KNIFE: new Spell(
    'Ice Knife',
    1,
    PointType.SpellSlot,
    [
      'You create a shard of ice and fling it at one creature within range. Make a ranged spell attack against the target. On a hit, the target takes 1d10 piercing damage. Hit or miss, the shard then explodes. The target and each creature within 5 feet of it must succeed on a Dexterity saving throw or take 2d6 cold damage.',
      'At Higher Levels. When you cast this spell using a spell slot of 2nd level or higher, the cold damage increases by 1d6 for each slot level above 1st.',
    ],
    Utilities.Combat,
    Times.Action,
    Durations.Instantaneous,
    Ranges.Range(60),
    AreaOfEffects.Radius(5),
    ToHits.SpellAttackRoll(true),
    Effects.SpellDamage(Roll.d(10), DamageTypes.Piercing, true /* hasSpecialDetails */),
    SM('a drop of water or a piece of ice'),
    'Conjuration'
  ),

  JUMP: new Spell(
    'Jump',
    1,
    PointType.SpellSlot,
    ["You touch a creature. The creature's jump distance is tripled until the spell ends."],
    Utilities.NonCombat,
    Times.Action,
    Durations.Minutes(1),
    Ranges.Touch,
    AreaOfEffects.SingleTarget,
    ToHits.AlwaysWorks,
    Effects.Buff(),
    VSM("a grasshopper's hind leg"),
    'Transmutation'
  ),

  // LONGSTRIDER: Included above

  // PURIFY_FOOD_AND_DRINK: Included above

  // SPEAK_WITH_ANIMALS: Included above

  // THUNDERWAVE: Included above

  // -------------------
  // Druid - 2
  // new Spell('SPELL', 2, PointType.SpellSlot, [
  //         'DESCRIPTION',
  //     ], [Utilities.Combat, Utilities.NonCombat],
  //     Times.%, Durations.%, Ranges.%, AreaOfEffects.%,
  //     ToHits.%, Effects.%,
  //     REQS, 'SCHOOL')
  // ANIMAL_MESSENGER: Included above

  BARKSKIN: new Spell(
    'Barkskin',
    2,
    PointType.SpellSlot,
    [
      "You touch a willing creature. Until the spell ends, the target's skin has a rough, bark-like appearance, and the target's AC can't be less than 16, regardless of what kind of armor it is wearing.",
    ],
    [Utilities.Combat],
    Times.Action,
    Durations.Hours(1),
    Ranges.Touch,
    AreaOfEffects.SingleTarget,
    ToHits.AlwaysWorks,
    Effects.Buff(true),
    VSMC('a handful of oak bark'),
    'Transmutation'
  ),

  BEAST_SENSE: null, // SCR

  DARKVISION: new Spell(
    'Darkvision',
    2,
    PointType.SpellSlot,
    [
      'You touch a willing creature to grant it the ability to see in the dark. For the duration, that creature has darkvision out to a range of 60 feet.',
    ],
    Utilities.NonCombat,
    Times.Action,
    Durations.Hours(8),
    Ranges.Touch,
    AreaOfEffects.SingleTarget,
    ToHits.AlwaysWorks,
    Effects.Buff(true),
    VSM('either a pinch of dried carrot or an agate'),
    'Transmutation'
  ),

  DUST_DEVIL: null, // VSMC

  EARTHBIND: null, // VC

  // ENHANCE_ABILITY: Included above

  // FIND_TRAPS: Included above

  FLAME_BLADE: null, // VSMC

  FLAMING_SPHERE: null, // VSMC

  GUST_OF_WIND: new Spell(
    'Gust of Wind',
    2,
    PointType.SpellSlot,
    [
      "A line of strong wind 60 feet long and 10 feet wide blasts from you in a direction you choose for the spell's duration. Each creature that starts its turn in the line must succeed on a Strength saving throw or be pushed 15 feet away from you in a direction following the line.",
      'Any creature in the line must spend 2 feet of movement for every 1 foot it moves when moving closer to you.',
      'The gust disperses gas or vapor, and it extinguishes candles, torches, and similar unprotected flames in the area. It causes protected flames, such as those of lanterns, to dance wildly and has a 50 percent chance to extinguish them.',
      'As a bonus action on each of your turns before the spell ends, you can change the direction in which the line blasts from you.',
    ],
    [Utilities.Combat],
    Times.Action,
    Durations.Minutes(1),
    Ranges.Self,
    AreaOfEffects.Line(60, 10),
    ToHits.SpellSave(AbilityType.STR),
    Effects.Control(true),
    VSMC('a legume seed'),
    'Evocation'
  ),

  // HEAT_METAL: Included above

  // HOLD_PERSON: Included above

  // LESSER_RESTORATION: Included above

  // LOCATE_ANIMALS_OR_PLANTS: Included above

  // LOCATE_OBJECT: Included above

  MOONBEAM: null, // VSMC

  PASS_WITHOUT_TRACE: new Spell(
    'Pass without Trace',
    2,
    PointType.SpellSlot,
    [
      "A veil of shadows and silence radiates from you, masking you and your companions from detection. For the duration, each creature you choose within 30 feet of you (including you) has a +10 bonus to Dexterity (Stealth) checks and can't be tracked except by magical means. A creature that receives this bonus leaves behind no tracks or other traces of its passage.",
    ],
    [Utilities.NonCombat],
    Times.Action,
    Durations.Hours(1),
    Ranges.Self,
    AreaOfEffects.Radius(30),
    ToHits.AlwaysWorks,
    Effects.Buff(true),
    VSMC('ashes froma burned leaf of mistletoe and a sprige of spruce'),
    'Abjuration'
  ),

  // PROTECTION_FROM_POISON: Included above

  // SKYWRITE: Included above

  SPIKE_GROWTH: new Spell(
    'Spike Growth',
    2,
    PointType.SpellSlot,
    [
      'The ground in a 20-foot radius centered on a point within range twists and sprouts hard spikes and thorns. The area becomes difficult terrain for the duration. When a creature moves into or within the area, it takes 2d4 piercing damage for every 5 feet it travels.',
      "The transformation of the ground is camouflaged to look natural. Any creature that can't see the area at the time the spell is cast must make a Wisdom (Perception) check against your spell save DC to recognize the terrain as hazardous before entering it.",
    ],
    [Utilities.Combat],
    Times.Action,
    Durations.Minutes(10),
    Ranges.Range(150),
    AreaOfEffects.Radius(20),
    ToHits.AlwaysWorks,
    Effects.Control(true),
    VSMC('seven sharp thorns or seven small twigs, each sharpened to a point'),
    'Transmutation'
  ),

  // WARDING_WIND: Included above

  // -------------------
  // Druid - 3
  // new Spell('SPELL', 3, PointType.SpellSlot, [
  //         'DESCRIPTION',
  //     ], [Utilities.Combat, Utilities.NonCombat],
  //     Times.%, Durations.%, Ranges.%, AreaOfEffects.%,
  //     ToHits.%, Effects.%,
  //     REQS, 'SCHOOL')
  // -------------------
  // Druid - 4
  // -------------------
  // Druid - 5
  // -------------------
  // Druid - 6
  // -------------------
  // Druid - 7
  // -------------------
  // Druid - 8
  // -------------------
  // Druid - 9

  // -------------------
  // Paladin - 1
  // new Spell('SPELL', 1, PointType.SpellSlot, [
  //         'DESCRIPTION',
  //     ], [Utilities.Combat, Utilities.NonCombat],
  //     Times.%, Durations.%, Ranges.%, AreaOfEffects.%,
  //     ToHits.%, Effects.%,
  //     REQS, 'SCHOOL')
  // -------------------
  // Paladin - 2
  // new Spell('SPELL', 2, PointType.SpellSlot, [
  //         'DESCRIPTION',
  //     ], [Utilities.Combat, Utilities.NonCombat],
  //     Times.%, Durations.%, Ranges.%, AreaOfEffects.%,
  //     ToHits.%, Effects.%,
  //     REQS, 'SCHOOL')
  // -------------------
  // Paladin - 3
  // new Spell('SPELL', 3, PointType.SpellSlot, [
  //         'DESCRIPTION',
  //     ], [Utilities.Combat, Utilities.NonCombat],
  //     Times.%, Durations.%, Ranges.%, AreaOfEffects.%,
  //     ToHits.%, Effects.%,
  //     REQS, 'SCHOOL')
  // -------------------
  // Paladin - 4
  // -------------------
  // Paladin - 5

  // -------------------
  // Ranger - Feats
  PRIMEVAL_AWARENESS: new Spell(
    'Primeval Awareness',
    1,
    PointType.SpellSlot,
    [
      'You can use your action and expend one ranger spell slot to focus your awareness on the region around you. For 1 minute per level of the spell slot you expend, you can sense whether the following types of creatures are present within 1 mile of you (or within up to 6 miles if you are in your favored terrain): aberrations, celestials, dragons, elementals, fey, fiends, and undead. This feature doesn’t reveal the creatures’ location or number.',
    ],
    Utilities.NonCombat,
    Times.Action,
    Durations.Minutes(1),
    Ranges.Self,
    AreaOfEffects.RadiusMiles(1),
    ToHits.AlwaysWorks,
    Effects.Detect(true),
    null /* reqs */,
    null /* school */
  ),

  // -------------------
  // Ranger - 1
  // new Spell('SPELL', 1, PointType.SpellSlot, [
  //         'DESCRIPTION',
  //     ], [Utilities.Combat, Utilities.NonCombat],
  //     Times.%, Durations.%, Ranges.%, AreaOfEffects.%,
  //     ToHits.%, Effects.%,
  //     REQS, 'SCHOOL')
  // -------------------
  // Ranger - 2
  // new Spell('SPELL', 2, PointType.SpellSlot, [
  //         'DESCRIPTION',
  //     ], [Utilities.Combat, Utilities.NonCombat],
  //     Times.%, Durations.%, Ranges.%, AreaOfEffects.%,
  //     ToHits.%, Effects.%,
  //     REQS, 'SCHOOL')
  // -------------------
  // Ranger - 3
  // new Spell('SPELL', 3, PointType.SpellSlot, [
  //         'DESCRIPTION',
  //     ], [Utilities.Combat, Utilities.NonCombat],
  //     Times.%, Durations.%, Ranges.%, AreaOfEffects.%,
  //     ToHits.%, Effects.%,
  //     REQS, 'SCHOOL')
  // -------------------
  // Ranger - 4
  // -------------------
  // Ranger - 5

  // -------------------
  // Sorcerer - Cantrips
  // new Spell('SPELL', 0, PointType.SpellSlot, [
  //         'DESCRIPTION',
  //     ], [Utilities.Combat, Utilities.NonCombat],
  //     Times.%, Durations.%, Ranges.%, AreaOfEffects.%,
  //     ToHits.%, Effects.%,
  //     REQS, 'SCHOOL')
  // -------------------
  // Sorcerer - 1
  // new Spell('SPELL', 1, PointType.SpellSlot, [
  //         'DESCRIPTION',
  //     ], [Utilities.Combat, Utilities.NonCombat],
  //     Times.%, Durations.%, Ranges.%, AreaOfEffects.%,
  //     ToHits.%, Effects.%,
  //     REQS, 'SCHOOL')
  // -------------------
  // Sorcerer - 2
  // new Spell('SPELL', 2, PointType.SpellSlot, [
  //         'DESCRIPTION',
  //     ], [Utilities.Combat, Utilities.NonCombat],
  //     Times.%, Durations.%, Ranges.%, AreaOfEffects.%,
  //     ToHits.%, Effects.%,
  //     REQS, 'SCHOOL')
  // -------------------
  // Sorcerer - 3
  // new Spell('SPELL', 3, PointType.SpellSlot, [
  //         'DESCRIPTION',
  //     ], [Utilities.Combat, Utilities.NonCombat],
  //     Times.%, Durations.%, Ranges.%, AreaOfEffects.%,
  //     ToHits.%, Effects.%,
  //     REQS, 'SCHOOL')
  // -------------------
  // Sorcerer - 4
  // -------------------
  // Sorcerer - 5
  // -------------------
  // Sorcerer - 6
  // -------------------
  // Sorcerer - 7
  // -------------------
  // Sorcerer - 8
  // -------------------
  // Sorcerer - 9

  // -------------------
  // Warlock - Cantrips
  // new Spell('SPELL', 0, PointType.SpellSlot, [
  //         'DESCRIPTION',
  //     ], [Utilities.Combat, Utilities.NonCombat],
  //     Times.%, Durations.%, Ranges.%, AreaOfEffects.%,
  //     ToHits.%, Effects.%,
  //     REQS, 'SCHOOL')
  ELDRITCH_BLAST: new Spell(
    'Eldritch Blast',
    0,
    PointType.SpellSlot,
    [
      'A beam of crackling energy streaks toward a creature within range. Make a ranged spell attack against the target. On a hit, the target takes 1d10 force damage.',
      'The spell creates more than one beam when you reach higher levels: two beams at 5th level, three beams at 11th level, and four beams at 17th level. You can direct the beams at the same target or at different ones. Make a separate attack roll for each beam.',
    ],
    [Utilities.Combat],
    Times.Action,
    Durations.Instantaneous,
    Ranges.Range(120),
    AreaOfEffects.SingleTarget,
    ToHits.SpellAttackRoll(true),
    Effects.SpellDamage(Roll.d(10), DamageTypes.Force),
    VS,
    'Evocation'
  ),
  // -------------------
  // Warlock - 1
  // new Spell('SPELL', 1, PointType.SpellSlot, [
  //         'DESCRIPTION',
  //     ], [Utilities.Combat, Utilities.NonCombat],
  //     Times.%, Durations.%, Ranges.%, AreaOfEffects.%,
  //     ToHits.%, Effects.%,
  //     REQS, 'SCHOOL')
  // -------------------
  // Warlock - 2
  // new Spell('SPELL', 2, PointType.SpellSlot, [
  //         'DESCRIPTION',
  //     ], [Utilities.Combat, Utilities.NonCombat],
  //     Times.%, Durations.%, Ranges.%, AreaOfEffects.%,
  //     ToHits.%, Effects.%,
  //     REQS, 'SCHOOL')
  // -------------------
  // Warlock - 3
  // new Spell('SPELL', 3, PointType.SpellSlot, [
  //         'DESCRIPTION',
  //     ], [Utilities.Combat, Utilities.NonCombat],
  //     Times.%, Durations.%, Ranges.%, AreaOfEffects.%,
  //     ToHits.%, Effects.%,
  //     REQS, 'SCHOOL')
  // -------------------
  // Warlock - 4
  // -------------------
  // Warlock - 5
  // -------------------
  // Warlock - 6
  // -------------------
  // Warlock - 7
  // -------------------
  // Warlock - 8
  // -------------------
  // Warlock - 9

  // -------------------
  // Wizard - Cantrips
  // new Spell('SPELL', 0, PointType.SpellSlot, [
  //         'DESCRIPTION',
  //     ], [Utilities.Combat, Utilities.NonCombat],
  //     Times.%, Durations.%, Ranges.%, AreaOfEffects.%,
  //     ToHits.%, Effects.%,
  //     REQS, 'SCHOOL')
  // -------------------
  // Wizard - 1
  // new Spell('SPELL', 1, PointType.SpellSlot, [
  //         'DESCRIPTION',
  //     ], [Utilities.Combat, Utilities.NonCombat],
  //     Times.%, Durations.%, Ranges.%, AreaOfEffects.%,
  //     ToHits.%, Effects.%,
  //     REQS, 'SCHOOL')
  // TASHAS_HIDEOUS_LAUGHTER: See "HIDEOUS_LAUGHTER"
  // -------------------
  // Wizard - 2
  // new Spell('SPELL', 2, PointType.SpellSlot, [
  //         'DESCRIPTION',
  //     ], [Utilities.Combat, Utilities.NonCombat],
  //     Times.%, Durations.%, Ranges.%, AreaOfEffects.%,
  //     ToHits.%, Effects.%,
  //     REQS, 'SCHOOL')
  // -------------------
  // Wizard - 3
  // new Spell('SPELL', 3, PointType.SpellSlot, [
  //         'DESCRIPTION',
  //     ], [Utilities.Combat, Utilities.NonCombat],
  //     Times.%, Durations.%, Ranges.%, AreaOfEffects.%,
  //     ToHits.%, Effects.%,
  //     REQS, 'SCHOOL')
  // -------------------
  // Wizard - 4
  // -------------------
  // Wizard - 5
  // -------------------
  // Wizard - 6
  // -------------------
  // Wizard - 7
  // -------------------
  // Wizard - 8
  // -------------------
  // Wizard - 9

  // Ki
  FLURRY_OF_BLOWS: new Spell(
    'Flurry of Blows',
    1,
    PointType.Ki,
    [
      'Immediately after you take the Attack action on your turn, you can spend 1 ki point to make two unarmed strikes as a bonus action.',
    ],
    Utilities.Combat,
    Times.Bonus,
    Durations.Instantaneous,
    Ranges.Reach(5),
    AreaOfEffects.SingleTarget,
    ToHits.UnarmedStrikeAttackRoll(true),
    Effects.UnarmedStrikeDamage(true),
    null /* reqs */,
    null /* school */
  ),
  PATIENT_DEFENSE: new Spell(
    'Patient Defense',
    1,
    PointType.Ki,
    ['You can spend 1 ki point to take the Dodge action as a bonus action on your turn.'],
    Utilities.Combat,
    Times.Bonus,
    Durations.Instantaneous,
    Ranges.Self,
    AreaOfEffects.SingleTarget,
    ToHits.AlwaysWorks,
    Effects.Movement(true),
    null /* reqs */,
    null /* school */
  ),
  STEP_OF_THE_WIND: new Spell(
    'Step of the Wind',
    1,
    PointType.Ki,
    [
      'You can spend 1 ki point to take the Disengage or Dash action as a bonus action on your turn, and your jump distance is doubled for the turn.',
    ],
    Utilities.Combat,
    Times.Bonus,
    Durations.Instantaneous,
    Ranges.Self,
    AreaOfEffects.SingleTarget,
    ToHits.AlwaysWorks,
    Effects.Movement(true),
    null /* reqs */,
    null /* school */
  ),

  KI_DARKNESS: new Spell(
    'Darkness',
    2,
    PointType.Ki,
    [
      "Magical darkness spreads from a point you choose within range to fill a 15-foot-radius sphere for the duration. The darkness spreads around corners. A creature with darkvision can't see through this darkness, and nonmagical light can't illuminate it.",
      "If the point you choose is on an object you are holding or one that isn't being worn or carried, the darkness emanates from the object and moves with it. Completely covering the source of the darkness with an opaque object, such as a bowl or a helm, blocks the darkness.",
      "If any of this spell's area overlaps with an area of light created by a spell of 2nd level or lower, the spell that created the light is dispelled.",
    ],
    [Utilities.Combat, Utilities.NonCombat],
    Times.Action,
    Durations.Minutes(10),
    Ranges.Range(60),
    AreaOfEffects.Radius(15),
    ToHits.AlwaysWorks,
    Effects.Control(true),
    VC,
    'Evocation'
  ),

  KI_DARKVISION: new Spell(
    'Darkvision',
    2,
    PointType.Ki,
    [
      'You touch a willing creature to grant it the ability to see in the dark. For the duration, that creature has darkvision out to a range of 60 feet.',
    ],
    Utilities.NonCombat,
    Times.Action,
    Durations.Hours(8),
    Ranges.Touch,
    AreaOfEffects.SingleTarget,
    ToHits.AlwaysWorks,
    Effects.Buff(true),
    VS,
    'Transmutation'
  ),

  KI_PASS_WITHOUT_TRACE: new Spell(
    'Pass without Trace',
    2,
    PointType.Ki,
    [
      "A veil of shadows and silence radiates from you, masking you and your companions from detection. For the duration, each creature you choose within 30 feet of you (including you) has a +10 bonus to Dexterity (Stealth) checks and can't be tracked except by magical means. A creature that receives this bonus leaves behind no tracks or other traces of its passage.",
    ],
    [Utilities.NonCombat],
    Times.Action,
    Durations.Hours(1),
    Ranges.Self,
    AreaOfEffects.Radius(30),
    ToHits.AlwaysWorks,
    Effects.Buff(true),
    VSC,
    'Abjuration'
  ),

  KI_SILENCE: new Spell(
    'Silence',
    2,
    PointType.Ki,
    [
      'For the duration, no sound can be created within or pass through a 20-foot-radius sphere centered on a point you choose within range. Any creature or object entirely inside the sphere is immune to thunder damage, and creatures are deafened while entirely inside it. Casting a spell that includes a verbal component is impossible there.',
    ],
    [Utilities.Combat, Utilities.NonCombat],
    Times.Action,
    Durations.Minutes(10),
    Ranges.Range(120),
    AreaOfEffects.Radius(20),
    ToHits.AlwaysWorks,
    Effects.Control(true),
    VSCR,
    'Illusion'
  ),

  // Patient Defense
  // You can spend 1 ki point to take the Dodge action as a bonus action on your turn.

  // Step of the Wind
  // You can spend 1 ki point to take the Disengage or Dash action as a bonus action on your turn, and your jump distance is doubled for the turn.
};

export default Spells;
