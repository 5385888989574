import React from 'react';

import Modifier from './Modifier.js';
import Value from './Value.js';
import Roll from './Roll.js';

function Actions(props) {
  const character = props.character;
  const includeCombat = props.includeCombat;
  const includeNonCombat = props.includeNonCombat;
  const includeRest = props.includeRest;
  const includeDetails = props.includeDetails;
  var actionsList = [];
  character.actions
    .sort((a, b) => {
      if (a.sortScore === b.sortScore) {
        if (a.name < b.name) {
          return -1;
        } else if (a.name > b.name) {
          return 1;
        } else {
          return 0;
        }
      } else {
        return a.sortScore - b.sortScore;
      }
    })
    .forEach((action) => {
      if (
        (includeCombat && action.isCombat) ||
        (includeNonCombat && action.isNonCombat) ||
        (includeRest && action.isRest)
      ) {
        actionsList.push(
          <Action
            key={action.name}
            action={action}
            character={character}
            includeDetails={includeDetails}
          />
        );
      }
    });
  return (
    <div className="Actions">
      <table className="ActionsTable">{actionsList}</table>
    </div>
  );
}

function Action(props) {
  const character = props.character;
  const action = props.action;
  const includeDetails = props.includeDetails;
  return (
    <tbody className="Action">
      <tr>
        <td className="NameAndType">
          <span className="Name">
            <Value value={action.name} />
          </span>
          <span className="secondary Type">
            <Value value={action.type.name} />
          </span>
        </td>
        <td className="Requires">
          <span className="Time">
            <Value value={action.time.calculatedShortName(character)} />
          </span>
          <span className="Components">
            <Value value={action.requiresShort} />
          </span>
        </td>
        <td className="RangeAndArea">
          <span className="Range">
            <Value value={action.range.shortName} />
          </span>
          {action.areaOfEffect && (
            <span className="AreaOfEffect">
              <Value value={action.areaOfEffect.shortName} />
            </span>
          )}
          {action.isNonInstantaneous && (
            <span className="Duration">
              {includeDetails && <Value value={action.duration.calculatedLongName(character)} />}
              {!includeDetails && <Value value={action.duration.calculatedShortName(character)} />}
            </span>
          )}
        </td>
        <td className="ToHit">
          {action.toHit && action.toHit.hasAttackRoll && (
            <span className="Attack">
              <Modifier value={action.toHit.attackRollBonus(character)} />
            </span>
          )}
          {action.toHit && action.toHit.hasSaveDC && (
            <span className="Save">
              <span className="label">{action.toHit.saveAbilityOrSkill.abbreviation} DC</span>
              <Value value={action.toHit.saveDC(character)} />
            </span>
          )}
          {action.toHit && action.toHit.hasSpecialDetails && (
            <span className="Special">{action.toHit.specialDetails}</span>
          )}
        </td>
        <td className="Effect">
          {action.effect && action.effect.hasDamage && (
            <span className="Damage">
              <span className="label">{action.effect.damageType.name}</span>
              <Roll roll={action.effect.damageRoll(character)} />
            </span>
          )}
          {action.effect && action.effect.hasSpecialDetails && (
            <span className="Special">{action.effect.specialDetails}</span>
          )}
        </td>
        {includeDetails && <td className="Spacer">&nbsp;</td>}
      </tr>
      {includeDetails && (
        <tr>
          <td className="Description" colSpan="6">
            {action.description}
          </td>
        </tr>
      )}
    </tbody>
  );
}

export default Actions;
