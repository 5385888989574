class Ability {
  constructor(type, score) {
    this._type = type;
    this._score = score;
  }

  get type() {
    return this._type;
  }

  get baseScore() {
    return this._score;
  }

  static modifier(score) {
    return Math.floor((score - 10) / 2);
  }
}

export default Ability;
