import assert from '../Common/Assert.js';
import Item from './Item.js';

class CarriedItem {
  constructor(item, opt_isActive, opt_isAttuned) {
    assert(item != null && item instanceof Item, 'CarriedItem given a non-item');
    this._item = item;
    this._isActive = !!opt_isActive;
    this._isAttuned = !!opt_isAttuned;
  }

  get item() {
    return this._item;
  }

  get isActive() {
    return this._isActive;
  }

  get isAttuned() {
    return this._isAttuned;
  }
}

export default CarriedItem;
