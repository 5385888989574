import Action from '../../Action.js';
import AreaOfEffects from '../../AreaOfEffects.js';
import Durations from '../../Durations.js';
import Effects from '../../Effects.js';
import Ranges from '../../Ranges.js';
import Sort from '../../Sort.js';
import Times from '../../Times.js';
import ToHits from '../../ToHits.js';
import Types from '../../Types.js';
import Utilities from '../../Utilities.js';

export default new Action(
  'Wild Shape',
  Types.Spell('1 Wild Shape Use'),
  Sort.Power,
  ['You transform into a beast. See the Wild Shape Feat for details.'],
  [Utilities.Combat, Utilities.NonCombat],
  Times.Action,
  Durations.HoursPerLevel(0.5),
  Ranges.Self,
  AreaOfEffects.SingleTarget,
  ToHits.AlwaysWorks,
  Effects.Buff(true),
  null /* requires */
);
