import StaticEffect from '../StaticEffect.js';

import { rollTypeToModifier } from './AttackRollType.js';

const ACTION_TO_HIT_HAS_MORE = ' (...)';

export default class ToHit {
  constructor(attackRollType, attackRollBonus, proficiency, saveType, hasSpecialDetails) {
    this._attackRollType = attackRollType;
    this._attackRollBonus = attackRollBonus;
    this._proficiency = proficiency;
    this._saveType = saveType;
    this._hasSpecialDetails = hasSpecialDetails;
  }

  get hasAttackRoll() {
    return !!this._attackRollType;
  }

  attackRollBonus(character) {
    var attackRollBonus = this._attackRollBonus || 0;
    if (this._proficiency && character.hasProficiency(this._proficiency)) {
      attackRollBonus += character.proficiencyBonus;
    }
    attackRollBonus += rollTypeToModifier(this._attackRollType, character, false /* isDamage */);
    attackRollBonus += StaticEffect.attackModifiers(character, this._attackRollType);
    return attackRollBonus;
  }

  get hasSaveDC() {
    return !!this._saveType;
  }

  saveDC(character) {
    return this._saveType.saveDCValue(character);
  }

  get saveAbilityOrSkill() {
    return (this._saveType && this._saveType.saveAbilityOrSkill) || null;
  }

  get hasSpecialDetails() {
    return this._hasSpecialDetails;
  }

  get specialDetails() {
    return ACTION_TO_HIT_HAS_MORE;
  }
}
