import React from 'react';
import AbilityType from '../Character/AbilityType.js';
import Modifier from './Modifier.js';
import Value from './Value.js';

function Abilities(props) {
  const character = props.character;
  const abilities = AbilityType.ALL.map((abilityType) => (
    <Ability key={abilityType.name} type={abilityType} character={character} />
  ));

  return (
    <div className="section Abilities">
      <div className="title">Abilities &amp; Skills</div>
      <div className="Proficiency">
        <Modifier value={character.proficiencyBonus} />
      </div>
      {abilities}
    </div>
  );
}

function Ability(props) {
  const type = props.type;
  const character = props.character;
  const skills = type.skillTypes.map((skillType) => (
    <Skill key={skillType.name} type={skillType} character={character} />
  ));
  return (
    <table className="AbilityTable">
      <AbilityDetailRow
        name={type.name}
        type="AbilityName"
        value={character.abilityScore(type)}
        secondary=""
      />
      <tbody className="ModifierAndSave">
        <tr className="Detail">
          <td colSpan="2">
            <span className="label">Modifier:</span>&nbsp;
            <Modifier value={character.abilityModifier(type)} />
            &nbsp; &nbsp;
            <span className="label">Saving:</span>&nbsp;
            <Modifier value={character.abilitySavingThrowModifier(type)} />
          </td>
        </tr>
      </tbody>
      {skills}
    </table>
  );
}

function AbilityDetailRow(props) {
  const name = props.name;
  const type = props.type;
  const value = props.value ? <Value value={props.value} /> : <Modifier value={props.modifier} />;
  const highlight = type === 'AbilityName';
  const labelClassName = 'label Name' + (highlight ? ' highlight' : '');
  const secondary = props.secondary;
  return (
    <tbody className={type}>
      <tr className="Detail">
        <td className={labelClassName}>{name}:&nbsp;</td>
        <td className="Modifier">
          {value}
          {secondary}
        </td>
      </tr>
    </tbody>
  );
}
function Skill(props) {
  const type = props.type;
  const character = props.character;
  const modifier = character.skillModifier(type);
  const passive = '';
  /*
  (type === SkillType.PERCEPTION ? (
    <span className="secondary SkillSecondary">
      <span className="label">Passive: </span><Value value={10+modifier}/>
      </span>) : '');
   */
  return <AbilityDetailRow name={type.name} type="Skill" modifier={modifier} secondary={passive} />;
}

export default Abilities;
