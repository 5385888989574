import ActionRange from './Range.js';

const Range = function(short, long) {
  if (long) {
    return new ActionRange(short + ' / ' + long + ' ft');
  } else {
    return new ActionRange(short + ' ft');
  }
};

export default {
  Self: new ActionRange('Self'),
  Touch: new ActionRange('Touch'),
  Sight: new ActionRange('Sight'),
  Reach: function(reach) {
    return Range(reach);
  },
  Range,
};
