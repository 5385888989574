import CharacterEnhancement, { EnhancementTypes } from './CharacterEnhancement.js';

class Language extends CharacterEnhancement {
  constructor(name, description) {
    super(EnhancementTypes.Language);
    this._name = name;
    this._description = description;
  }
  get name() {
    return this._name;
  }

  get description() {
    return this._description;
  }
}

export default Language;
