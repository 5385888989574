import CharacterEnhancement, { EnhancementTypes } from './CharacterEnhancement.js';

class Proficiency extends CharacterEnhancement {
  constructor(name) {
    super(EnhancementTypes.Proficiency);
    this._name = name;
  }
  get name() {
    return this._name;
  }
}

export default Proficiency;
