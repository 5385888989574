import AbilityType from '../Character/AbilityType.js';
import CarriedItem from '../Character/CarriedItem.js';
import Character from '../Character/Character.js';
import Classes from '../Character/Classes.js';
import Feats from '../Character/Feats.js';
import Items from '../Character/Items.js';
import Proficiencies from '../Character/Proficiencies.js';
import SkillType from '../Character/SkillType.js';
import Spells from '../Character/Spells/Spells.js';

import KimPortrait from '../Portraits/KimPortrait.png';

import { LANGUAGES, RACE, LAST_NAME, WA_PREFIX, WA_SUFFIX, LEVEL } from './Common.js';

// prettier-ignore
const kim = new Character('kim',
    'Kym' + LAST_NAME, KimPortrait,
    20, '5\' 2"', '125 lbs', // AGE HEIGHT WEIGHT
    'Black, Long, Braided', 'Teal', 'Tan', 'Black cat (Patty) tattoo on inner left wrist; Tree tattoo on right shoulder', //	HAIR EYES SKIN MARKS
    WA_PREFIX + 'kym' + WA_SUFFIX,
    [
        'Settles for nothing less than perfection.',
        'Generally willing to go out of her way to assist others.',
        'Tradition and honesty are important to her.',
        'Nobody stays angry at her or around her for long, since she can defuse any amount of tension.',
        'Her horn is my most treasured possession - it reminds her of Patty.',
        'Has trouble keeping her true feelings hidden. Her sharp tongue lands her in trouble.',
        'Took care of Patty (black cat family pet) before she died.',
        'Takes care of Duke (Patty\'s child).',
        'Many animals find her horn soothing.',
        'The tattoo of Patty glows with arcane energy when casting spells with material cost (returning to normal after casting is complete).',
        'Her outfits include cargo pants and at least one hooded top.',
    ], LANGUAGES,
    RACE, Classes.CIRCLE_OF_THE_SHEPHERD, LEVEL,
    12, 13, 12, 13, 14, 10, // STR DEX CON INT WIS CHA
    [
        // Background Feat
        Feats.HOMEBREW_FRIENDLYFACE,

        // Half Elf choices
        Feats.ABILITY_SCORE_INCREASE(AbilityType.DEX, 1),
        Feats.ABILITY_SCORE_INCREASE(AbilityType.INT, 1),

        // Replace Half Elf CHA+2 with WIS+2
        Feats.ABILITY_SCORE_INCREASE(AbilityType.WIS, 2),
        Feats.ABILITY_SCORE_INCREASE(AbilityType.CHA, -2),

        // Other Feats
    ], [
        // Half Elf choices
        SkillType.ARCANA,
        SkillType.NATURE,

        // Druid Choices
        SkillType.SURVIVAL,
        SkillType.PERCEPTION,

        // Background Choices
        SkillType.ANIMAL_HANDLING,
        SkillType.PERFORMANCE,
    ], [
        // Background Choices
        Proficiencies.DisguiseKit,
        Proficiencies.Horn,
    ], [
        // Prepared Spells
        Spells.DRUIDCRAFT,
        Spells.THORN_WHIP,
        Spells.ANIMAL_FRIENDSHIP,
        Spells.CURE_WOUNDS,
        Spells.ENTANGLE,
        Spells.GOODBERRY,

        // Level 2 spell choice
        Spells.BEAST_BOND,
        //Spells.FAERIE_FIRE,
        //Spells.HEALING_WORD,
        //Spells.SPEAK_WITH_ANIMALS,
    ], [
        Items.Backpack,
        Items.Bedroll,
        Items.CatTreats,
        Items.ClothesCommon(5),
        Items.ClothesFine(1),
        Items.Costume(1),
        new CarriedItem(Items.DruidicFocus('Patty Tattoo'), true /* isActive */ ),
        new CarriedItem(Items.Gambeson(), true /* isActive */ ),
        Items.Horn,
        Items.MessKit,
        Items.Pouch,
        Items.Rations(5),
        Items.Rope50(1),
        new CarriedItem(Items.Scimitar(), true /* isActive */ ),
        Items.SeedPouch,
        new CarriedItem(Items.Sling(), true /* isActive */ ),
        new CarriedItem(Items.SlingBullet(20), true /* isActive */ ),
        Items.Tinderbox,
        Items.Torch(2),
        Items.Waterskin,
    ]);
kim.expertiseSkills(SkillType.ANIMAL_HANDLING);
export default kim;
