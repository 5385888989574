import Dice from './Dice.js';

class PickRoll {
  constructor(roll1, roll2) {
    this.roll1 = roll1;
    this.roll2 = roll2;
  }
  get name() {
    return this.roll1.name + ' or ' + this.roll2.name;
  }
}

class Roll {
  constructor(...dice) {
    if (Array.isArray(dice[0])) {
      dice = dice[0];
    }
    this._dice = [].concat(dice);
  }

  get dice() {
    return this._dice;
  }

  get name() {
    var name = '';
    this._dice.forEach((die) => {
      if (typeof die === 'number') {
        if (die > 0) {
          if (name !== '') {
            name += ' + ';
          }
          name += die;
        } else if (die < 0) {
          if (name !== '') {
            name += ' - ';
          } else {
            name += '-';
          }
          name += -die;
        }
      } else if (typeof die === 'string') {
        if (name !== '') {
          name += ' + ';
        }
        name += die;
      } else {
        if (name !== '') {
          name += ' + ';
        }
        name += die.name;
      }
    });
    if (name === '') {
      return '0';
    } else {
      return name;
    }
  }

  static combine(...rolls) {
    var flatBonusTotal = 0;
    var strValues = [];
    var diceSides = [];
    var diceCountBySides = {};
    rolls.forEach((roll) => {
      if (roll) {
        roll.dice.forEach((die) => {
          if (typeof die === 'number') {
            flatBonusTotal += die;
          } else if (typeof die === 'string') {
            strValues.push(die);
          } else if (die instanceof Dice) {
            if (!diceCountBySides[die.sides]) {
              diceCountBySides[die.sides] = 0;
              diceSides.push(die.sides);
            }
            diceCountBySides[die.sides] += die.count;
          } else {
          }
        });
      }
    });
    diceSides.sort();
    var dice = [];
    diceSides.forEach((sides) => {
      dice.push(new Dice(diceCountBySides[sides], sides));
    });
    if (flatBonusTotal !== 0) {
      dice.push(flatBonusTotal);
    }
    dice = dice.concat(strValues);
    return new Roll(dice);
  }

  static pick(roll1, roll2) {
    return new PickRoll(roll1, roll2);
  }
  static constant(value) {
    return new Roll(value);
  }

  static d(sidesCount) {
    return new Roll(Dice.d(sidesCount));
  }

  static nd(diceCount, sidesCount) {
    return new Roll(new Dice(diceCount, sidesCount));
  }
}

export default Roll;
