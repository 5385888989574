import Effect, { ACTION_EFFECT_HAS_MORE } from './Effect.js';
import { AttackRollTypes } from './AttackRollType.js';

import Roll from '../Roll.js';
import { DamageTypes } from '../DamageType.js';

const SpellDamageWithSpellModifier = function(
  damageRoll,
  damageType,
  opt_hasSpecialDetails,
  opt_bonusByCasterLevel
) {
  return new Effect(
    AttackRollTypes.SpellAttackAndDamage,
    damageRoll,
    damageType,
    !!opt_hasSpecialDetails,
    opt_bonusByCasterLevel
  );
};

const SpellDamage = function(
  damageRoll,
  damageType,
  opt_hasSpecialDetails,
  opt_bonusByCasterLevel
) {
  return new Effect(
    AttackRollTypes.Spell,
    damageRoll,
    damageType,
    !!opt_hasSpecialDetails,
    opt_bonusByCasterLevel
  );
};

export default {
  UnarmedStrikeDamage: function(opt_hasSpecialDetails) {
    return new Effect(
      AttackRollTypes.UnarmedStrike,
      Roll.constant(1),
      DamageTypes.Bludgeoning,
      !!opt_hasSpecialDetails,
      null
    );
  },
  MeleeDamage: function(damageRoll, damageType, opt_hasSpecialDetails) {
    return new Effect(
      AttackRollTypes.Melee,
      damageRoll,
      damageType,
      !!opt_hasSpecialDetails,
      null
    );
  },
  MeleeDexDamage: function(damageRoll, damageType, opt_hasSpecialDetails) {
    return new Effect(
      AttackRollTypes.MeleeDex,
      damageRoll,
      damageType,
      !!opt_hasSpecialDetails,
      null
    );
  },
  MeleeOrMartialArtsDamage: function(damageRoll, damageType, opt_hasSpecialDetails) {
    return new Effect(
      AttackRollTypes.MeleeOrMartialArts,
      damageRoll,
      damageType,
      !!opt_hasSpecialDetails,
      null
    );
  },
  FinesseMeleeDamage: function(damageRoll, damageType, opt_hasSpecialDetails) {
    return new Effect(
      AttackRollTypes.Finesse,
      damageRoll,
      damageType,
      !!opt_hasSpecialDetails,
      null
    );
  },
  FinesseOrMartialArtsMeleeDamage: function(damageRoll, damageType, opt_hasSpecialDetails) {
    return new Effect(
      AttackRollTypes.FinesseOrMartialArts,
      damageRoll,
      damageType,
      !!opt_hasSpecialDetails,
      null
    );
  },
  FinesseOffHandDamage: function(damageRoll, damageType, opt_hasSpecialDetails) {
    return new Effect(
      AttackRollTypes.FinesseOffHand,
      damageRoll,
      damageType,
      !!opt_hasSpecialDetails,
      null
    );
  },
  RangedDamage: function(damageRoll, damageType, opt_hasSpecialDetails) {
    return new Effect(
      AttackRollTypes.Ranged,
      damageRoll,
      damageType,
      !!opt_hasSpecialDetails,
      null
    );
  },
  SpellDamageWithSpellModifier,
  SpellDamage,
  SpellDamageByCasterLevel: function(
    damageRoll,
    damageType,
    damageBonusByCasterLevel,
    opt_hasSpecialDetails
  ) {
    return new Effect(
      AttackRollTypes.Spell,
      damageRoll,
      damageType,
      !!opt_hasSpecialDetails,
      damageBonusByCasterLevel
    );
  },
  UnmodifiedDamage: function(damageRoll, damageType, opt_hasSpecialDetails) {
    return new Effect(
      AttackRollTypes.Unmodified,
      damageRoll,
      damageType,
      !!opt_hasSpecialDetails,
      null
    );
  },
  Buff: function(opt_hasSpecialDetails) {
    return new Effect(
      null /* attackRollType */,
      null /* damageRoll */,
      null /* damageType */,
      'Buff' + (opt_hasSpecialDetails ? ACTION_EFFECT_HAS_MORE : ''),
      null /* damageBonusByCasterLevel */
    );
  },
  Blind: function(opt_hasSpecialDetails) {
    return new Effect(
      null /* attackRollType */,
      null /* damageRoll */,
      null /* damageType */,
      'Blind' + (opt_hasSpecialDetails ? ACTION_EFFECT_HAS_MORE : ''),
      null /* damageBonusByCasterLevel */
    );
  },
  Charm: function(opt_hasSpecialDetails) {
    return new Effect(
      null /* attackRollType */,
      null /* damageRoll */,
      null /* damageType */,
      'Charm' + (opt_hasSpecialDetails ? ACTION_EFFECT_HAS_MORE : ''),
      null /* damageBonusByCasterLevel */
    );
  },
  Communicate: function(opt_hasSpecialDetails) {
    return new Effect(
      null /* attackRollType */,
      null /* damageRoll */,
      null /* damageType */,
      'Communicate' + (opt_hasSpecialDetails ? ACTION_EFFECT_HAS_MORE : ''),
      null /* damageBonusByCasterLevel */
    );
  },
  Control: function(opt_hasSpecialDetails) {
    return new Effect(
      null /* attackRollType */,
      null /* damageRoll */,
      null /* damageType */,
      'Control' + (opt_hasSpecialDetails ? ACTION_EFFECT_HAS_MORE : ''),
      null /* damageBonusByCasterLevel */
    );
  },
  Create: function(opt_hasSpecialDetails) {
    return new Effect(
      null /* attackRollType */,
      null /* damageRoll */,
      null /* damageType */,
      'Create' + (opt_hasSpecialDetails ? ACTION_EFFECT_HAS_MORE : ''),
      null /* damageBonusByCasterLevel */
    );
  },
  Cure: function(opt_hasSpecialDetails) {
    return new Effect(
      null /* attackRollType */,
      null /* damageRoll */,
      null /* damageType */,
      'Cure' + (opt_hasSpecialDetails ? ACTION_EFFECT_HAS_MORE : ''),
      null /* damageBonusByCasterLevel */
    );
  },
  Deafen: function(opt_hasSpecialDetails) {
    return new Effect(
      null /* attackRollType */,
      null /* damageRoll */,
      null /* damageType */,
      'Deafen' + (opt_hasSpecialDetails ? ACTION_EFFECT_HAS_MORE : ''),
      null /* damageBonusByCasterLevel */
    );
  },
  Debuff: function(opt_hasSpecialDetails) {
    return new Effect(
      null /* attackRollType */,
      null /* damageRoll */,
      null /* damageType */,
      'Debuff' + (opt_hasSpecialDetails ? ACTION_EFFECT_HAS_MORE : ''),
      null /* damageBonusByCasterLevel */
    );
  },
  Destroy: function(opt_hasSpecialDetails) {
    return new Effect(
      null /* attackRollType */,
      null /* damageRoll */,
      null /* damageType */,
      'Destroy' + (opt_hasSpecialDetails ? ACTION_EFFECT_HAS_MORE : ''),
      null /* damageBonusByCasterLevel */
    );
  },
  Detect: function(opt_hasSpecialDetails) {
    return new Effect(
      null /* attackRollType */,
      null /* damageRoll */,
      null /* damageType */,
      'Detect' + (opt_hasSpecialDetails ? ACTION_EFFECT_HAS_MORE : ''),
      null /* damageBonusByCasterLevel */
    );
  },
  Dismiss: function(opt_hasSpecialDetails) {
    return new Effect(
      null /* attackRollType */,
      null /* damageRoll */,
      null /* damageType */,
      'Dismiss' + (opt_hasSpecialDetails ? ACTION_EFFECT_HAS_MORE : ''),
      null /* damageBonusByCasterLevel */
    );
  },
  Foreknowledge: function(opt_hasSpecialDetails) {
    return new Effect(
      null /* attackRollType */,
      null /* damageRoll */,
      null /* damageType */,
      'Foreknowledge' + (opt_hasSpecialDetails ? ACTION_EFFECT_HAS_MORE : ''),
      null /* damageBonusByCasterLevel */
    );
  },
  Heal: function(healRoll, useSpellModifier, opt_hasSpecialDetails, opt_bonusByCasterLevel) {
    if (useSpellModifier) {
      return SpellDamageWithSpellModifier(
        healRoll,
        DamageTypes.Healing,
        opt_hasSpecialDetails,
        opt_bonusByCasterLevel
      );
    } else {
      return SpellDamage(
        healRoll,
        DamageTypes.Healing,
        opt_hasSpecialDetails,
        opt_bonusByCasterLevel
      );
    }
  },
  Inspire: function() {
    return new Effect(
      AttackRollTypes.Inspiration,
      null /* damageRoll */,
      DamageTypes.Inspiration,
      true /* hasSpecialDetails */,
      null
    );
  },
  Invisibility: function(opt_hasSpecialDetails) {
    return new Effect(
      null /* attackRollType */,
      null /* damageRoll */,
      null /* damageType */,
      'Invisible' + (opt_hasSpecialDetails ? ACTION_EFFECT_HAS_MORE : ''),
      null /* damageBonusByCasterLevel */
    );
  },
  Movement: function(opt_hasSpecialDetails) {
    return new Effect(
      null /* attackRollType */,
      null /* damageRoll */,
      null /* damageType */,
      'Movement' + (opt_hasSpecialDetails ? ACTION_EFFECT_HAS_MORE : ''),
      null /* damageBonusByCasterLevel */
    );
  },
  Paralyze: function(opt_hasSpecialDetails) {
    return new Effect(
      null /* attackRollType */,
      null /* damageRoll */,
      null /* damageType */,
      'Paralyze' + (opt_hasSpecialDetails ? ACTION_EFFECT_HAS_MORE : ''),
      null /* damageBonusByCasterLevel */
    );
  },
  Prone: function(opt_hasSpecialDetails) {
    return new Effect(
      null /* attackRollType */,
      null /* damageRoll */,
      null /* damageType */,
      'Prone' + (opt_hasSpecialDetails ? ACTION_EFFECT_HAS_MORE : ''),
      null /* damageBonusByCasterLevel */
    );
  },
  Rage: function() {
    // TODO: Vary by level
    return new Effect(
      AttackRollTypes.Rage,
      Roll.constant(2) /* damageRoll */,
      DamageTypes.Rage,
      true /* hasSpecialDetails */,
      {
        9: Roll.constant(1),
        16: Roll.constant(1),
      }
    );
  },
  Recover: function(opt_hasSpecialDetails) {
    return new Effect(
      null /* attackRollType */,
      null /* damageRoll */,
      null /* damageType */,
      'Recover' + (opt_hasSpecialDetails ? ACTION_EFFECT_HAS_MORE : ''),
      null /* damageBonusByCasterLevel */
    );
  },
  Restrain: function(opt_hasSpecialDetails) {
    return new Effect(
      null /* attackRollType */,
      null /* damageRoll */,
      null /* damageType */,
      'Restrain' + (opt_hasSpecialDetails ? ACTION_EFFECT_HAS_MORE : ''),
      null /* damageBonusByCasterLevel */
    );
  },
  Shapechange: function(opt_hasSpecialDetails) {
    return new Effect(
      null /* attackRollType */,
      null /* damageRoll */,
      null /* damageType */,
      'Shapechange' + (opt_hasSpecialDetails ? ACTION_EFFECT_HAS_MORE : ''),
      null /* damageBonusByCasterLevel */
    );
  },
  Sleep: function(opt_hasSpecialDetails) {
    return new Effect(
      null /* attackRollType */,
      null /* damageRoll */,
      null /* damageType */,
      'Sleep' + (opt_hasSpecialDetails ? ACTION_EFFECT_HAS_MORE : ''),
      null /* damageBonusByCasterLevel */
    );
  },
  Stablize: function(opt_hasSpecialDetails) {
    return new Effect(
      null /* attackRollType */,
      null /* damageRoll */,
      null /* damageType */,
      'Stablize' + (opt_hasSpecialDetails ? ACTION_EFFECT_HAS_MORE : ''),
      null /* damageBonusByCasterLevel */
    );
  },
  Social: function(opt_hasSpecialDetails) {
    return new Effect(
      null /* attackRollType */,
      null /* damageRoll */,
      null /* damageType */,
      'Social' + (opt_hasSpecialDetails ? ACTION_EFFECT_HAS_MORE : ''),
      null /* damageBonusByCasterLevel */
    );
  },
  Summon: function(opt_hasSpecialDetails) {
    return new Effect(
      null /* attackRollType */,
      null /* damageRoll */,
      null /* damageType */,
      'Summon' + (opt_hasSpecialDetails ? ACTION_EFFECT_HAS_MORE : ''),
      null /* damageBonusByCasterLevel */
    );
  },
  Teleport: function(opt_hasSpecialDetails) {
    return new Effect(
      null /* attackRollType */,
      null /* damageRoll */,
      null /* damageType */,
      'Teleport' + (opt_hasSpecialDetails ? ACTION_EFFECT_HAS_MORE : ''),
      null /* damageBonusByCasterLevel */
    );
  },
  Utility: function(opt_hasSpecialDetails) {
    return new Effect(
      null /* attackRollType */,
      null /* damageRoll */,
      null /* damageType */,
      'Utility' + (opt_hasSpecialDetails ? ACTION_EFFECT_HAS_MORE : ''),
      null /* damageBonusByCasterLevel */
    );
  },
  // nonDamaging: function() {
  //     return new Effect(null /* attackRollType */ , null /* damageRoll */ , null /* damageType */ , true /* hasSpecialDetails */ , null /* damageBonusByCasterLevel */ );
  // },
};
