const TextUtilities = {
  Nth: function(value) {
    const lastDigit = value % 10;
    var ending;
    switch (lastDigit) {
      case 1:
        ending = 'st';
        break;
      case 2:
        ending = 'nd';
        break;
      case 3:
        ending = 'rd';
        break;
      default:
        ending = 'th';
        break;
    }
    return value + ending;
  },
};

export default TextUtilities;
