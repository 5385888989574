import { VS } from '../Components.js';
import PointType from '../PointType.js';
import Spell from '../Spell.js';

import AreaOfEffects from '../../Actions/AreaOfEffects.js';
import Durations from '../../Actions/Durations.js';
import Effects from '../../Actions/Effects.js';
import Ranges from '../../Actions/Ranges.js';
import Times from '../../Actions/Times.js';
import ToHits from '../../Actions/ToHits.js';
import Utilities from '../../Actions/Utilities.js';

export default new Spell(
  'Blade Ward',
  0,
  PointType.SpellSlot,
  [
    'You extend your hand and trace a sigil of warding in the air. Until the end of your next turn, you have resistance against bludgeoning, piercing, and slashing damage dealt by weapon attacks',
  ],
  Utilities.Combat,
  Times.Action,
  Durations.Rounds(1),
  Ranges.Self,
  AreaOfEffects.SingleTarget,
  ToHits.AlwaysWorks,
  Effects.Buff(),
  VS,
  'Abjuration'
);
