import CharacterEnhancer from './CharacterEnhancer.js';

class Feat extends CharacterEnhancer {
  constructor(name, description, isTrivial, opt_actionsAndEffects) {
    super(opt_actionsAndEffects);
    if (typeof isTrivial != 'boolean') {
      throw new Error('Oops');
    }
    this._name = name;
    this._description = description;
    this._isTrivial = isTrivial;
  }
  get name() {
    return this._name;
  }

  get description() {
    return this._description;
  }

  get isTrivial() {
    return this._isTrivial;
  }
}

export default Feat;
