import assert from '../Common/Assert.js';

import Action from './Actions/Action.js';
import Types from './Actions/Types.js';
import AreaOfEffects from './Actions/AreaOfEffects.js';
import Durations from './Actions/Durations.js';
import ActionEffects from './Actions/Effects.js';
import Ranges from './Actions/Ranges.js';
import Requirement from './Actions/Requirement.js';
import Sort from './Actions/Sort.js';
import Times from './Actions/Times.js';
import ToHits from './Actions/ToHits.js';
import Utilities from './Actions/Utilities.js';

import AbilityType from './AbilityType.js';
import DamageType, { DamageTypes } from './DamageType.js';
import StaticEffects from './StaticEffects.js';
import Item from './Item.js';
import Proficiencies from './Proficiencies.js';
import Roll from './Roll.js';

const SlingAmmo = new Requirement('One sling bullet', 'Sling Bullet');
const BowAmmo = new Requirement('One arrow', 'Arrow');
const CrossbowAmmo = new Requirement('One crossbow bolt', 'Bolt');

class NamedBonus {
  constructor(prefix, postfix) {
    this._prefix = prefix || '';
    this._postfix = postfix || '';
  }
  get prefix() {
    return this._prefix;
  }
  get postfix() {
    return this._postfix;
  }
}

const NameWithBonus = function(name, bonus) {
  if (typeof bonus === 'undefined' || bonus === null) {
    // No bonus, nothing to do
  } else if (typeof bonus === 'number') {
    if (bonus > 0) {
      name += ', +' + bonus;
    } else if (bonus < 0) {
      name += ', -' + -1 * bonus;
    }
  } else if (bonus instanceof NamedBonus) {
    name = bonus.prefix + name + bonus.postfix;
  } else {
    console.dir(bonus);
    throw Error('Unexpected bonus for ' + name);
  }
  return name;
};

const WeaponBonus = {
  PlusOne: 1,
  PlusTwo: 2,
  PlusThree: 3,
  Vicious: new NamedBonus('Vicious ', null),
  Warning: new NamedBonus(null, ' of Warning'),
};

const AttackBonus = function(bonus) {
  var attackBonus = null;
  if (typeof bonus === 'number') {
    attackBonus += bonus;
  }
  return attackBonus;
};

const DamageRoll = function(baseRoll, bonus) {
  var damageRoll = baseRoll;
  if (typeof bonus === 'number') {
    damageRoll = Roll.combine(baseRoll, Roll.constant(bonus));
  }
  return damageRoll;
};

const Weapon = function(
  name,
  description,
  attackType,
  attackDescription,
  ammoType,
  range,
  proficiency,
  baseRoll,
  damageType,
  weight,
  bonus,
  toHitFN,
  damageFN,
  opt_versatileDamageRoll,
  opt_thrownRange,
  opt_extraEffects,
  opt_requiresAttunement
) {
  name = NameWithBonus(name, bonus);
  var attackBonus = AttackBonus(bonus);
  var damageRoll = DamageRoll(baseRoll, bonus);
  var requiresAttunement = !!opt_requiresAttunement;
  if (bonus === WeaponBonus.Warning) {
    requiresAttunement = true;
  }
  var effects = [];
  effects.push(
    new Action(
      name,
      attackType,
      Sort.Item,
      attackDescription || 'You make an attack with your ' + name + '.',
      Utilities.Combat,
      Times.Action,
      Durations.NA,
      range,
      AreaOfEffects.SingleTarget,
      toHitFN(proficiency, attackBonus),
      damageFN(damageRoll, damageType),
      ammoType /* requires */
    )
  );
  if (opt_versatileDamageRoll) {
    var versatileToHitFN = toHitFN;
    if (toHitFN === ToHits.MeleeOrMartialArtsAttackRoll) {
      versatileToHitFN = ToHits.MeleeAttackRoll;
    } else if (toHitFN === ToHits.FinesseOrMartialArtsMeleeAttackRoll) {
      versatileToHitFN = ToHits.FinesseAttackRoll;
    }
    var versatileDamageFN = damageFN;
    if (damageFN === ActionEffects.MeleeOrMartialArtsDamage) {
      versatileDamageFN = ActionEffects.MeleeDamage;
    } else if (damageFN === ToHits.FinesseOrMartialArtsMeleeDamage) {
      versatileDamageFN = ToHits.FinesseDamage;
    }
    var versatileDamageRoll = DamageRoll(opt_versatileDamageRoll, bonus);
    effects.push(
      new Action(
        name + ' (2h)',
        attackType,
        Sort.Item,
        attackDescription || 'You make an attack with your ' + name + ' using two hands.',
        Utilities.Combat,
        Times.Action,
        Durations.NA,
        range,
        AreaOfEffects.SingleTarget,
        versatileToHitFN(proficiency, attackBonus),
        versatileDamageFN(versatileDamageRoll, damageType),
        ammoType /* requires */
      )
    );
  }
  if (opt_thrownRange) {
    effects.push(
      new Action(
        name + ' (thrown)',
        Types.ThrownAttack,
        Sort.Item,
        attackDescription || 'You make an attack by throwing your ' + name + '.',
        Utilities.Combat,
        Times.Action,
        Durations.NA,
        opt_thrownRange,
        AreaOfEffects.SingleTarget,
        toHitFN(proficiency, attackBonus),
        damageFN(damageRoll, damageType),
        ammoType /* requires */
      )
    );
  }
  if (opt_extraEffects) {
    effects.push(opt_extraEffects);
  }
  return new Item(name, 1, description, weight, effects, requiresAttunement);
};

const MeleeWeapon = function(
  name,
  description,
  opt_attackDescription,
  proficiency,
  baseRoll,
  damageType,
  weight,
  bonus,
  opt_versatileDamageRoll,
  opt_thrownRange,
  opt_extraEffects
) {
  return Weapon(
    name,
    description,
    Types.MeleeAttack,
    opt_attackDescription,
    null /* ammoType */,
    Ranges.Reach(5),
    proficiency,
    baseRoll,
    damageType,
    weight,
    bonus,
    ToHits.MeleeAttackRoll,
    ActionEffects.MeleeDamage,
    opt_versatileDamageRoll,
    opt_thrownRange,
    opt_extraEffects
  );
};
const MeleeOrMartialArtsWeapon = function(
  name,
  description,
  opt_attackDescription,
  proficiency,
  baseRoll,
  damageType,
  weight,
  bonus,
  opt_versatileDamageRoll,
  opt_thrownRange,
  opt_extraEffects
) {
  return Weapon(
    name,
    description,
    Types.MeleeAttack,
    opt_attackDescription,
    null /* ammoType */,
    Ranges.Reach(5),
    proficiency,
    baseRoll,
    damageType,
    weight,
    bonus,
    ToHits.MeleeOrMartialArtsAttackRoll,
    ActionEffects.MeleeOrMartialArtsDamage,
    opt_versatileDamageRoll,
    opt_thrownRange,
    opt_extraEffects
  );
};
const FinesseMeleeWeapon = function(
  name,
  description,
  opt_attackDescription,
  proficiency,
  baseRoll,
  damageType,
  weight,
  bonus,
  opt_versatileDamageRoll,
  opt_thrownRange,
  opt_extraEffects
) {
  return Weapon(
    name,
    description,
    Types.MeleeAttack,
    opt_attackDescription,
    null /* ammoType */,
    Ranges.Reach(5),
    proficiency,
    baseRoll,
    damageType,
    weight,
    bonus,
    ToHits.FinesseMeleeAttackRoll,
    ActionEffects.FinesseMeleeDamage,
    opt_versatileDamageRoll,
    opt_thrownRange,
    opt_extraEffects
  );
};
const FinesseOrMartialArtsMeleeWeapon = function(
  name,
  description,
  opt_attackDescription,
  proficiency,
  baseRoll,
  damageType,
  weight,
  bonus,
  opt_versatileDamageRoll,
  opt_thrownRange,
  opt_extraEffects
) {
  return Weapon(
    name,
    description,
    Types.MeleeAttack,
    opt_attackDescription,
    null /* ammoType */,
    Ranges.Reach(5),
    proficiency,
    baseRoll,
    damageType,
    weight,
    bonus,
    ToHits.FinesseOrMartialArtsMeleeAttackRoll,
    ActionEffects.FinesseOrMartialArtsMeleeDamage,
    opt_versatileDamageRoll,
    opt_thrownRange,
    opt_extraEffects
  );
};
const RangedWeapon = function(
  name,
  description,
  opt_attackDescription,
  proficiency,
  baseRoll,
  damageType,
  ammoType,
  range,
  weight,
  bonus,
  opt_extraEffects
) {
  return Weapon(
    name,
    description,
    Types.RangedAttack,
    opt_attackDescription,
    ammoType,
    range,
    proficiency,
    baseRoll,
    damageType,
    weight,
    bonus,
    ToHits.RangedAttackRoll,
    ActionEffects.RangedDamage,
    null /* versatile damage */,
    null /* thrown range */,
    opt_extraEffects
  );
};

// const MeleeAttack = function(name, proficiency, damageRoll, damageType, opt_description) {
//     return new Action(name, 'Melee Attack', Sort.Item, opt_description || 'You make a melee attack with your ' + name + '.', [Utilities.Combat],
//         Times.Action, Durations.NA, Ranges.Reach(5), AreaOfEffects.SingleTarget,
//         ToHits.MeleeAttackRoll(proficiency),
//         ActionEffects.MeleeDamage(damageRoll, damageType),
//         null /* requires */ );
// };

// const MeleeOrMartialArtsAttack = function(name, proficiency, damageRoll, damageType, opt_description) {
//     return new Action(name, 'Melee Attack', Sort.Item, opt_description || 'You make a melee attack with your ' + name + '.', [Utilities.Combat],
//         Times.Action, Durations.NA, Ranges.Reach(5), AreaOfEffects.SingleTarget,
//         ToHits.MeleeOrMartialArtsAttackRoll(proficiency),
//         ActionEffects.MeleeOrMartialArtsDamage(damageRoll, damageType),
//         null /* requires */ );
// };

// const FinesseMeleeAttack = function(name, proficiency, damageRoll, damageType, opt_description) {
//     return new Action(name, 'Melee Attack', Sort.Item, opt_description || 'You make a melee attack with your ' + name + '.', [Utilities.Combat],
//         Times.Action, Durations.NA, Ranges.Reach(5), AreaOfEffects.SingleTarget,
//         ToHits.FinesseMeleeAttackRoll(proficiency),
//         ActionEffects.FinesseMeleeDamage(damageRoll, damageType),
//         null /* requires */ );
// };

// const FinesseOrMartialArtsMeleeAttack = function(name, proficiency, damageRoll, damageType, opt_description) {
//     return new Action(name, 'Melee Attack', Sort.Item, opt_description || 'You make a melee attack with your ' + name + '.', [Utilities.Combat],
//         Times.Action, Durations.NA, Ranges.Reach(5), AreaOfEffects.SingleTarget,
//         ToHits.FinesseOrMartialArtsMeleeAttackRoll(proficiency),
//         ActionEffects.FinesseOrMartialArtsMeleeDamage(damageRoll, damageType),
//         null /* requires */ );
// };

// const RangedAttack = function(name, proficiency, damageRoll, damageType, lowRange, highRange, ammo, opt_description) {
//     return new Action(name, 'Ranged Attack', Sort.Item, opt_description || 'You make a ranged attack with your ' + name + '.', [Utilities.Combat],
//         Times.Action, Durations.NA, Ranges.Range(lowRange, highRange), AreaOfEffects.SingleTarget,
//         ToHits.RangedAttackRoll(proficiency),
//         ActionEffects.RangedDamage(damageRoll, damageType),
//         ammo);
// };

class ResistanceArmorBonus extends NamedBonus {
  constructor(damageType) {
    super(null, ' of ' + damageType + ' Resistance');
    assert(
      damageType instanceof DamageType,
      'Invalid damage type for resistance armor',
      damageType
    );
    this._damageType = damageType;
  }

  get damageType() {
    return this._damageType;
  }
}
const ArmorBonus = {
  PlusOne: 1,
  PlusTwo: 2,
  PlusThree: 3,
  Adamantine: new NamedBonus('Adamantine ', null),
  Mithril: new NamedBonus('Mithral ', null),
  Resistance: function(damageType) {
    return new ResistanceArmorBonus(damageType);
  },
  // Invulnerability
  // Vulnerability
  // Mariner's
  // Scorpion
};

const Armor = function(
  name,
  description,
  proficiency,
  baseAC,
  maxDexModifier,
  weight,
  strength,
  stealthDisadvantage,
  bonus,
  opt_requiresAttunement
) {
  // TODO: Handle proficiency, max dex modifier, strength requirement, and stealth disadvantage
  // TODO: Handle resistance, mithril, adamantine, etc bonuses
  var ac = baseAC;
  var effects = [];
  var requiresAttunement = !!opt_requiresAttunement;
  if (typeof bonus === 'number') {
    ac += bonus;
    if (bonus > 0) {
      name += ', +' + bonus;
    } else if (bonus < 0) {
      name += ', -' + -1 * bonus;
    }
  } else if (bonus === ArmorBonus.Mithril) {
    stealthDisadvantage = false;
    strength = null;
  } else if (bonus === ArmorBonus.Adamantine) {
    effects.push(StaticEffects.CRITICAL_HIT_IMMUNITY);
  } else if (bonus instanceof ResistanceArmorBonus) {
    effects.push(StaticEffects.RESISTANCE(bonus.damageType));
    requiresAttunement = true;
  }
  effects.push(StaticEffects.ARMOR_AC(ac, maxDexModifier));
  if (strength) {
    effects.push(StaticEffects.HEAVY_ARMOR(strength));
  }
  return new Item(name, 1, description, weight, effects, requiresAttunement);
};

const Shield = function(name, description, weight, bonus, opt_extraEffects) {
  var acBonus = 0;
  var effects = [];
  if (typeof bonus === 'number') {
    acBonus = bonus;
    if (bonus > 0) {
      name += ', +' + bonus;
    } else if (bonus < 0) {
      name += ', -' + -1 * bonus;
    }
  }
  effects.push(StaticEffects.SHIELD_AC(acBonus));
  if (opt_extraEffects) {
    effects = effects.concat(opt_extraEffects);
  }
  return new Item(name, 1, description, weight, effects);
};

const Items = {
  Arrows: function(count) {
    return new Item(
      'Arrow',
      count,
      [
        'Arrows are used with a weapon that has the ammunition property to make a ranged attack. Each time you attack with the weapon, you expend one piece of ammunition. Drawing the ammunition from a quiver, case, or other container is part of the attack (you need a free hand to load a one-handed weapon). At the end of the battle, you can recover half your expended ammunition by taking a minute to search the battlefield.',
      ],
      0.05 /* 1lb per 20 */
    );
  },

  Backpack: new Item(
    'Backpack',
    1,
    [
      'A backpack is a leather pack carried on the back, typically with straps to secure it. A backpack can hold 1 cubic foot/ 30 pounds of gear.',
      'You can also strap items, such as a bedroll or a coil of rope, to the outside of a backpack.',
    ],
    5
  ),
  Battleaxe: function(bonus) {
    return MeleeWeapon(
      'Battleaxe',
      [
        'Proficiency with a battleaxe allows you to add your proficiency bonus to the attack roll for any attack you make with it.',
      ],
      null /* opt_attackDescription */,
      Proficiencies.Battleaxe,
      Roll.d(8),
      DamageTypes.Slashing,
      4 /* weight */,
      bonus,
      Roll.d(10)
    );
  },
  Bedroll: new Item(
    'Bedroll',
    1,
    [
      'You never know where you’re going to sleep, and a bedroll helps you get better sleep in a hayloft or on the cold ground. A bedroll consists of bedding and a blanket thin enough to be rolled up and tied. In an emergency, it can double as a stretcher.',
    ],
    7
  ),
  BlowgunNeedle: function(count) {
    return new Item(
      'Blowgun Needle',
      count,
      [
        'Blowgun needles are used with a weapon that has the ammunition property to make a ranged attack. Each time you attack with the weapon, you expend one piece of ammunition. Drawing the ammunition from a quiver, case, or other container is part of the attack (you need a free hand to load a one-handed weapon). At the end of the battle, you can recover half your expended ammunition by taking a minute to search the battlefield.',
      ],
      0.02 /* 1lb per 50 */
    );
  },
  Bongos: new Item(
    'Bongos',
    1,
    [
      'If you have proficiency with a given musical instrument, you can add your proficiency bonus to any ability checks you make to play music with the instrument. A bard can use a musical instrument as a spellcasting focus.',
    ],
    4
  ),
  Breastplate: function(bonus, opt_overrideName, opt_overrideDescription) {
    var name = opt_overrideName || 'Breastplate';
    var description = opt_overrideDescription || [
      "This armor consists of a fitted metal chest piece worn with supple leather. Although it leaves the legs and arms relatively unprotected, this armor provides good protection for the wearer's vital organs while leaving the wearer relatively unencumbered.",
    ];
    return Armor(
      name,
      description,
      Proficiencies.MediumArmor,
      14 /* baseAC */,
      2 /* maxDexModifier */,
      20 /* weight */,
      null /* strength */,
      false /* stealthDisadvantage */,
      bonus
    );
  },

  CartographersTools: new Item(
    "Cartographer's Tools",
    1,
    [
      "These special tools include the items needed to pursue a craft or trade. Proficiency with a set of artisan's tools lets you add your proficiency bonus to any ability checks you make using the tools in your craft. Each type of artisan's tools requires a separate proficiency.",
    ],
    6
  ),
  ChainMail: function(bonus) {
    return Armor(
      'Chain Mail',
      [
        'Made of interlocking metal rings, chain mail includes a layer of quilted fabric worn underneath the mail to prevent chafing and to cushion the impact of blows. The suit includes gauntlets.',
      ],
      Proficiencies.HeavyArmor,
      16 /* baseAC */,
      0 /* maxDexModifier */,
      55 /* weight */,
      13 /* strength */,
      true /* stealthDisadvantage */,
      bonus
    );
  },
  ChainShirt: function(bonus) {
    return Armor(
      'Chain Shirt',
      [
        "Made of interlocking metal rings, a chain shirt is worn between layers of clothing or leather. This armor offers modest protection to the wearer's upper body and allows the sound of the rings rubbing against one another to be muffled by outer layers.",
      ],
      Proficiencies.MediumArmor,
      13 /* baseAC */,
      2 /* maxDexModifier */,
      20 /* weight */,
      null /* strength */,
      false /* stealthDisadvantage */,
      bonus
    );
  },
  Chalk: function(count) {
    return new Item(
      'Chalk',
      count,
      ['A piece of chalk used for writing and marking on various surfaces.'],
      0
    );
  },
  ClothesCommon: function(count) {
    return new Item(
      'Common Clothes',
      count,
      [
        'This set of clothes could consist of a loose shirt and baggy breeches, or a loose shirt and skirt or overdress. Cloth wrappings are used for shoes.',
      ],
      3
    );
  },
  ClothesFine: function(count) {
    return new Item(
      'Fine Clothes',
      count,
      [
        'This set of clothes is designed specifically to be expensive and to show it, including fancy, tailored clothes in whatever fashion happens to be the current style in the courts of the nobles. Precious metals and gems could be worked into the clothing.',
      ],
      6
    );
  },
  Club: function(bonus) {
    return MeleeOrMartialArtsWeapon(
      'Club',
      [
        'Proficiency with a club allows you to add your proficiency bonus to the attack roll for any attack you make with it.',
      ],
      null /* opt_attackDescription */,
      Proficiencies.Club,
      Roll.d(4),
      DamageTypes.Bludgeoning,
      2 /* weight */,
      bonus
    );
  },
  Costume: function(count) {
    return new Item(
      'Costume',
      count,
      [
        'This set of clothes is fashioned after a particular costume, typically meant for entertaining.',
      ],
      4
    );
  },
  CrossbowBoltCase: new Item(
    'Crossbow Bolt Case',
    1,
    ['This wooden case can hold up to twenty crossbow bolts.'],
    1
  ),
  CrossbowBolts: function(count) {
    return new Item(
      'Crossbow Bolt',
      count,
      [
        'Crossbow bolts are used with a weapon that has the ammunition property to make a ranged attack. Each time you attack with the weapon, you expend one piece of ammunition. Drawing the ammunition from a quiver, case, or other container is part of the attack (you need a free hand to load a one-handed weapon). At the end of the battle, you can recover half your expended ammunition by taking a minute to search the battlefield.',
      ],
      0.075 /* 1.5lb per 20 */
    );
  },

  Dagger: function(bonus) {
    return FinesseOrMartialArtsMeleeWeapon(
      'Dagger',
      [
        'Proficiency with a dagger allows you to add your proficiency bonus to the attack roll for any attack you make with it.',
      ],
      null /* opt_attackDescription */,
      Proficiencies.Dagger,
      Roll.d(4),
      DamageTypes.Piercing,
      1 /* weight */,
      bonus
    );
  },
  DisguiseKit: new Item(
    'Disguise Kit',
    1,
    [
      'This pouch of cosmetics, hair dye, and small props lets you create disguises that change your physical appearance. Proficiency with this kit lets you add your proficiency bonus to any ability checks you make to create a visual disguise.',
    ],
    3
  ),
  DruidicFocus: function(name, opt_weight) {
    var description;
    if (name) {
      name = name + ' (Focus)';
      description = 'A druid can use this item as a spellcasting focus.';
    } else {
      name = 'Druidic Focus';
      description =
        'A druid can use a length of wood, a sprig of mistletoe, or some other part of a plant they hold sacred as a spellcasting focus.';
    }
    return new Item(name, 1, [description], opt_weight || 0);
  },

  HalfPlate: function(bonus) {
    return Armor(
      'Half Plate',
      [
        "Half plate consists of shaped metal plates that cover most of the wearer's body. It does not include leg protection beyond simple greaves that are attached with leather straps.",
      ],
      Proficiencies.MediumArmor,
      15 /* baseAC */,
      2 /* maxDexModifier */,
      40 /* weight */,
      null /* strength */,
      true /* stealthDisadvantage */,
      bonus
    );
  },
  HerbalismKit: new Item(
    'Herbalism Kit',
    1,
    [
      'This kit contains a variety of instruments such as clippers, mortar and pestle, and pouches and vials used by herbalists to create remedies and potions. Proficiency with this kit lets you add your proficiency bonus to any ability checks you make to identify or apply herbs. Also, proficiency with this kit is required to create antitoxin and any potion of healing.',
    ],
    3
  ),
  HideArmor: function(bonus, opt_overrideName, opt_overrideDescription) {
    var name = opt_overrideName || 'Hide Armor';
    var description = opt_overrideDescription || [
      'This crude armor consists of thick furs and pelts. It is commonly worn by barbarian tribes, evil humanoids, and other folk who lack access to the tools and materials needed to create better armor.',
    ];
    return Armor(
      name,
      description,
      Proficiencies.MediumArmor,
      12 /* baseAC */,
      2 /* maxDexModifier */,
      12 /* weight */,
      null /* strength */,
      false /* stealthDisadvantage */,
      bonus
    );
  },
  Horn: new Item(
    'Horn',
    1,
    [
      'If you have proficiency with a given musical instrument, you can add your proficiency bonus to any ability checks you make to play music with the instrument. A bard can use a musical instrument as a spellcasting focus.',
    ],
    2
  ),

  LeatherArmor: function(bonus, opt_overrideName, opt_overrideDescription) {
    var name = opt_overrideName || 'Leather Armor';
    var description = opt_overrideDescription || [
      'The breastplate and shoulder protectors of this armor are made of leather that has been stiffened by being boiled in oil. The rest of the armor is made of softer and more flexible materials.',
    ];
    return Armor(
      name,
      description,
      Proficiencies.LightArmor,
      11 /* baseAC */,
      null /* maxDexModifier */,
      10 /* weight */,
      null /* strength */,
      false /* stealthDisadvantage */,
      bonus
    );
  },
  LightCrossbow: function(bonus) {
    return RangedWeapon(
      'Light Crossbow',
      [
        'Proficiency with a light crossbow allows you to add your proficiency bonus to the attack roll for any attack you make with it.',
      ],
      null /* opt_attackDescription */,
      Proficiencies.LightCrossbow,
      Roll.d(8),
      DamageTypes.Piercing,
      CrossbowAmmo,
      Ranges.Range(80, 320),
      5 /* weight */,
      bonus
    );
  },
  Longbow: function(bonus) {
    return RangedWeapon(
      'Longbow',
      [
        'Proficiency with a longbow allows you to add your proficiency bonus to the attack roll for any attack you make with it.',
      ],
      null /* opt_attackDescription */,
      Proficiencies.Longbow,
      Roll.d(8),
      DamageTypes.Piercing,
      BowAmmo,
      Ranges.Range(150, 600),
      2 /* weight */,
      bonus
    );
  },
  Lute: new Item(
    'Lute',
    1,
    [
      'If you have proficiency with a given musical instrument, you can add your proficiency bonus to any ability checks you make to play music with the instrument. A bard can use a musical instrument as a spellcasting focus.',
    ],
    2
  ),
  Lyre: new Item(
    'Lyre',
    1,
    [
      'If you have proficiency with a given musical instrument, you can add your proficiency bonus to any ability checks you make to play music with the instrument. A bard can use a musical instrument as a spellcasting focus.',
    ],
    2
  ),

  MakeupKit: new Item('Makeup Kit', 1, ['This small case carries some simple cosmetics.'], 0.5),
  MessKit: new Item(
    'Mess Kit',
    1,
    [
      'This tin box contains a cup and simple cutlery. The box clamps together, and one side can be used as a cooking pan and the other as a plate or shallow bowl.',
    ],
    1
  ),

  // PaddedArmor -- stock D&D padded armor unrealistic... see Gambeson (eq to Hide) and Light Gambeson (eq to leather)
  PlateArmor: function(bonus) {
    return Armor(
      'Plate Armor',
      [
        'Plate consists of shaped, interlocking metal plates to cover the entire body. A suit of plate includes gauntlets, heavy leather boots, a visored helmet, and thick layers of padding underneath the armor. Buckles and straps distribute the weight over the body.',
      ],
      Proficiencies.HeavyArmor,
      18 /* baseAC */,
      0 /* maxDexModifier */,
      65 /* weight */,
      15 /* strength */,
      true /* stealthDisadvantage */,
      bonus
    );
  },
  PotionOfHealing: function(count) {
    return new Item(
      'Potion of Healing',
      count,
      [
        'A character who drinks the magical red fluid in this vial regains 2d4 + 2 hit points. Drinking or administering a potion takes an action.',
      ],
      1
    );
  },
  Pouch: new Item(
    'Pouch',
    1,
    [
      'A cloth or leather pouch can hold 1/5 cubic foot/ 6 pounds of gear - or up to 20 sling bullets or 50 blowgun needles, among other things.',
    ],
    1
  ),

  Quarterstaff: function(bonus) {
    return MeleeOrMartialArtsWeapon(
      'Quarterstaff',
      [
        'Proficiency with a quarterstaff allows you to add your proficiency bonus to the attack roll for any attack you make with it.',
      ],
      null /* opt_attackDescription */,
      Proficiencies.Quarterstaff,
      Roll.d(6),
      DamageTypes.Bludgeoning,
      4 /* weight */,
      bonus,
      Roll.d(8)
    );
  },

  Rapier: function(bonus) {
    return FinesseMeleeWeapon(
      'Rapier',
      [
        'Proficiency with a rapier allows you to add your proficiency bonus to the attack roll for any attack you make with it.',
      ],
      null /* opt_attackDescription */,
      Proficiencies.Rapier,
      Roll.d(8),
      DamageTypes.Piercing,
      2 /* weight */,
      bonus
    );
  },
  Rations: function(count) {
    return new Item(
      'Rations',
      count,
      [
        'Rations consist of dry foods suitable for extended travel, including jerky, dried fruit, hardtack, and nuts.',
      ],
      2
    );
  },
  RingMail: function(bonus) {
    return Armor(
      'Ring Mail',
      [
        "This armor is leather armor with heavy rings sewn into it. The rings help reinforce the armor against blows from swords and axes. Ring mail is inferior to chain mail, and it's usually worn only by those who can't afford better armor.",
      ],
      Proficiencies.HeavyArmor,
      14 /* baseAC */,
      0 /* maxDexModifier */,
      40 /* weight */,
      null /* strength */,
      true /* stealthDisadvantage */,
      bonus
    );
  },

  Rope50: function(count) {
    return new Item(
      'Rope (50 feet)',
      count,
      ['Rope, has 2 hit points and can be burst with a DC 17 Strength check.'],
      10
    );
  },

  ScaleMail: function(bonus) {
    return Armor(
      'Scale Mail',
      [
        'This armor consists of a coat and leggings (and perhaps a separate skirt) of leather covered with overlapping pieces of metal, much like the scales of a fish. The suit includes gauntlets.',
      ],
      Proficiencies.MediumArmor,
      14 /* baseAC */,
      2 /* maxDexModifier */,
      45 /* weight */,
      null /* strength */,
      true /* stealthDisadvantage */,
      bonus
    );
  },
  Scimitar: function(bonus) {
    return FinesseMeleeWeapon(
      'Scimitar',
      [
        'Proficiency with a rapier allows you to add your proficiency bonus to the attack roll for any attack you make with it.',
      ],
      null /* opt_attackDescription */,
      Proficiencies.Scimitar,
      Roll.d(6),
      DamageTypes.Slashing,
      3 /* weight */,
      bonus
    );
  },
  Sling: function(bonus) {
    return RangedWeapon(
      'Sling',
      [
        'Proficiency with a sling allows you to add your proficiency bonus to the attack roll for any attack you make with it.',
      ],
      null /* opt_attackDescription */,
      Proficiencies.Sling,
      Roll.d(4),
      DamageTypes.Bludgeoning,
      SlingAmmo,
      Ranges.Range(30, 120),
      0 /* weight */,
      bonus
    );
  },
  SlingBullet: function(count) {
    return new Item(
      'Sling Bullet',
      count,
      [
        'Sling bullets are used with a weapon that has the ammunition property to make a ranged attack. Each time you attack with the weapon, you expend one piece of ammunition. Drawing the ammunition from a quiver, case, or other container is part of the attack (you need a free hand to load a one-handed weapon). At the end of the battle, you can recover half your expended ammunition by taking a minute to search the battlefield.',
      ],
      0.075 /* 1.5lb per 20 */
    );
  },
  Shield: function(bonus) {
    return Shield(
      'Shield',
      [
        'A shield is made from wood or metal and is carried in one hand. Wielding a shield increases your Armor Class by 2. You can benefit from only one shield at a time.',
      ],
      6 /* weight */,
      bonus
    );
  },
  SplintArmor: function(bonus) {
    return Armor(
      'Splint Armor',
      [
        'This armor is made of narrow vertical strips of metal riveted to a backing of leather that is worn over cloth padding. Flexible chain mail protects the joints.',
      ],
      Proficiencies.HeavyArmor,
      17 /* baseAC */,
      0 /* maxDexModifier */,
      60 /* weight */,
      15 /* strength */,
      true /* stealthDisadvantage */,
      bonus
    );
  },
  Spyglass: new Item(
    'Spyglass',
    1,
    ['Objects viewed through a spyglass are magnified to twice their size.'],
    1
  ),
  SteelMirror: new Item(
    'Mirror (Steel)',
    1,
    [
      'A steel mirror is handy when you want to look around corners, signal friends with reflected sunlight, make sure that you look good enough to present yourself to the queen, or examine wounds that you’ve received on hard-to-see parts of your body.',
    ],
    0.5
  ),
  StuddedLeatherArmor: function(bonus, opt_overrideName, opt_overrideDescription) {
    var name = opt_overrideName || 'Studded Leather Armor';
    var description = opt_overrideDescription || [
      'Made from tough but flexible leather, studded leather is reinforced with plates of metal connected with rivets, appearing as studs on the outside.',
    ];
    return Armor(
      name,
      description,
      Proficiencies.LightArmor,
      12 /* baseAC */,
      null /* maxDexModifier */,
      13 /* weight */,
      null /* strength */,
      false /* stealthDisadvantage */,
      bonus
    );
  },

  Tinderbox: new Item(
    'Tinderbox',
    1,
    [
      'This small container holds flint, fire steel, and tinder (usually dry cloth soaked in light oil) used to kindle a fire. Using it to light a torch -- or anything else with abundant, exposed fuel -- takes an action. Lighting any other fire takes 1 minute.',
    ],
    1
  ),
  Torch: function(count) {
    return new Item(
      'Torch',
      count,
      [
        'A torch burns for 1 hour, providing bright light in a 20-foot radius and dim light for an additional 20 feet. If you make a melee attack with a burning torch and hit, it deals 1 fire damage.',
      ],
      1
    );
  },

  Viol: new Item(
    'Viol',
    1,
    [
      'If you have proficiency with a given musical instrument, you can add your proficiency bonus to any ability checks you make to play music with the instrument. A bard can use a musical instrument as a spellcasting focus.',
    ],
    1
  ),

  Waterskin: new Item('Waterskin', 1, ['A waterskin can hold 4 pints of liquid.'], 5),

  // -----------------
  // Homebrew Non-Magic Items
  BarkLinedShirt: function(bonus) {
    return Items.LeatherArmor(bonus, 'Bark Vest', [
      'This linen vest is lined with plates of treated bark in sensitive areas for added protection.',
    ]);
  },
  BarkLinedArmor: function(bonus) {
    return Items.HideArmor(bonus, 'Bark Armor', [
      'This linen suit is lined with plates of treated bark in sensitive areas for added protection.',
      'It includes a vest, leggings, faulds (waist and hip guard), pauldrons (shoulder guard), and vambraces (forearm guard).',
    ]);
  },

  CatTreats: new Item(
    'Cat Treats',
    1,
    ['Delicious (to domestic cats at least), but not very nutritional.'],
    0
  ),
  Compass: new Item(
    'Compass',
    1,
    ['This handheld mechanical device has a pointer that (usually) points north.'],
    0.25
  ),

  Gambeson: function(bonus) {
    return Items.HideArmor(bonus, 'Gambeson', [
      'This padded, defensive jacket consists of several layers of quilted linen or wool.',
    ]);
  },

  HookedShortstaff: function(bonus) {
    return MeleeOrMartialArtsWeapon(
      'Hooked Shortstaff',
      [
        'Proficiency with a club allows you to add your proficiency bonus to the attack roll for any striking attack you make with it.',
      ],
      null /* opt_attackDescription */,
      Proficiencies.Club,
      Roll.d(6),
      DamageTypes.Bludgeoning,
      4 /* weight */,
      bonus,
      null /* versatile damage */,
      null /* thrown range */,
      [
        new Action(
          NameWithBonus('Hooked Shortstaff', bonus) + ' (trip)',
          Types.ItemPower,
          Sort.Item,
          [
            'You can attempt to trip a creature of your size or smaller using the hooked end of your staff.',
            'Make a dexterity check opposed by your targets dexterity or strength check (their choice).',
            'If you win the challenge, target is knocked prone and suffers 1d4 bludgeoning damage.',
            'If you lose the challenge, target is unaffected.',
            'Flying creatures and creatures without legs (or similar support structures) are immune.',
            'Creatures with more than two legs have advantage on their check.',
          ],
          Utilities.Combat,
          Times.Action,
          Durations.NA,
          Ranges.Reach(5),
          AreaOfEffects.SingleTarget,
          ToHits.MeleeDexAttackRoll(
            Proficiencies.IMPOSSIBLE,
            null /* bonus */,
            true /* hasSpecialDetails */
          ),
          ActionEffects.MeleeDexDamage(
            DamageRoll(Roll.d(4), bonus),
            DamageTypes.Bludgeoning,
            true /* hasSpecialDetails */
          ),
          null /* requires */
        ),
      ]
    );
  },

  HookedStaff: function(bonus, opt_overrideName) {
    var name = opt_overrideName || 'Hooked Staff';
    return MeleeOrMartialArtsWeapon(
      name,
      [
        'Proficiency with a quarterstaff allows you to add your proficiency bonus to the attack roll for any striking attack you make with it.',
      ],
      null /* opt_attackDescription */,
      Proficiencies.Quarterstaff,
      Roll.d(6),
      DamageTypes.Bludgeoning,
      4 /* weight */,
      bonus,
      Roll.d(8) /* versatile damage */,
      null /* thrown range */,
      [
        new Action(
          NameWithBonus(name, bonus) + ' (trip)',
          Types.MeleeAttack,
          Sort.Item,
          [
            'You can attempt to trip a creature of your size or smaller using the hooked end of your staff.',
            'Make a dexterity check opposed by your targets dexterity or strength check (their choice).',
            'If you win the challenge, target is knocked prone and suffers 1d4 bludgeoning damage.',
            'If you lose the challenge, target is unaffected.',
            'Flying creatures and creatures without legs (or similar support structures) are immune.',
            'Creatures with more than two legs have advantage on their check.',
          ],
          Utilities.Combat,
          Times.Action,
          Durations.NA,
          Ranges.Reach(5),
          AreaOfEffects.SingleTarget,
          ToHits.Contest(
            AbilityType.STR,
            true /* hasSpecialDetails */
          ),
          ActionEffects.UnmodifiedDamage(
            DamageRoll(Roll.d(4), bonus),
            DamageTypes.Bludgeoning,
            true /* hasSpecialDetails */
          ),
          null /* requires */
        ),
        new Action(
          NameWithBonus(name, bonus) + ' (½)',
          Types.MeleeAttack,
          Sort.Item,
          ['With your staff split in half, you attack with one half.'],
          Utilities.Combat,
          Times.Action,
          Durations.NA,
          Ranges.Reach(5),
          AreaOfEffects.SingleTarget,
          ToHits.FinesseMeleeAttackRoll(
            Proficiencies.Quarterstaff,
            bonus,
            false /* hasSpecialDetails */
          ),
          ActionEffects.FinesseMeleeDamage(
            DamageRoll(Roll.d(6), bonus),
            DamageTypes.Bludgeoning,
            false /* hasSpecialDetails */
          ),
          null /* requires */
        ),
        new Action(
          NameWithBonus(name, bonus) + ' (½, bonus)',
          Types.MeleeAttack,
          Sort.Item,
          [
            'With your staff split in half, you attack with one half.',
            'Must be done after taking an attack action on the same turn, using a different light weapon (ex. the other half of the staff).',
          ],
          Utilities.Combat,
          Times.Bonus,
          Durations.NA,
          Ranges.Reach(5),
          AreaOfEffects.SingleTarget,
          ToHits.FinesseOffHandAttackRoll(
            Proficiencies.Quarterstaff,
            bonus,
            false /* hasSpecialDetails */
          ),
          ActionEffects.FinesseOffHandDamage(
            DamageRoll(Roll.d(6), bonus),
            DamageTypes.Bludgeoning,
            false /* hasSpecialDetails */
          ),
          null /* requires */
        ),
      ]
    );
  },

  Ink: new Item('Ink (1oz bottle)', 1, ['Ink is typically used with an ink pen to write.'], 0),
  InkPen: new Item(
    'Ink Pen',
    1,
    [
      'An ink pen is a wooden stick with a special tip on the end. The tip draws ink in when dipped in a vial and leaves an ink trail when drawn across a surface.',
    ],
    0
  ),
  IronwoodBreastplate: function(bonus) {
    return Items.Breastplate(bonus, 'Ironwood Breastplate', [
      'This armor consists of a plates of a very hard wood connected with linen or other cloth, designed to be fitted to the specific wearer.',
      "Although it leaves the legs and arms relatively unprotected, this armor provides good protection for the wearer's vital organs while leaving the wearer relatively unencumbered.",
      'An ironwood breastplate that does not fit properly is extremely distracting and will hinder attacks, spellcasting, stealth, and other actions that require metal focus.',
    ]);
  },

  Journal: new Item('Journal', 1, ['A bound book full of blank pages, used as a journal.'], 2.5),

  LightGambeson: function(bonus) {
    return Items.LeatherArmor(bonus, 'Light Gambeson', [
      'This padded, defensive jacket consists of several layers of quilted linen or wool (but fewer than a typical Gambeson).',
    ]);
  },

  SeedPouch: new Item(
    'Seed Pouch',
    1,
    [
      'This small pouch includes a variety of seeds, with each type of seed having their own tiny compartment.',
    ],
    0
  ),

  // -----------------
  // Magic Items
  OrbOfDirection: new Item(
    'Orb of Direction',
    1,
    [
      'While holding this orb, you can use an action to determine which way is north. This property functions only on the Material Plane.',
    ],
    0,
    [
      new Action(
        'Find North',
        Types.ItemPower,
        Sort.Item,
        'You can use an action to determine which way is north by looking at the Orb of Direction.',
        [Utilities.NonCombat],
        Times.Action,
        Durations.NA,
        Ranges.Touch,
        AreaOfEffects.SingleTarget,
        ToHits.AlwaysWorks,
        ActionEffects.Utility(true),
        null /* requires */
      ),
    ]
  ),
  OrbOfTime: new Item(
    'Orb of Direction',
    1,
    [
      'While holding this orb, you can use an action to determine whether it is morning, afternoon, evening, or nighttime outside. This property functions only on the Material Plane.',
    ],
    0,
    [
      new Action(
        'Check Time',
        Types.ItemPower,
        Sort.Item,
        'You can use an action to determine the approximate time by looking at the Orb of Time.',
        [Utilities.NonCombat],
        Times.Action,
        Durations.NA,
        Ranges.Touch,
        AreaOfEffects.SingleTarget,
        ToHits.AlwaysWorks,
        ActionEffects.Utility(true),
        null /* requires */
      ),
    ]
  ),

  RingOfMindShielding: function(opt_name) {
    const name = opt_name || 'Ring of Mind Shielding';
    return new Item(
      name,
      1,
      [
        'While wearing this ring, you are immune to magic that allows other creatures to read your thoughts, determine whether you are lying, know your alignment, or know your creature type. Creatures can telepathically communicate with you only if you allow it.',
        'You can use an action to cause the ring to become invisible until you use another action to make it visible, until you remove the ring, or until you die.',
        "If you die while wearing the ring, your soul enters it, unless it already houses a soul. You can remain in the ring or depart for the afterlife. As long as your soul is in the ring, you can telepathically communicate with any creature wearing it. A wearer can't prevent this telepathic communication.",
      ],
      0,
      [
        new Action(
          'Hide ' + name,
          Types.ItemPower,
          Sort.Item,
          'You can use an action to cause the ring to become invisible until you use another action to make it visible, until you remove the ring, or until you die.',
          [Utilities.NonCombat],
          Times.Action,
          Durations.NA,
          Ranges.Touch,
          AreaOfEffects.SingleTarget,
          ToHits.AlwaysWorks,
          ActionEffects.Invisibility(),
          null /* requires */
        ),
      ],
      true /* requiresAttunement */
    );
  },

  // -----------------
  // Homebrew Magic Items
  AmarasShadowBand: new Item(
    "Amara's Shadow Band",
    1,
    [
      'Ring is made of black metal.',
      "While wearing this ring, if you do not have another familiar, you gain the service of a special bat familiar named Amara's Shadow.",
      "When you cast a spell with a range of touch, Amara's Shadow is within 100 feet of you, and she has not used her reaction yet this turn, you can use her reaction to deliver the spell through her touch.",
      "Amara's Shadow has the following stats:",
      '* Str 2, Dex 16, Con 6, Int 2, Wis 12, Cha 4',
      '* Size: Tiny (10" wingspan, 2" body, 0.2 ounces), Type: Fey, Alignment: unaligned',
      '* HP 1, AC 13, Speed 5, Flying Speed 40, Stealth +5',
      "* Blindsight 60ft, Echolocation (can't use blindsight when deafened)",
      '* Keen Hearing (advantage on perception checks that rely on hearing)',
      '* Unable to attack',
      "When Amara's Shadow is dismissed, she transforms into a cloud of shadow and moves straight to the ring at a speed of 900 ft (ignoring any obstacles or other movement restrictions) before disappearing into the ring's pocket dimension.",
      "If Amara's Shadow drops to 0 hit points, she is immediately dismissed instead of dying or falling unconcious, the ring becomes cold to the touch for one hour, and she cannot be re-summoned until the end of that hour.",
      "Amara's Shadow will not willingly move more than 100 feet from you, and will attempt to follow if you moves away from her. If Amara's Shadow is unable to stay within 100 feet of you, she will be immediately dismissed.",
      "If Amara's Shadow has been dismissed for more than 10 hours, she wills you to summon her. You must succeed a Wisdom Saving Throw with a DC equal to the number of hours since she was last summoned every once every hour to avoid summoning her. If you are incapable of summoning her (unconcious, paralyzed, etc), wait until you become capable before rolling a saving throw.",
      "If the ring is removed, or if you become unconcious or dead, Amara's Shadow is immediately dismissed.",
    ],
    0,
    [
      new Action(
        "Amara's Shadow (Summon)",
        Types.ItemPower,
        Sort.Item,
        ["You can use an action to summon Amara's Shadow from the ring's pocket dimension."],
        [Utilities.NonCombat],
        Times.Action,
        Durations.Instantaneous,
        Ranges.Touch,
        AreaOfEffects.SingleTarget,
        ToHits.AlwaysWorks,
        ActionEffects.Summon(),
        null /* requires */
      ),
      new Action(
        "Amara's Shadow (Dismiss)",
        Types.ItemPower,
        Sort.Item,
        ["You can use an action to dismiss Amara's Shadow back into the ring's pocket dimension."],
        [Utilities.NonCombat],
        Times.Action,
        Durations.Instantaneous,
        Ranges.Touch,
        AreaOfEffects.SingleTarget,
        ToHits.AlwaysWorks,
        ActionEffects.Dismiss(),
        null /* requires */
      ),
      new Action(
        "Amara's Shadow (Hear)",
        Types.ItemPower,
        Sort.Item,
        [
          'If Amara\'s Shadow is within 100 feet of you, you can hear what Amara\'s Shadow can hear and "see" what she sees through Echolocation for up to one minute. During this time, you are deaf and blind with regard to your own senses. You must wait at least five minutes before using this again.',
        ],
        [Utilities.NonCombat],
        Times.Action,
        Durations.Instantaneous,
        Ranges.Touch,
        AreaOfEffects.SingleTarget,
        ToHits.AlwaysWorks,
        ActionEffects.Buff(),
        null /* requires */
      ),
    ],
    true /* requiresAttunement */
  ),
};

export { Items as default, ArmorBonus, WeaponBonus };
