import { VSM } from '../Components.js';
import PointType from '../PointType.js';
import Spell from '../Spell.js';

import AreaOfEffects from '../../Actions/AreaOfEffects.js';
import Durations from '../../Actions/Durations.js';
import Effects from '../../Actions/Effects.js';
import Ranges from '../../Actions/Ranges.js';
import Times from '../../Actions/Times.js';
import ToHits from '../../Actions/ToHits.js';
import Utilities from '../../Actions/Utilities.js';

export default new Spell(
  'Mending',
  0,
  PointType.SpellSlot,
  [
    'This spell repairs a single break or tear in an object you touch, such as a broken chain link, two halves of a broken key, a torn cloak, or a leaking wineskin. As long as the break or tear is no larger than 1 foot in any dimension, you mend it, leaving no trace of the former damage.',
    "This spell can physically repair a magic item or construct, but the spell can't restore magic to such an object.",
  ],
  Utilities.NonCombat,
  Times.Minutes(1),
  Durations.Instantaneous,
  Ranges.Touch,
  AreaOfEffects.SingleTarget,
  ToHits.AlwaysWorks,
  Effects.Utility(),
  VSM('two lodestones'),
  'Transmutation'
);
