import React from 'react';

function FeatDetails(props) {
  const character = props.character;
  const feats = character.feats
    .concat()
    .sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      } else if (a.name > b.name) {
        return 1;
      } else {
        return 0;
      }
    })
    .map((feat, index) => {
      return <Feat key={'feat' + index} feat={feat} />;
    });
  return (
    <div className="section FeatDetails">
      <div className="title">Feats</div>
      <div className="Feats">{feats}</div>
    </div>
  );
}

function Feat(props) {
  const feat = props.feat;
  const description = feat.description.map((part, index) => {
    return <div key={'part' + index}>{part}</div>;
  });
  return (
    <div className="Feat">
      <div className="Name">{feat.name}</div>
      <div className="Description">{description}</div>
    </div>
  );
}

export default FeatDetails;
