import React from 'react';

import './CharacterSheet.scss';
import BasicDetails from './BasicDetails.js';
import Abilities from './Abilities.js';
import Combat from './Combat.js';
import NonCombat from './NonCombat.js';
import Rest from './Rest.js';
import Inventory from './Inventory.js';
import InventoryDetails from './InventoryDetails.js';
import FeatDetails from './FeatDetails.js';
import Personality from './Personality.js';
import ProficiencyDetails from './ProficiencyDetails.js';
import SpellDetails from './SpellDetails.js';

function CharacterSheet(props) {
  const character = props.character;
  return (
    <div className="CharacterSheet">
      <div className="Grid">
        <BasicDetails character={character} />
        <Abilities character={character} />
        <Combat character={character} />
        <NonCombat character={character} />
        <Rest character={character} />
        <Inventory character={character} />
        {/*
          <div className="CharacterSheet-Inspiration">
            INSPIRATION
          </div>
          */}
      </div>
      {/*
       */}
      <Personality character={character} />
      <SpellDetails character={character} />
      <InventoryDetails character={character} />
      <FeatDetails character={character} />
      <ProficiencyDetails character={character} />
    </div>
  );
}

export default CharacterSheet;
