import React from 'react';
import AbilityType from '../Character/AbilityType.js';
import SkillType from '../Character/SkillType.js';
import Modifier from './Modifier.js';
import Value from './Value.js';

function Abilities(props) {
  const character = props.character;
  const skillsA = [];
  const skillsB = [];
  for (let i = 0; i < SkillType.SORTED_LIST.length; i++) {
    const skill = SkillType.SORTED_LIST[i];
    if (i > SkillType.SORTED_LIST.length / 2 - 1) {
      skillsB.push(<Skill key={skill.name} type={skill} character={character} />);
    } else {
      skillsA.push(<Skill key={skill.name} type={skill} character={character} />);
    }
  }

  return (
    <div className="grid two-column">
      <div className="section Abilities">
        <div className="title">Abilities</div>
        <div className="Proficiency">
          <Modifier value={character.proficiencyBonus} />
        </div>
        <div className="Column">
          <Ability type={AbilityType.STR} character={character} />
          <Ability type={AbilityType.DEX} character={character} />
          <Ability type={AbilityType.CON} character={character} />
        </div>
        <div className="Column">
          <Ability type={AbilityType.INT} character={character} />
          <Ability type={AbilityType.WIS} character={character} />
          <Ability type={AbilityType.CHA} character={character} />
        </div>
      </div>
      <div className="section Skills">
        <div className="title">Skills</div>
        <div className="Proficiency">
          <Modifier value={character.proficiencyBonus} />
        </div>
        <div className="Column">{skillsA}</div>
        <div className="Column">{skillsB}</div>
      </div>
    </div>
  );
}

function Ability(props) {
  const type = props.type;
  const character = props.character;
  return (
    <div className="Ability">
      <AbilityDetailRow
        name={type.name}
        type="AbilityName"
        value={character.abilityScore(type)}
        secondary=""
      />
      <AbilityDetailRow
        name="Modifier"
        type="Modifier"
        modifier={character.abilityModifier(type)}
        secondary=""
      />
      <AbilityDetailRow
        name="Saving Throw"
        type="SavingThrow"
        modifier={character.abilitySavingThrowModifier(type)}
        secondary=""
      />
    </div>
  );
}

function AbilityDetailRow(props) {
  const name = props.name;
  const type = props.type;
  const value = props.value ? <Value value={props.value} /> : <Modifier value={props.modifier} />;
  const highlight = type === 'AbilityName';
  const labelClassName = 'label Name' + (highlight ? ' highlight' : '');
  const secondary = props.secondary;
  return (
    <div className={type}>
      <div className="Detail">
        <span className={labelClassName}>{name}:&nbsp;</span>
        <span className="Modifier">
          {value}
          {secondary}
        </span>
      </div>
    </div>
  );
}

function Skill(props) {
  const type = props.type;
  const character = props.character;
  const modifier = character.skillModifier(type);
  return (
    <div className="Skill">
      <div className="Detail">
        <span className="label">
          <span className="Name">{type.name}</span>{' '}
          <span className="Ability">({type.abilityType.abbreviation})</span>:&nbsp;
        </span>
        <span className="Modifier">
          <Modifier value={modifier} />
        </span>
      </div>
    </div>
  );
}

export default Abilities;
