import React from 'react';

import './CharacterSheet.scss';
import BasicDetails from './BasicDetails.js';
import Abilities from './Abilities.js';
import Combat from './Combat.js';
import NonCombat from './NonCombat.js';
import Rest from './Rest.js';
import Inventory from './Inventory.js';
import InventoryDetails from './InventoryDetails.js';
import FeatDetails from './FeatDetails.js';
import Personality from './Personality.js';
import ProficiencyDetails from './ProficiencyDetails.js';
import SpellDetails from './SpellDetails.js';

function PrintableCharacterSheet(props) {
  const character = props.character;
  const splitItems = props.splitItems;
  return (
    <div className="PrintableCharacterSheet">
      <BasicDetails character={character} />
      <Personality character={character} />
      <Abilities character={character} />
      <Inventory character={character} splitItems={splitItems} />
      <ProficiencyDetails character={character} />
      <div className="section Notes">
        <div className="title">Notes</div>
      </div>
      <div className="break-after"></div>
      <Combat character={character} />
      <div className="section Notes">
        <div className="title">Notes</div>
      </div>
      <div className="break-after"></div>
      <NonCombat character={character} />
      <div className="section Notes">
        <div className="title">Notes</div>
      </div>
      <div className="break-after"></div>
      <Rest character={character} />
      <div className="section Notes">
        <div className="title">Notes</div>
      </div>
      <div className="break-after"></div>
      <SpellDetails character={character} />
      <div className="section Notes">
        <div className="title">Notes</div>
      </div>
      <div className="break-after"></div>
      <InventoryDetails character={character} splitItems={splitItems} />
      <div className="section Notes">
        <div className="title">Notes</div>
      </div>
      <div className="break-after"></div>
      <FeatDetails character={character} />
      <div className="section Notes">
        <div className="title">Notes</div>
      </div>
    </div>
  );
}

export default PrintableCharacterSheet;
