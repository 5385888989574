import React from 'react';

import Value from './Value.js';

const SPLIT_ITEMS = false;

function Inventory(props) {
  const character = props.character;

  const itemsAttuned = [];
  const itemsWorn = [];
  const itemsCarried = [];
  const itemsAll = [];
  character.items
    .concat()
    .sort((a, b) => {
      if (a.item.name < b.item.name) {
        return -1;
      } else if (a.item.name > b.item.name) {
        return 1;
      } else {
        return 0;
      }
    })
    .forEach((item, index) => {
      if (SPLIT_ITEMS) {
        if (item.isAttuned) {
          itemsAttuned.push(<Item key={'attuned' + index} item={item} />);
        }
        if (item.isActive) {
          itemsWorn.push(<Item key={'worn' + index} item={item} />);
        } else {
          itemsCarried.push(<Item key={'carried' + index} item={item} />);
        }
      } else {
        itemsAll.push(<Item key={'all' + index} item={item} />);
      }
    });
  return (
    <div className="section Inventory">
      <div className="title">Inventory</div>
      <div className="Details">
        <div className="Encumberance">
          <span className="secondary CarriedWeight">
            <Value value={character.carriedWeight + ' lbs'} />
          </span>
          <span className="tertiary">
            <Value value={' / ' + character.maxCarriedWeight + ' lbs'} />
          </span>
        </div>
      </div>
      {/* Wealth? CP, SP, EP, GP, PP */}
      {itemsAll.length > 0 && <div className="Equipment All">{itemsAll}</div>}
      {itemsAttuned.length > 0 && (
        <div className="Equipment Attuned">
          <div className="title">Attuned</div>
          {itemsAttuned}
        </div>
      )}
      {itemsWorn.length > 0 && (
        <div className="Equipment Active">
          <div className="title">Active</div>
          {itemsWorn}
        </div>
      )}
      {itemsCarried.length > 0 && (
        <div className="Equipment Carried">
          <div className="title">Carried</div>
          {itemsCarried}
        </div>
      )}
    </div>
  );
}

function Item(props) {
  const item = props.item;
  const className =
    'Item' +
    (item.item.requiresAttunement ? ' RequiresAttunement' : '') +
    (item.isAttuned ? ' Attuned' : '') +
    (item.isActive ? ' Active' : '');
  return (
    <div className={className}>
      <div className="Name">{item.item.name}</div>
      {item.item.count > 1 && <div className="Count">{item.item.count}</div>}
    </div>
  );
}

export default Inventory;
