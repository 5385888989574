import AreaOfEffect from './AreaOfEffect.js';

export default {
  SingleTarget: new AreaOfEffect('Single Target', ''),
  MultipleTargets: new AreaOfEffect('Multiple Targets', ''),
  Line: function(length, width) {
    return new AreaOfEffect(
      'A line ' + length + ' feet long and ' + width + 'feet wide line',
      length + 'x' + width + 'ft line'
    );
  }, // |
  Radius: function(size) {
    return new AreaOfEffect(size + '-foot radius', size + 'ft radius');
  }, // ⊙
  RadiusMiles: function(size) {
    return new AreaOfEffect(size + '-mile radius', size + 'mi radius');
  }, // ⊙
  Cube: function(size) {
    return new AreaOfEffect(size + '-foot cube', size + ' ft cube');
  }, // ⊞
  Square: function(size) {
    return new AreaOfEffect(size + '-foot square', size + 'ft square');
  }, // ⊡
};
