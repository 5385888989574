import React from 'react';

function ProficiencyDetails(props) {
  const character = props.character;
  const proficiencies = character.proficiencies
    .concat()
    .sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      } else if (a.name > b.name) {
        return 1;
      } else {
        return 0;
      }
    })
    .map((proficiency, index) => {
      return (
        <div key={'proficiency' + index} className="Proficiency">
          {proficiency.name}
        </div>
      );
    });
  return (
    <div className="section ProficiencyDetails">
      <div className="title">Proficiencies</div>
      <div className="Proficiencies">{proficiencies}</div>
    </div>
  );
}

export default ProficiencyDetails;
