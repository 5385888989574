import AbilityType from '../AbilityType.js';

export const AttackRollTypes = {
  Spell: 'spell',
  SpellAttackAndDamage: 'spellAttackAndDamage',
  Inspiration: 'inspiration',
  Rage: 'rage',
  UnarmedStrike: 'unarmedStrike',
  Melee: 'melee',
  MeleeDex: 'meleeDex',
  MeleeOrMartialArts: 'meleeOrMartialArts',
  Finesse: 'finesse',
  FinesseOrMartialArts: 'finesseOrMartialArts',
  FinesseOffHand: 'finesseOffHand',
  Thrown: 'thrown',
  ThrownFinesse: 'thrownFinesse',
  Ranged: 'ranged',
  Unmodified: 'unmodified',
};

export const rollTypeToModifier = function(attackRollType, character, isDamage) {
  switch (attackRollType) {
    case AttackRollTypes.Spell:
      return isDamage ? 0 : character.spellAttackModifier;
    case AttackRollTypes.SpellAttackAndDamage:
      return isDamage ? character.spellAbilityModifier : character.spellAttackModifier;
    case AttackRollTypes.Melee:
    case AttackRollTypes.Thrown:
      return character.abilityModifier(AbilityType.STR);
    case AttackRollTypes.Ranged:
    case AttackRollTypes.MeleeDex:
      return character.abilityModifier(AbilityType.DEX);
    case AttackRollTypes.FinesseOffHand:
      if (isDamage && !character.hasTwoWeaponFighting) {
        return 0;
      }
    // eslint-disable-next-line no-fallthrough
    case AttackRollTypes.Finesse:
    case AttackRollTypes.FinesseOrMartialArts:
    case AttackRollTypes.ThrownFinesse:
      return Math.max(
        character.abilityModifier(AbilityType.STR),
        character.abilityModifier(AbilityType.DEX)
      );
    case AttackRollTypes.UnarmedStrike:
    case AttackRollTypes.MeleeOrMartialArts:
      if (character.hasMartialArts) {
        return Math.max(
          character.abilityModifier(AbilityType.STR),
          character.abilityModifier(AbilityType.DEX)
        );
      } else {
        return character.abilityModifier(AbilityType.STR);
      }
    case AttackRollTypes.Rage:
    case AttackRollTypes.Unmodified:
      return 0;
    default:
      throw new Error('Invalid attackRollType');
  }
};
