import Languages from '../Character/Languages.js';
import Races from '../Character/Races.js';

export const LANGUAGES = [Languages.DWARVISH];
export const RACE = Races.HALF_ELF;
export const LAST_NAME = ' Emerton';

export const WA_PREFIX = 'https://www.worldanvil.com/w/teoxia-illandril/a/';
export const WA_SUFFIX = '-emerton-article';

// Don't forget ability score improvements and other class features that have choices
export const LEVEL = 2;
