import AbilityType from './AbilityType.js';
import Feats from './Feats.js';
import Proficiencies from './Proficiencies.js';
import Spells from './Spells/Spells.js';
import Class, { Subclass, mergeFeats } from './Class.js';

const CASTER_SPELL_SLOTS = {
  1: ['∞', 2],
  2: ['∞', 3],
  3: ['∞', 4, 2],
  4: ['∞', 4, 3],
  5: ['∞', 4, 3, 2],
  6: ['∞', 4, 3, 3],
  7: ['∞', 4, 3, 3, 1],
  8: ['∞', 4, 3, 3, 2],
  9: ['∞', 4, 3, 3, 3, 1],
  10: ['∞', 4, 3, 3, 3, 2],
  11: ['∞', 4, 3, 3, 3, 2, 1],
  12: ['∞', 4, 3, 3, 3, 2, 1],
  13: ['∞', 4, 3, 3, 3, 2, 1, 1],
  14: ['∞', 4, 3, 3, 3, 2, 1, 1],
  15: ['∞', 4, 3, 3, 3, 2, 1, 1, 1],
  16: ['∞', 4, 3, 3, 3, 2, 1, 1, 1],
  17: ['∞', 4, 3, 3, 3, 2, 1, 1, 1, 1],
  18: ['∞', 4, 3, 3, 3, 3, 1, 1, 1, 1],
  19: ['∞', 4, 3, 3, 3, 3, 2, 1, 1, 1],
  20: ['∞', 4, 3, 3, 3, 3, 2, 2, 1, 1],
};

const HALF_CASTER_SPELL_SLOTS = (function() {
  var slots = {};
  for (var level = 2; level <= 20; level++) {
    slots[level] = CASTER_SPELL_SLOTS[Math.ceil(level / 2)];
  }
  return slots;
})();

// Eldritch Knight, Arcane Trickster
const ONE_THIRD_CASTER_SPELL_SLOTS = (function() {
  var slots = {};
  for (var level = 3; level <= 20; level++) {
    slots[level] = CASTER_SPELL_SLOTS[Math.ceil(level / 3)];
  }
  return slots;
})();

const PACT_SPELL_SLOTS = {
  1: ['∞', 1],
  2: ['∞', 2],
  3: ['∞', 0, 2],
  4: ['∞', 0, 2],
  5: ['∞', 0, 0, 2],
  6: ['∞', 0, 0, 2],
  7: ['∞', 0, 0, 0, 2],
  8: ['∞', 0, 0, 0, 2],
  9: ['∞', 0, 0, 0, 0, 2],
  10: ['∞', 0, 0, 0, 0, 2],
  11: ['∞', 0, 0, 0, 0, 3],
  12: ['∞', 0, 0, 0, 0, 3],
  13: ['∞', 0, 0, 0, 0, 3],
  14: ['∞', 0, 0, 0, 0, 3],
  15: ['∞', 0, 0, 0, 0, 3],
  16: ['∞', 0, 0, 0, 0, 3],
  17: ['∞', 0, 0, 0, 0, 4],
  18: ['∞', 0, 0, 0, 0, 4],
  19: ['∞', 0, 0, 0, 0, 4],
  20: ['∞', 0, 0, 0, 0, 4],
};

const ONE_THIRD_PACT_SPELL_SLOTS = (function() {
  var slots = {};
  for (var level = 3; level <= 20; level++) {
    slots[level] = PACT_SPELL_SLOTS[Math.ceil(level / 3)];
  }
  return slots;
})();

const HOMEBREW_PACTFIGHTER_NAME = 'Fey-touched Knight';

const HOMEBREW_BARBARIANDRUID_NAME = 'Path of the Raging Druid';

const HOMEBREW_MONKDRUID_NAME = "Nature's Guardian";
// const HOMEBREW_MONKDRUID_SPELL_SLOTS = (function() {
//   // One third, shifted down by one level so they can cast spells earlier
//   var slots = {};
//   for (var level = 2; level <= 20; level++) {
//     slots[level] = CASTER_SPELL_SLOTS[Math.ceil((level + 1) / 3)];
//   }
//   return slots;
// })();

const BARBARIAN = new Class(
  'Barbarian',
  12 /* hitDiceSides */,
  AbilityType.INT /* spell ability... no spells, shouldn't matter */,
  [AbilityType.STR, AbilityType.CON],
  {
    1: [
      Feats.RAGE,
      Feats.UNARMORED_DEFENSE_BARBARIAN,
      Feats.PROFICIENCIES('Barbarian Proficiencies', [
        // Armor
        Proficiencies.LightArmor,
        Proficiencies.MediumArmor,
        Proficiencies.Shield,

        // Weapons
        Proficiencies.SimpleWeapons,
        Proficiencies.MartialWeapons,

        // Tools
        // None
      ]),
      // Choice of 2 skills from Animal Handling, Athletics, Intimidation, Nature, Perception, and Survivial
    ],
    2: [Feats.RECKLESS_ATTACK, Feats.DANGER_SENSE],
    3: [
      // Primal Path (subclass)
    ],
    5: [
      // Extra attack
      // Fast movement
    ],
    6: [
      // Path feature
    ],
    7: [
      // Feral instinct
    ],
    9: [
      // Brutal Critical (1 die)
    ],
    10: [
      // Path feature
    ],
    11: [
      // Relentless rage
    ],
    13: [
      // Brutal critical (2 dice)
    ],
    14: [
      // Path feature
    ],
    15: [
      // Persistent rage
    ],
    17: [
      // Brutal critical (3 dice)
    ],
    18: [
      // Indomitable might
    ],
    20: [
      // Primal champion
    ],
  },
  null /* spell slots */,
  (function() {
    var power = {};
    for (var level = 1; level <= 20; level++) {
      var rage;
      if (level < 3) {
        rage = 2;
      } else if (level < 6) {
        rage = 3;
      } else if (level < 12) {
        rage = 4;
      } else if (level < 17) {
        rage = 5;
      } else if (level < 20) {
        rage = 6;
      } else {
        rage = 999;
      }
      power[level] = {
        rage: rage,
      };
    }
    return power;
  })() /* powerPoints */
);

const BARD = new Class(
  'Bard',
  8 /* hitDiceSides */,
  AbilityType.CHA,
  [AbilityType.DEX, AbilityType.CHA],
  {
    1: [
      Feats.BARDIC_INSPIRATION,
      Feats.BARDIC_SPELLCASTING,
      Feats.PROFICIENCIES('Bardic Proficiencies', [
        // Armor
        Proficiencies.LightArmor,

        // Weapons
        Proficiencies.SimpleWeapons,
        Proficiencies.HandCrossbow,
        Proficiencies.Longsword,
        Proficiencies.Rapier,
        Proficiencies.Shortsword,

        // Tools
        // Three musical instruments of choice
      ]),
      // Choice of any 3 skills
    ],
    2: [Feats.JACK_OF_ALL_TRADES, Feats.SONG_OF_REST],
    3: [
      // Bardic College (subclass)
      // Expertise for 2 skills
    ],
    5: [Feats.FONT_OF_INSPIRATION],
    6: [
      // Countercharm
      // Bard college feature
    ],
    10: [
      // Expertise
      // Magical secrets
    ],
    14: [
      // Magical Secrets
      // Bard college feature
    ],
    18: [
      // Magical secrets
    ],
    20: [
      // Superior inspiration
    ],
  },
  CASTER_SPELL_SLOTS,
  null /* powerPoints */
);

const DRUID = new Class(
  'Druid',
  8 /* hitDiceSides */,
  AbilityType.WIS,
  [AbilityType.INT, AbilityType.WIS],
  {
    1: [
      Feats.DRUIDIC,
      Feats.DRUID_SPELLCASTING,
      Feats.PROFICIENCIES('Druidic Proficiencies', [
        // Armor
        Proficiencies.LightArmor,
        Proficiencies.MediumArmor,
        Proficiencies.Shield,

        // Weapons
        Proficiencies.Club,
        Proficiencies.Dagger,
        Proficiencies.Darts,
        Proficiencies.Javelin,
        Proficiencies.Mace,
        Proficiencies.Quarterstaff,
        Proficiencies.Scimitar,
        Proficiencies.Sickle,
        Proficiencies.Sling,
        Proficiencies.Spear,

        // Tools
        Proficiencies.HerbalismKit,
      ]),
      // Choice of two skills from Arcana, Animal Handling, Insight, Medicine, Nature, Perception, Religion, and Survival
    ],
    2: [Feats.WILD_SHAPE, Feats.WILD_COMPANION],
    18: [Feats.TIMELESS_BODY, Feats.BEAST_SPELLS],
    20: [Feats.ARCHDRUID],
  },
  CASTER_SPELL_SLOTS,
  null /* powerPoints */
);

const FIGHTER = new Class(
  'Fighter',
  10 /* hitDiceSides */,
  AbilityType.INT /* spell ability... no spells, shouldn't matter */,
  [AbilityType.STR, AbilityType.CON],
  {
    1: [
      // Choice of Fighting Style
      Feats.SECOND_WIND,
      Feats.PROFICIENCIES('Barbarian Proficiencies', [
        // Armor
        Proficiencies.AllArmor,
        Proficiencies.Shield,

        // Weapons
        Proficiencies.SimpleWeapons,
        Proficiencies.MartialWeapons,

        // Tools
        // None
      ]),
      // Choice of 2 skills from Acrobatics, Animal Handling, Athletics, History, Insight, Intimidation, Perception, and Survival
    ],
    2: [Feats.ACTION_SURGE],
    3: [
      // Martial Archetype (subclass)
    ],
    4: [
      // ASI
    ],
    5: [
      // Extra attack
    ],
    6: [
      // ASI
    ],
    7: [
      // Martial Archetype feature
    ],
    8: [
      // ASI
    ],
    9: [
      // Indomitable
    ],
    10: [
      // Martial Archetype feature
    ],
    11: [
      // Extra Attack x2
    ],
    12: [
      // ASI
    ],
    13: [
      // Indomitable (second use)
    ],
    14: [
      // ASI
    ],
    15: [
      // Martial Archetype feature
    ],
    16: [
      // ASI
    ],
    17: [
      // Action Surge (second use), Indomitable (third use)
    ],
    18: [
      // Martial Archetype feature
    ],
    19: [
      // ASI
    ],
    20: [
      // Extra Attack (third use)
    ],
  },
  null /* spell slots */,
  null /* powerPoints */
);

const RANGER = new Class(
  'Ranger',
  10 /* hitDiceSides */,
  AbilityType.WIS,
  [AbilityType.STR, AbilityType.DEX],
  {
    // TODO: Feats
    1: [
      Feats.PROFICIENCIES('Ranger Proficiencies', [
        // Armor
        Proficiencies.LightArmor,
        Proficiencies.MediumArmor,
        Proficiencies.Shield,

        // Weapons
        Proficiencies.SimpleWeapons,
        Proficiencies.MartialWeapons,

        // Tools
        // None
      ]),
      // Skills: Choose three from Animal Handling, Athletics, Insight, Investigation, Nature, Perception, Stealth, and Survival
      // Favored Enemy choice
      // Natural Explorer choice
    ],
    2: [
      // Fighting Style choice
      Feats.RANGER_SPELLCASTING,
    ],
    3: [
      // Ranger Archetype
      Feats.PRIMEVAL_AWARENESS,
    ],
    5: [Feats.EXTRA_ATTACK],
    6: [
      // Favored Enemy +1
      // Natural Explorer +1
    ],
    7: [
      // Ranger Archetype feature
    ],
    8: [Feats.LANDS_STRIDE],
    10: [
      // Natural Explorer +1
      // Hide in Plain Sight
    ],
    11: [
      // Ranger Archetype Feature
    ],
    14: [
      // Favored Enemy +1
      // Vanish
    ],
    15: [
      // Ranger Archetype Feature
    ],
    18: [
      // Feral Senses
    ],
    20: [
      // Foe Slayer
    ],
  },
  HALF_CASTER_SPELL_SLOTS,
  null /* powerPoints */
);

const WIZARD = new Class(
  'Wizard',
  6 /* hitDiceSides */,
  AbilityType.INT,
  [AbilityType.INT, AbilityType.WIS],
  {
    1: [
      Feats.PROFICIENCIES('Wizard Proficiencies', [
        // Armor
        // None

        // Weapons
        Proficiencies.Dagger,
        Proficiencies.Darts,
        Proficiencies.Sling,
        Proficiencies.Quarterstaff,
        Proficiencies.LightCrossbow,

        // Tools
        // None
      ]),
      // Skills: Choose two from Arcana, History, Insight, Investigation, Medicine, and Religion
      Feats.WIZARD_SPELLCASTING,
      Feats.ARCANE_RECOVERY,
    ],
    2: [
      // Arcane Tradition
    ],
    6: [
      // Arcane Tradition Feature
    ],
    10: [
      // Arcane Tradition Feature
    ],
    14: [
      // Arcane Tradition Feature
    ],
    18: [
      // Spell Mastery
    ],
    20: [
      // Signature Spells
    ],
  },
  CASTER_SPELL_SLOTS,
  null /* powerPoints */
);

const SORCERER = new Class(
  'Sorcerer',
  6 /* hitDiceSides */,
  AbilityType.CHA,
  [AbilityType.CON, AbilityType.CHA],
  {
    1: [
      Feats.PROFICIENCIES('Sorcerer Proficiencies', [
        // Armor
        // None

        // Weapons
        Proficiencies.Dagger,
        Proficiencies.Darts,
        Proficiencies.Sling,
        Proficiencies.Quarterstaff,
        Proficiencies.LightCrossbow,

        // Tools
        // None
      ]),
      // Skills: Choose two from Arcana, Deception, Insight, Intimidation, Persuasion, and Religion
      Feats.SORCERER_SPELLCASTING,
      // Sorcerous Origin
    ],
    2: [
      // Font of Magic
    ],
    3: [
      // Metamagic
    ],
    6: [
      // Sorcerous Origin Feature
    ],
    10: [
      // Metamagic Improvement
    ],
    14: [
      // Sorcerous Origin Feature
    ],
    17: [
      // Metamagic Improvement
    ],
    18: [
      // Sorcerous Origin Feature
    ],
    20: [
      // Sorcerous Restoration
    ],
  },
  CASTER_SPELL_SLOTS,
  (function() {
    var power = {};
    for (var level = 2; level <= 20; level++) {
      power[level] = {
        sorcery: level,
      };
    }
    return power;
  })() /* powerPoints */
);

const CIRCLE_OF_THE_LAND_FEATS = {
  2: [
    // Bonus Cantrip
    Feats.NATURAL_RECOVERY,
  ],
  3: [Feats.CIRCLE_SPELLS],
  6: [Feats.LANDS_STRIDE],
  10: [Feats.NATURES_WARD],
  14: [Feats.NATURES_SANCTUARY],
};

const Classes = {
  // --------------
  // Barbarian
  HOMEBREW_BARBARIANDRUID: new Subclass(
    HOMEBREW_BARBARIANDRUID_NAME,
    BARBARIAN,
    {
      3: [
        // Spellcasting
      ],
      6: [
        // Enlarge when raging
      ],
      10: [
        // TBD
      ],
      14: [
        // TBD
      ],
    },
    AbilityType.WIS,
    ONE_THIRD_CASTER_SPELL_SLOTS
  ),

  // --------------
  // Bard
  COLLEGE_OF_TBD: new Subclass('College of (TBD)', BARD, {}),

  COLLEGE_OF_LORE: new Subclass('College of Lore', BARD, {
    // TODO: Add feats
  }),

  COLLEGE_OF_VALOR: new Subclass('College of Lore', BARD, {
    // TODO: Add feats
  }),

  // --------------
  // Druid
  CIRCLE_OF_THE_MOON: new Subclass('Circle of the Moon', DRUID, {
    2: [Feats.COMBAT_WILD_SHAPE, Feats.CIRCLE_FORMS],
    6: [Feats.PRIMAL_STRIKE],
    10: [Feats.ELEMENTAL_WILD_SHAPE],
    14: [Feats.THOUSAND_FORMS],
  }),

  CIRCLE_OF_THE_SHEPHERD: new Subclass('Circle of the Shepherd', DRUID, {
    2: [Feats.SPEECH_OF_THE_WOODS, Feats.SPIRIT_TOTEM],
    //    6: [Feats.MIGHTY_SUMMONER],
    //    10: [Feats.GUARDIAN_SPIRIT],
    //    14: [Feats.FAITHFUL_SUMMONS],
  }),

  CIRCLE_OF_THE_LAND_TBD: new Subclass('Circle of the Land (TBD)', DRUID, CIRCLE_OF_THE_LAND_FEATS),

  CIRCLE_OF_THE_LAND_ARCTIC: new Subclass(
    'Circle of the Land (Arctic)',
    DRUID,
    mergeFeats(CIRCLE_OF_THE_LAND_FEATS, {
      3: [
        Feats.SPELLS('3rd Level Circle Spells', [
          //     '3rd: hold person, spike growth',
        ]),
      ],
      5: [
        Feats.SPELLS('5th Level Circle Spells', [
          // '5rd: sleet storm, slow',
        ]),
      ],
      7: [
        Feats.SPELLS('7th Level Circle Spells', [
          // '7rd: freedom of movement, ice storm',
        ]),
      ],
      9: [
        Feats.SPELLS('9th Level Circle Spells', [
          // '9rd: commune with nature, cone of cold',
        ]),
      ],
    })
  ),

  CIRCLE_OF_THE_LAND_COAST: new Subclass(
    'Circle of the Land (Coast)',
    DRUID,
    mergeFeats(CIRCLE_OF_THE_LAND_FEATS, {
      3: [
        Feats.SPELLS('3rd Level Circle Spells', [
          //     '3rd: mirror image, misty step',
        ]),
      ],
      5: [
        Feats.SPELLS('5th Level Circle Spells', [
          //     '5rd: water breathing, water walk',
        ]),
      ],
      7: [
        Feats.SPELLS('7th Level Circle Spells', [
          //     '7rd: control water, freedom of movement',
        ]),
      ],
      9: [
        Feats.SPELLS('9th Level Circle Spells', [
          //     '9rd: conjure elemental, scrying',
        ]),
      ],
    })
  ),

  // Desert
  // Druid Level	Spells
  // 3rd
  // blur, silence
  // 5th
  // create food and water, protection from energy
  // 7th
  // blight, hallucinatory terrain
  // 9th
  // insect plague, wall of stone
  // Forest
  // Druid Level	Spells
  // 3rd
  // barkskin, spider climb
  // 5th
  // call lightning, plant growth
  // 7th
  // divination, freedom of movement
  // 9th
  // commune with nature, tree stride

  CIRCLE_OF_THE_LAND_GRASSLAND: new Subclass(
    'Circle of the Land (Grassland)',
    DRUID,
    mergeFeats(CIRCLE_OF_THE_LAND_FEATS, {
      3: [
        Feats.SPELLS('3rd Level Circle Spells', [Spells.INVISIBILITY, Spells.PASS_WITHOUT_TRACE]),
      ],
      5: [
        Feats.SPELLS('5th Level Circle Spells', [
          //     '5rd: daylight, haste',
        ]),
      ],
      7: [
        Feats.SPELLS('7th Level Circle Spells', [
          //     '7rd: divination, freedom of movement',
        ]),
      ],
      9: [
        Feats.SPELLS('9th Level Circle Spells', [
          //     '9rd: dream, insect plague',
        ]),
      ],
    })
  ),

  // Mountain
  // Druid Level	Spells
  // 3rd
  // spider climb, spike growth
  // 5th
  // lightning bolt, meld into stone
  // 7th
  // stone shape, stoneskin
  // 9th
  // passwall, wall of stone
  // Swamp
  // Druid Level	Spells
  // 3rd
  // darkness, acid arrow
  // 5th
  // water walk, stinking cloud
  // 7th
  // freedom of movement, locate creature
  // 9th
  // insect plague, scrying
  // Underdark
  // Druid Level	Spells
  // 3rd
  // spider climb, web
  // 5th
  // gaseous form, stinking cloud
  // 7th
  // greater invisibility, stone shape
  // 9th
  // cloudkill, insect plague

  // --------------
  // Fighter
  HOMEBREW_PACTFIGHTER: new Subclass(
    HOMEBREW_PACTFIGHTER_NAME,
    FIGHTER,
    {
      // TODO: Feats
      3: [Feats.HOMEBREW_PACTFIGHTER_SPELLCASTING, Feats.WEAPON_BOND],
      7: [
        // TODO
      ],
      10: [
        // TODO
      ],
      15: [
        // TODO
      ],
      18: [
        // TODO
      ],
    },
    AbilityType.WIS,
    ONE_THIRD_PACT_SPELL_SLOTS
  ),

  // --------------
  // Ranger
  BEAST_MASTER: new Subclass('Beast Master', RANGER, {
    // TODO: Feats
    3: [Feats.RANGERS_COMPANION],
    7: [Feats.BEAST_EXCEPTIONAL_TRAINING],
    11: [
      // Beastial Fury
    ],
    15: [
      // Share Spells
    ],
  }),

  HUNTER: new Subclass('Hunter', RANGER, {
    // TODO: Feats
    3: [
      // Ranger Archetype
    ],
    7: [
      // Ranger Archetype feature
    ],
    11: [
      // Ranger Archetype Feature
    ],
    15: [
      // Ranger Archetype Feature
    ],
  }),

  // --------------
  // Sorcerer
  SORCERER_SHADOW_MAGIC: new Subclass('Shadow Magic', SORCERER, {
    1: [
      // Eyes of the Dark
      // Strength of the Grave
      // Shadow Sorcerer Quirk choice
    ],
    3: [
      // Eyes of the Dark enhancement
    ],
    6: [
      // Hound of Ill Omen
    ],
    14: [
      // Shadow Walk
    ],
    18: [
      // Umbral Form
    ],
  }),

  // --------------
  // Wizard
  SCHOOL_OF_ABJURATION: new Subclass('School of Abjuration', WIZARD, {
    // TODO: School feats
    2: [],
    6: [],
    10: [],
    14: [],
  }),

  SCHOOL_OF_CONJURATION: new Subclass('School of Conjuration', WIZARD, {
    // TODO: School feats
    2: [],
    6: [],
    10: [],
    14: [],
  }),

  SCHOOL_OF_DIVINATION: new Subclass('School of Divination', WIZARD, {
    // TODO: School feats
    2: [],
    6: [],
    10: [],
    14: [],
  }),

  SCHOOL_OF_ENCHANTMENT: new Subclass('School of Enchantment', WIZARD, {
    // TODO: School feats
    2: [],
    6: [],
    10: [],
    14: [],
  }),

  SCHOOL_OF_EVOCATION: new Subclass('School of Evocation', WIZARD, {
    // TODO: School feats
    2: [],
    6: [],
    10: [],
    14: [],
  }),

  SCHOOL_OF_ILLUSION: new Subclass('School of Illusion', WIZARD, {
    // TODO: School feats
    2: [],
    6: [],
    10: [],
    14: [],
  }),

  SCHOOL_OF_NECROMANCY: new Subclass('School of Necromancy', WIZARD, {
    // TODO: School feats
    2: [],
    6: [],
    10: [],
    14: [],
  }),

  SCHOOL_OF_TRANSMUTATION: new Subclass('School of Transmutation', WIZARD, {
    // TODO: School feats
    2: [Feats.TRANSMUTATION_SAVANT, Feats.MINOR_ALCHEMY],
    6: [Feats.TRANSMUTERS_STONE],
    10: [
      // Shapechanger
    ],
    14: [
      // Master Transmuter
    ],
  }),

  // --------------
  // Homebrew classes
  HOMEBREW_MONKDRUID: new Class(
    HOMEBREW_MONKDRUID_NAME,
    8 /* hitDiceSides */,
    AbilityType.WIS,
    [AbilityType.DEX, AbilityType.WIS],
    {
      // TODO: Add feats
      1: [
        Feats.HOMEBREW_MONKDRUID_MARTIAL_ARTS,
        Feats.UNARMORED_DEFENSE_MONK,
        Feats.PROFICIENCIES(HOMEBREW_MONKDRUID_NAME + ' Proficiencies', [
          // Armor
          // None

          // Weapons
          Proficiencies.SimpleWeapons,
          Proficiencies.Shortsword,

          // Tools
          // Choice of one artisan's tool or musical instrument
        ]),
        // Skills: Choose two from Acrobatics, Athletics, History, Insight, Religion, Stealth
      ],
      2: [Feats.KI, Feats.UNARMORED_MOVEMENT],
      3: [
        // Monastic Tradition - Shadow Arts
        Feats.HOMEBREW_MONKDRUID_SPELLCASTING,
        Feats.SPELLS(HOMEBREW_MONKDRUID_NAME + ' Level 3 Spells', [
          Spells.DRUIDCRAFT,
          Spells.THORN_WHIP,
          Spells.ENTANGLE,
          //Spells.ENSNARING_STRIKE,
        ]),
        // Deflect Missiles
        // Feats.SPELLS('Shadow Arts', [
        //   Spells.KI_DARKNESS,
        //   Spells.KI_DARKVISION,
        //   Spells.KI_PASS_WITHOUT_TRACE,
        //   Spells.KI_SILENCE,
        // ]),
      ],
      4: [
        // Slow Fall
      ],
      5: [
        // Extra Attack
        // Stunning Strike
      ],
      6: [
        // Ki-Empowered Strikes
        Feats.SPELLS(HOMEBREW_MONKDRUID_NAME + ' Level 6 Spells', [
          // Barskin
          // Locate plants
          // Spike growth
          // misty step? pass without trace?
        ]),
      ],
      7: [
        // Evasion
        // Stillness of Mind
      ],
      9: [Feats.UNARMORED_MOVEMENT_BONUS],
      10: [
        // Purity of Body
      ],
      11: [
        Feats.SPELLS(HOMEBREW_MONKDRUID_NAME + ' Level 11 Spells', [
          // Plant growth
          // Speak with plants
        ]),
      ],
      13: [
        // Tongue of the Sun and Moon
      ],
      14: [
        // Diamond Soul
      ],
      15: [
        // Timeless Body
      ],
      17: [
        Feats.SPELLS(HOMEBREW_MONKDRUID_NAME + ' Level 17 Spells', [
          // Grasping Vine
          // Guardian of Nature ( great tree only)
          // Polymorph (self only, plant types only)
        ]),
      ],
      18: [
        // Empty Body
      ],
      20: [
        // Perfect Self
      ],
    },
    (function() {
      var power = {};
      for (var level = 1; level <= 20; level++) {
        power[level] = {
          ki: level, // Math.floor(level / 2),
        };
      }
      return power;
    })()
  ),

  // --------------
  // Non-Adventurer NPC classes
  NPC: new Class(
    'NPC',
    8 /* hitDiceSides */,
    AbilityType.INT,
    [],
    {
      // Feats
    },
    null /* spell slots */,
    null /* power points */
  ),
};

export default Classes;
