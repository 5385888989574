import { S } from '../Components.js';
import PointType from '../PointType.js';
import Spell from '../Spell.js';

import AreaOfEffects from '../../Actions/AreaOfEffects.js';
import Durations from '../../Actions/Durations.js';
import Effects from '../../Actions/Effects.js';
import Ranges from '../../Actions/Ranges.js';
import Times from '../../Actions/Times.js';
import ToHits from '../../Actions/ToHits.js';
import Utilities from '../../Actions/Utilities.js';

import AbilityType from '../../AbilityType.js';
import { DamageTypes } from '../../DamageType.js';
import Roll from '../../Roll.js';

export default new Spell(
  'Thunderclap',
  0,
  PointType.SpellSlot,
  [
    'You create a burst of thunderous sound, which can be heard 100 feet away. Each creature other than you within 5 feet of you must make a Constitution saving throw. On a failed save, the creature takes 1d6 thunder damage.',
    'The spell’s damage increases by 1d6 when you reach 5th level (2d6), 11th level (3d6), and 17th level (4d6).',
  ],
  Utilities.Combat,
  Times.Action,
  Durations.Instantaneous,
  Ranges.Self,
  AreaOfEffects.Radius(5),
  ToHits.SpellSave(AbilityType.CON),
  Effects.SpellDamageByCasterLevel(Roll.d(6), DamageTypes.Thunder, {
    5: Roll.d(6),
    11: Roll.d(6),
    17: Roll.d(6),
  }),
  S,
  'Evocation'
);
