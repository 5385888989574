import assert from '../../Common/Assert.js';
import { UnfoldArray } from '../../Common/ArrayUtilities.js';

import CharacterEnhancement, { EnhancementTypes } from '../CharacterEnhancement.js';

import AreaOfEffect from './AreaOfEffect.js';
import Durations from './Durations.js';
import Effect from './Effect.js';
import Range from './Range.js';
import Requirement from './Requirement.js';
import Time from './Time.js';
import ToHit from './ToHit.js';
import Type from './Type.js';
import Utilities from './Utilities.js';

export default class Action extends CharacterEnhancement {
  constructor(
    name,
    type,
    sortScore,
    description,
    utilities,
    time,
    duration,
    range,
    areaOfEffect,
    toHit,
    effect,
    _requires
  ) {
    super(EnhancementTypes.Action);
    assert(name != null, 'Action missing name');
    assert(type != null && type instanceof Type, 'Action missing type');
    assert(description != null, 'Action missing description');
    assert(utilities != null, 'Action missing utilities');
    assert(time != null && time instanceof Time, 'Action missing time');
    assert(duration != null && duration instanceof Time, 'Action missing duration');
    assert(range != null && range instanceof Range, 'Action missing range');
    assert(
      areaOfEffect != null && areaOfEffect instanceof AreaOfEffect,
      'Action missing areaOfEffect'
    );
    assert(toHit != null && toHit instanceof ToHit, 'Action missing toHit');
    assert(effect != null && effect instanceof Effect, 'Action missing effect');
    const requires = UnfoldArray(_requires, Requirement);
    this._name = name;
    this._type = type;
    this._sortScore = sortScore;
    var descriptionPlusRequires = [];
    if (requires !== null) {
      descriptionPlusRequires.push('Requires: ' + requires.map((req) => req.name).join(', '));
    }
    this._description = descriptionPlusRequires.concat(description);
    this._utilities = [].concat(utilities);
    this._time = time;
    this._duration = duration;
    this._range = range;
    this._areaOfEffect = areaOfEffect;
    this._toHit = toHit;
    this._effect = effect;
    this._requires = requires;
  }

  get name() {
    return this._name;
  }

  get type() {
    return this._type;
  }

  get description() {
    return this._description.join('\n');
  }

  get isCombat() {
    return this._utilities.indexOf(Utilities.Combat) > -1;
  }

  get isNonCombat() {
    return this._utilities.indexOf(Utilities.NonCombat) > -1;
  }

  get isRest() {
    return this._utilities.indexOf(Utilities.Rest) > -1;
  }

  get time() {
    return this._time;
  }

  get duration() {
    return this._duration;
  }

  get range() {
    return this._range;
  }

  get areaOfEffect() {
    return this._areaOfEffect;
  }

  get toHit() {
    return this._toHit;
  }

  get effect() {
    return this._effect;
  }

  get requires() {
    return this._requires;
  }

  get requiresShort() {
    return this._requires ? this._requires.map((requires) => requires.shortName).join(',') : '';
  }

  get isNonInstantaneous() {
    return this._duration && this._duration !== Durations.Instantaneous;
  }

  get sortScore() {
    return this._sortScore;
  }
}
