import React from 'react';

import { USE_SPELL_POINTS } from '../Settings.js';

import Actions from './Actions.js';
import SpellSlots from './SpellSlots.js';

function SpellDetails(props) {
  const character = props.character;
  const hasConcentration = props.character.spells.some((spell) => spell.concentration);
  const hasRitual = props.character.spells.some((spell) => spell.ritual);
  const anySpellSlotsOrPoints = USE_SPELL_POINTS ? 'any spell points' : 'a spell slot';
  return (
    <div className="section SpellDetails">
      <div className="title">Power &amp; Spell Details</div>
      <SpellSlots character={character} includeDetails={true} />
      {(hasConcentration || hasRitual) && (
        <div className="General">
          {hasConcentration && (
            <div className="Detail">
              <b>Concentration Spells:</b> Some spells require you to maintain concentration in
              order to keep their magic active. If you lose concentration, such a spell ends.
              Concentration can be lost by casting another spell that requires concentration, taking
              damage, being knocked unconcious, or simply deciding to stop concentrating.
            </div>
          )}
          {hasRitual && (
            <div className="Detail">
              <b>Ritual Spells:</b> Some spells can be cast as a ritual instead of as a normal
              spell. Ritual casting takes an additional 10 minutes, but does not expend{' '}
              {anySpellSlotsOrPoints}.
            </div>
          )}
        </div>
      )}
      <Actions
        character={character}
        includeCombat={true}
        includeNonCombat={true}
        includeRest={false}
        includeDetails={true}
      />
    </div>
  );
}

export default SpellDetails;
