import React from 'react';

import Value from './Value.js';
import Feats from '../Character/Feats.js';

function SpellSlots(props) {
  const character = props.character;
  const spellSlots = character.spellSlots;
  var slotValues = [];
  if (spellSlots) {
    for (var level = 1; level <= 9; level++) {
      if (spellSlots[level]) {
        slotValues.push(
          <span className={'Spells-SpellSlots-Level L' + level}>
            <Value value={spellSlots[level] || 0} />
          </span>
        );
      }
    }
  }
  const powerPoints = character.powerPoints;
  const includeDetails = props.includeDetails;
  const inspirationShortRest = character.hasFeat(Feats.FONT_OF_INSPIRATION);
  return (
    <div className={'Spells-SpellSlotsAndPoints' + (includeDetails ? ' Detailed' : '')}>
      <Points
        label="Spell Points (SP)"
        points={powerPoints && powerPoints.spell}
        shortRestRecovery={false}
        includeDetails={includeDetails}
      />
      <Points
        label="Sorcery Points"
        points={powerPoints && powerPoints.sorcery}
        shortRestRecovery={false}
        includeDetails={includeDetails}
      />
      <Points
        label="Inspiration Uses"
        points={powerPoints && powerPoints.inspiration}
        shortRestRecovery={inspirationShortRest}
        includeDetails={includeDetails}
      />
      <Points
        label="Wild Shape Uses"
        points={powerPoints && powerPoints.wildShape}
        shortRestRecovery={true}
        includeDetails={includeDetails}
      />
      <Points
        label="Rage Uses"
        points={powerPoints && powerPoints.rage}
        shortRestRecovery={false}
        includeDetails={includeDetails}
      />
      <Points
        label="Ki Points"
        points={powerPoints && powerPoints.ki}
        shortRestRecovery={true}
        includeDetails={includeDetails}
      />
      {spellSlots && (
        <div className="Spells-SpellSlots Spells-SpellLevels">
          {slotValues}
          {includeDetails && (
            <div className="details">
              (slots recover after a {character.isPactCaster ? 'short' : 'long'} rest)
            </div>
          )}
        </div>
      )}
    </div>
  );
}

function Points(props) {
  const label = props.label;
  const points = props.points;
  const shortRestRecovery = props.shortRestRecovery;
  var includeDetails = props.includeDetails;
  var show = false;
  if (typeof points === 'number') {
    show = points > 0;
  } else if (typeof points === 'string') {
    show = true;
    includeDetails = false;
  }
  if (!show) {
    return null;
  }
  return (
    <div className="Spells-SpellSlots">
      <span className="label">Max {label}: </span>
      <Value value={points} />
      {includeDetails && (
        <div className="details">
          (recovers after a {shortRestRecovery ? 'short or long' : 'long'} rest)
        </div>
      )}
    </div>
  );
}

export default SpellSlots;
