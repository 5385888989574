import AbilityType from './AbilityType.js';
import CharacterEnhancement, { EnhancementTypes } from './CharacterEnhancement.js';

class SkillType extends CharacterEnhancement {
  constructor(name, abbreviation, ability) {
    super(EnhancementTypes.Skill);
    this._name = name;
    this._abbreviation = abbreviation;
    this._ability = ability;
    ability.addSkillType(this);
    SkillType.LIST.push(this);
  }
  get name() {
    return this._name;
  }
  get abbreviation() {
    return this._abbreviation;
  }
  get abilityType() {
    return this._ability;
  }
}

SkillType.LIST = [];
SkillType.ATHLETICS = new SkillType('Athletics', 'ATH', AbilityType.STR);

SkillType.ACROBATICS = new SkillType('Acrobatics', 'ACRO', AbilityType.DEX);
SkillType.SLEIGHT_OF_HAND = new SkillType('Sleight of Hand', 'SoH', AbilityType.DEX);
SkillType.STEALTH = new SkillType('Stealth', 'STLTH', AbilityType.DEX);

SkillType.ARCANA = new SkillType('Arcana', 'ARC', AbilityType.INT);
SkillType.HISTORY = new SkillType('History', 'HIS', AbilityType.INT);
SkillType.INVESTIGATION = new SkillType('Investigation', 'INV', AbilityType.INT);
SkillType.NATURE = new SkillType('Nature', 'NAT', AbilityType.INT);
SkillType.RELIGION = new SkillType('Religion', 'REL', AbilityType.INT);

SkillType.ANIMAL_HANDLING = new SkillType('Animal Handling', 'AH', AbilityType.WIS);
SkillType.INSIGHT = new SkillType('Insight', 'INS', AbilityType.WIS);
SkillType.MEDICINE = new SkillType('Medicine', 'MED', AbilityType.WIS);
SkillType.PERCEPTION = new SkillType('Perception', 'PERC', AbilityType.WIS);
SkillType.SURVIVAL = new SkillType('Survival', 'SURV', AbilityType.WIS);

SkillType.DECEPTION = new SkillType('Deception', 'DEC', AbilityType.CHA);
SkillType.INTIMIDATION = new SkillType('Intimidation', 'INTIM', AbilityType.CHA);
SkillType.PERFORMANCE = new SkillType('Performance', 'PERF', AbilityType.CHA);
SkillType.PERSUASION = new SkillType('Persuasion', 'PERS', AbilityType.CHA);

SkillType.SORTED_LIST = SkillType.LIST.slice(0);
SkillType.SORTED_LIST.sort((a, b) => {
  var nameA = a.name.toUpperCase();
  var nameB = b.name.toUpperCase();
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
});

export default SkillType;
