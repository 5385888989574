import { VM } from '../Components.js';
import PointType from '../PointType.js';
import Spell from '../Spell.js';

import AreaOfEffects from '../../Actions/AreaOfEffects.js';
import Durations from '../../Actions/Durations.js';
import Effects from '../../Actions/Effects.js';
import Ranges from '../../Actions/Ranges.js';
import Times from '../../Actions/Times.js';
import ToHits from '../../Actions/ToHits.js';
import Utilities from '../../Actions/Utilities.js';

import AbilityType from '../../AbilityType.js';

export default new Spell(
  'Light',
  0,
  PointType.SpellSlot,
  [
    'You touch one object that is no larger than 10 feet in any dimension. Until the spell ends, the object sheds bright light in a 20-foot radius and dim light for an additional 20 feet. The light can be colored as you like. Completely covering the object with something opaque blocks the light. The spell ends if you cast it again or dismiss it as an action.',
    'If you target an object held or worn by a hostile creature, that creature must succeed on a Dexterity saving throw to avoid the spell.',
  ],
  Utilities.NonCombat,
  Times.Action,
  Durations.Hours(1),
  Ranges.Touch,
  AreaOfEffects.Radius(20),
  ToHits.SpellSave(AbilityType.DEX, true),
  Effects.Create(true),
  VM('a firefly or phosphorescent moss'),
  'Evocation'
);
