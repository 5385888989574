import AbilityType from '../Character/AbilityType.js';
import CarriedItem from '../Character/CarriedItem.js';
import Character from '../Character/Character.js';
import Classes from '../Character/Classes.js';
import Feats from '../Character/Feats.js';
import Items from '../Character/Items.js';
import Proficiencies from '../Character/Proficiencies.js';
import SkillType from '../Character/SkillType.js';
import Spells from '../Character/Spells/Spells.js';

import CrystalPortrait from '../Portraits/CrystalPortrait.png';

import { LANGUAGES, RACE, LAST_NAME, WA_PREFIX, WA_SUFFIX, LEVEL } from './Common.js';

// prettier-ignore
const crystal = new Character('crystal',
    'Tii\'la' + LAST_NAME, CrystalPortrait, // Middle name: Estelle
    22, '5\' 1"', '108 lbs', // AGE HEIGHT WEIGHT
    'Brown, Long, Braided', 'Violet', 'Fairly light', 'Claw scar on R forearm; Plant tattoo on L forearm; "Skinny man" tattoo on L bicep', //	HAIR EYES SKIN MARKS
    WA_PREFIX + 'tiila' + WA_SUFFIX,
    [
        'Tougher than she looks. Holds a warrior stance and stern look when "on duty", but acts much less tough (but not to the point of seeming frail) when "off duty".',
        'Got scar from attempting to tame a young panther as a pet when she was 12.',
        'Encounter with panther made her recognize how dangerous the world can be, and inspired her desire to become stronger.',
        'Self-taught through books and practice on her own.',
        'Wears an outfit of wrapped cloth that can easily be adjusted for different situations and weather.',
        'The plant tattoo is black and white. When casting spells, the tatoo grows in both directions and sprouts leaves and red roses. When raging, the tatoo sprouts thorns. The tatoo returns to normal after casting is complete and when the rage subsides.',
        'Her staff can be split into two pieces.',
    ], LANGUAGES,
    RACE, Classes.HOMEBREW_PACTFIGHTER, LEVEL,
    13, 14, 12, 10, 14, 10, // STR DEX CON INT WIS CHA
    [
        // Background Feat
        Feats.HOMEBREW_CITY_SECRETS_ALT_C,

        // Early Tattoo Benefits
        Feats.HOMEBREW_TIILA_TRAINING_AC,

        // Half Elf choices
        Feats.ABILITY_SCORE_INCREASE(AbilityType.STR, 1),
        Feats.ABILITY_SCORE_INCREASE(AbilityType.WIS, 1),

        // Replace Half Elf CHA+2 with DEX+2
        Feats.ABILITY_SCORE_INCREASE(AbilityType.DEX, 2),
        Feats.ABILITY_SCORE_INCREASE(AbilityType.CHA, -2),

        // Fighter choices
        Feats.FIGHTING_STYLE_TWO_WEAPON_FIGHTING,

        // Other Feats
    ], [
        // Half Elf choices
        SkillType.INSIGHT,
        SkillType.PERCEPTION,

        // Fighter Choices
        SkillType.ATHLETICS,
        SkillType.INTIMIDATION,

        // Background Choices
        SkillType.PERFORMANCE,
        SkillType.STEALTH,
    ], [
        // Extra choice
        Proficiencies.Drum,

        // Background Choices
        Proficiencies.HerbalismKit,
        Proficiencies.WoodcarversTools,
    ], [
        // Prepared Spells
        // None yet
        // Spells.MAGE_HAND,
        // Spells.ELDRITCH_BLAST,
    ], [
        Items.Backpack,
        Items.Bedroll,
        Items.ClothesCommon(5),
        Items.Costume(1),
        new CarriedItem(Items.DruidicFocus('Plant Tattoo'), true /* isActive */ ),
        Items.SteelMirror,
        Items.MakeupKit,
        Items.HerbalismKit,
        Items.Bongos,
        Items.MessKit,
        new CarriedItem(Items.PotionOfHealing(2), true /* isActive */ ),
        Items.Pouch,
        Items.Rations(5),
        Items.Rope50(1),
        new CarriedItem(Items.HookedStaff(), true /* isActive */ ),
        new CarriedItem(Items.Sling(), true /* isActive */ ),
        new CarriedItem(Items.SlingBullet(20), true /* isActive */ ),
        Items.Tinderbox,
        Items.Torch(2),
        Items.Waterskin,
    ]);
crystal.expertiseSkills(SkillType.PERCEPTION);
export default crystal;
