import SaveDCType from './SaveDCType.js';

export default class SaveType {
  constructor(saveDCType, saveAbilityOrSkill) {
    this._saveDCType = saveDCType;
    this._saveAbilityOrSkill = saveAbilityOrSkill;
  }

  get saveDCType() {
    console.log(this._saveDCType);
    return this._saveDCType;
  }

  saveDCValue(character) {
    if (this._saveDCType === SaveDCType.Spell) {
      return character.spellSaveDC;
    } else if (this._saveDCType === SaveDCType.Contest) {
      return 'Contest';
    } else {
      return -1;
    }
  }

  get saveAbilityOrSkill() {
    return this._saveAbilityOrSkill;
  }

  static SpellSave(abilityTypeOrSkill) {
    return new SaveType(SaveDCType.Spell, abilityTypeOrSkill);
  }

  static Contest(abilityTypeOrSkill) {
    return new SaveType(SaveDCType.Contest, abilityTypeOrSkill);
  }
}
