import assert from '../../Common/Assert.js';
import AbilityType from '../AbilityType.js';
import { AttackRollTypes } from './AttackRollType.js';
import Proficiencies from '../Proficiencies.js';
import SkillType from '../SkillType.js';

import ToHit from './ToHit.js';
import SaveType from './SaveType.js';

export default {
  AlwaysWorks: new ToHit(
    null /* attackRollType */,
    null /* attackRollBonus */,
    null /* proficiency */,
    null /* saveType */,
    false
  ),
  Special: new ToHit(
    null /* attackRollType */,
    null /* attackRollBonus */,
    null /* proficiency */,
    null /* saveType */,
    true
  ),
  UnarmedStrikeAttackRoll: function(opt_hasSpecialDetails) {
    return new ToHit(
      AttackRollTypes.UnarmedStrike,
      0,
      Proficiencies.UnarmedStrike,
      null /* saveType */,
      !!opt_hasSpecialDetails
    );
  },
  MeleeDexAttackRoll: function(proficiency, opt_attackBonus, opt_hasSpecialDetails) {
    return new ToHit(
      AttackRollTypes.MeleeDex,
      opt_attackBonus || 0,
      proficiency,
      null /* saveType */,
      !!opt_hasSpecialDetails
    );
  },
  MeleeAttackRoll: function(proficiency, opt_attackBonus, opt_hasSpecialDetails) {
    return new ToHit(
      AttackRollTypes.Melee,
      opt_attackBonus || 0,
      proficiency,
      null /* saveType */,
      !!opt_hasSpecialDetails
    );
  },
  MeleeOrMartialArtsAttackRoll: function(proficiency, opt_attackBonus, opt_hasSpecialDetails) {
    return new ToHit(
      AttackRollTypes.MeleeOrMartialArts,
      opt_attackBonus || 0,
      proficiency,
      null /* saveType */,
      !!opt_hasSpecialDetails
    );
  },
  FinesseMeleeAttackRoll: function(proficiency, opt_attackBonus, opt_hasSpecialDetails) {
    return new ToHit(
      AttackRollTypes.Finesse,
      opt_attackBonus || 0,
      proficiency,
      null /* saveType */,
      !!opt_hasSpecialDetails
    );
  },
  FinesseOffHandAttackRoll: function(proficiency, opt_attackBonus, opt_hasSpecialDetails) {
    return new ToHit(
      AttackRollTypes.FinesseOffHand,
      opt_attackBonus || 0,
      proficiency,
      null /* saveType */,
      !!opt_hasSpecialDetails
    );
  },
  FinesseOrMartialArtsMeleeAttackRoll: function(
    proficiency,
    opt_attackBonus,
    opt_hasSpecialDetails
  ) {
    return new ToHit(
      AttackRollTypes.Finesse,
      opt_attackBonus || 0,
      proficiency,
      null /* saveType */,
      !!opt_hasSpecialDetails
    );
  },
  RangedAttackRoll: function(proficiency, opt_attackBonus, opt_hasSpecialDetails) {
    return new ToHit(
      AttackRollTypes.Ranged,
      opt_attackBonus || 0,
      proficiency,
      null /* saveType */,
      !!opt_hasSpecialDetails
    );
  },
  SpellAttackRoll: function(opt_hasSpecialDetails) {
    return new ToHit(
      AttackRollTypes.Spell,
      0 /* attackBonus */,
      null /* proficiency */,
      null /* saveType */,
      !!opt_hasSpecialDetails
    );
  },
  SpellSave: function(abilityOrSkillType, opt_hasSpecialDetails) {
    assert(
      abilityOrSkillType !== null &&
        (abilityOrSkillType instanceof AbilityType || abilityOrSkillType instanceof SkillType),
      'Missing or invalid skill or ability type for spell save'
    );
    return new ToHit(
      null /* AttackRollType */,
      null /* attackBonus */,
      null /* proficiency */,
      SaveType.SpellSave(abilityOrSkillType),
      !!opt_hasSpecialDetails
    );
  },
  Contest: function(abilityOrSkillType, opt_hasSpecialDetails) {
    assert(
      abilityOrSkillType !== null &&
        (abilityOrSkillType instanceof AbilityType || abilityOrSkillType instanceof SkillType),
      'Missing or invalid skill or ability type for spell save'
    );
    return new ToHit(
      null /* AttackRollType */,
      null /* attackBonus */,
      null /* proficiency */,
      SaveType.Contest(abilityOrSkillType),
      !!opt_hasSpecialDetails
    );
  },
};
