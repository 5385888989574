import { UnfoldArray } from '../Common/ArrayUtilities.js';

import { USE_SPELL_POINTS } from '../Settings.js';

import AbilityType from './AbilityType.js';
import Feat from './Feat.js';
import Feats from './Feats.js';
import Spell from './Spells/Spell.js';

class Class {
  constructor(
    name,
    hitDiceSides,
    spellAbility,
    savingThrowProficiencies,
    featsByLevel,
    spellSlots,
    nonSpellPowerPoints
  ) {
    this._name = name;
    this._hitDiceSides = hitDiceSides;
    this._firstLevelHP = hitDiceSides;
    this._higherLevelHP = hitDiceSides / 2 + 1;
    this._spellAbility = spellAbility;
    this._savingThrowProficiencies = savingThrowProficiencies;
    this._featsByLevel = featsByLevel;
    var powerPoints = Object.assign({}, nonSpellPowerPoints);
    if (spellSlots && USE_SPELL_POINTS) {
      this._spellSlots = null;
      for (var level = 1; level <= 20; level++) {
        powerPoints[level] = powerPoints[level] || {};
        powerPoints[level].spell = Spell.spellSlotsToPoints(spellSlots[level]);
      }
    } else {
      this._spellSlots = spellSlots;
    }
    this._powerPoints = powerPoints;
  }

  get name() {
    return this._name;
  }

  get hitDiceSides() {
    return this._hitDiceSides;
  }

  isSavingThrowProficient(abilityType) {
    return this._savingThrowProficiencies.some((proficiency) => {
      return abilityType === proficiency;
    });
  }

  get featsByLevel() {
    return this._featsByLevel;
  }

  get allSpellSlots() {
    return this._spellSlots;
  }

  get allPowerPoints() {
    return this._powerPoints;
  }

  get savingThrowProficiencies() {
    return this._savingThrowProficiencies;
  }

  get spellAbility() {
    return this._spellAbility;
  }

  spellSlots(level) {
    return (this._spellSlots && this._spellSlots[level]) || null;
  }

  powerPoints(character) {
    var points = (this._powerPoints && this._powerPoints[character.level]) || null;
    if (character.hasFeat(Feats.BARDIC_INSPIRATION)) {
      points = Object.assign({}, points || {});
      points.inspiration = Math.max(character.abilityModifier(AbilityType.CHA), 1);
    }
    if (character.hasFeat(Feats.WILD_SHAPE)) {
      points = Object.assign({}, points || {});
      if (character.hasFeat(Feats.ARCHDRUID)) {
        points.wildShape = '∞';
      } else {
        points.wildShape = 2;
      }
    }

    return points;
  }

  feats(level) {
    var feats = [];
    for (var i = 1; i <= level; i++) {
      if (this.featsByLevel[i]) {
        this.featsByLevel[i].forEach((feat) => {
          feats.push(feat);
        });
      }
    }
    return UnfoldArray(feats, Feat);
  }
}

class Subclass extends Class {
  constructor(name, mainClass, featsByLevel, opt_overrideSpellAbility, opt_overrideSpellSlots) {
    super(
      mainClass.name + ' - ' + name,
      mainClass.hitDiceSides,
      opt_overrideSpellAbility || mainClass.spellAbility,
      mainClass.savingThrowProficiencies,
      mergeFeats(mainClass.featsByLevel, featsByLevel),
      opt_overrideSpellSlots || mainClass.allSpellSlots,
      mainClass.allPowerPoints
    );
  }
}

function mergeFeats(mainFeats, extraFeats) {
  var mergedFeats = {};
  for (var level = 1; level <= 20; level++) {
    if (mainFeats[level] || extraFeats[level]) {
      mergedFeats[level] = [];
    }
    if (mainFeats[level]) {
      mergedFeats[level] = mergedFeats[level].concat(mainFeats[level]);
    }
    if (extraFeats[level]) {
      mergedFeats[level] = mergedFeats[level].concat(extraFeats[level]);
    }
  }
  return mergedFeats;
}

export { Class as default, Subclass, mergeFeats };
