import assert from '../../Common/Assert.js';

import { AttackRollTypes, rollTypeToModifier } from './AttackRollType.js';
import DamageType from '../DamageType.js';

import Roll from '../Roll.js';

export const ACTION_EFFECT_HAS_MORE = ' (...)';

export default class Effect {
  constructor(attackRollType, damageRoll, damageType, specialDetails, damageBonusByCasterLevel) {
    if(damageRoll) {
      assert(damageType != null && damageType instanceof DamageType, 'Effect missing damageType');
    }
    this._attackRollType = attackRollType;
    this._damageRoll = damageRoll;
    this._damageType = damageType;
    this._specialDetails = specialDetails;
    this._damageBonusByCasterLevel = damageBonusByCasterLevel;
  }

  get hasDamage() {
    return !!this._damageType;
  }

  get damageType() {
    return this._damageType;
  }

  damageRoll(character) {
    var damageRoll = this._damageRoll;
    if (this._attackRollType === AttackRollTypes.Inspiration) {
      if (character.level >= 15) {
        damageRoll = Roll.d(12);
      } else if (character.level >= 10) {
        damageRoll = Roll.d(10);
      } else if (character.level >= 5) {
        damageRoll = Roll.d(8);
      } else {
        damageRoll = Roll.d(6);
      }
    } else {
      if (this._attackRollType === AttackRollTypes.UnarmedStrike && character.hasMartialArts) {
        if (character.level <= 4) {
          //1-4
          damageRoll = Roll.d(4);
        } else if (character.level <= 10) {
          // 5-10
          damageRoll = Roll.d(6);
        } else if (character.level <= 16) {
          // 11-16
          damageRoll = Roll.d(8);
        } else {
          // 17-20
          damageRoll = Roll.d(10);
        }
      }
      if (this._damageBonusByCasterLevel) {
        for (var i = 1; i <= character.level; i++) {
          if (this._damageBonusByCasterLevel[i]) {
            damageRoll = Roll.combine(damageRoll, this._damageBonusByCasterLevel[i]);
          }
        }
      }
      damageRoll = Roll.combine(
        damageRoll,
        Roll.constant(rollTypeToModifier(this._attackRollType, character, true /* isDamage */))
      );
    }
    const attackAllowsMartialArts =
      this._attackRollType === AttackRollTypes.MeleeOrMartialArts ||
      this._attackRollType === AttackRollTypes.FinesseOrMartialArts;
    if (attackAllowsMartialArts && character.hasMartialArts) {
      damageRoll = Roll.pick(damageRoll, Roll.constant('MA'));
    }
    return damageRoll;
  }

  get hasSpecialDetails() {
    return !!this._specialDetails;
  }

  get specialDetails() {
    if (typeof this._specialDetails === 'boolean') {
      return ACTION_EFFECT_HAS_MORE;
    } else {
      return this._specialDetails;
    }
  }
}
