import Action from '../Action.js';
import AreaOfEffects from '../AreaOfEffects.js';
import Durations from '../Durations.js';
import Effects from '../Effects.js';
import Ranges from '../Ranges.js';
import Sort from '../Sort.js';
import Times from '../Times.js';
import ToHits from '../ToHits.js';
import Types from '../Types.js';
import Utilities from '../Utilities.js';

export default new Action(
  'Short Rest',
  Types.OncePerShortRestPower,
  Sort.First,
  [
    'A short rest is a period of downtime, at least 1 hour long, during which you do nothing more strenuous than eating, drinking, reading, and tending to wounds.',
    'You can spend one or more Hit Dice at the end of a short rest. Each Hit Die spent this qay recovers hit points equal to the total plus your constitution modifier.',
    'You have a number of hit dice equal to your level. You regain up to half your level worth of spent hit dice during a long rest.',
  ],
  [Utilities.Rest],
  Times.Hours(1),
  Durations.Instantaneous,
  Ranges.Self,
  AreaOfEffects.SingleTarget,
  ToHits.AlwaysWorks,
  Effects.Recover(true),
  null /* requires */
);
