import { VSM } from '../Components.js';
import PointType from '../PointType.js';
import Spell from '../Spell.js';

import AreaOfEffects from '../../Actions/AreaOfEffects.js';
import Durations from '../../Actions/Durations.js';
import Effects from '../../Actions/Effects.js';
import Ranges from '../../Actions/Ranges.js';
import Times from '../../Actions/Times.js';
import ToHits from '../../Actions/ToHits.js';
import Utilities from '../../Actions/Utilities.js';

export default new Spell(
  'Message',
  0,
  PointType.SpellSlot,
  [
    'You point your finger toward a creature within range and whisper a message. The target (and only the target) hears the message and can reply in a whisper that only you can hear.',
    "You can cast this spell through solid objects if you are familiar with the target and know it is beyond the barrier. Magical silence, 1 foot of stone, 1 inch of common metal, a thin sheet of lead, or 3 feet of wood blocks the spell. The spell doesn't have to follow a straight line and can travel freely around corners or through openings.",
  ],
  Utilities.NonCombat,
  Times.Action,
  Durations.Rounds(1),
  Ranges.Range(120),
  AreaOfEffects.SingleTarget,
  ToHits.AlwaysWorks,
  Effects.Communicate(),
  VSM('a short piece of copper wire'),
  'Transmutation'
);
