import Action from '../Action.js';
import AreaOfEffects from '../AreaOfEffects.js';
import Durations from '../Durations.js';
import Effects from '../Effects.js';
import Ranges from '../Ranges.js';
import Sort from '../Sort.js';
import Times from '../Times.js';
import ToHits from '../ToHits.js';
import Types from '../Types.js';
import Utilities from '../Utilities.js';

export default new Action(
  'Unarmed Strike',
  Types.MeleeAttack,
  Sort.First,
  'You make a melee attack with your body (a punch, kick, headbutt, etc).' /* description */,
  [Utilities.Combat],
  Times.Action,
  Durations.NA,
  Ranges.Touch,
  AreaOfEffects.SingleTarget,
  ToHits.UnarmedStrikeAttackRoll(),
  Effects.UnarmedStrikeDamage(),
  null /* requires */
);
