export default class Time {
  constructor(generic, singular, plural, unitsPerLevel) {
    this._name = generic;
    this._singular = singular;
    this._plural = plural;
    this._unitsPerLevel = unitsPerLevel;
  }
  get name() {
    return this._name;
  }
  get shortName() {
    return this._name;
  }
  calculatedLongName(character) {
    if (this._unitsPerLevel) {
      return this.calculatedShortName(character) + ' (' + this._name + ')';
    } else {
      return this.name;
    }
  }
  calculatedShortName(character) {
    if (this._unitsPerLevel) {
      var units = Math.floor(character.level * this._unitsPerLevel);
      if (units === 1) {
        return units + ' ' + this._singular;
      } else {
        return units + ' ' + this._plural;
      }
    } else {
      return this.shortName;
    }
  }
}
