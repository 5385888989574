class Size {
  constructor(name, abbreviation) {
    this._name = name;
    this._abbreviation = abbreviation;
  }
  get name() {
    return this._name;
  }
  get abbreviation() {
    return this._abbreviation;
  }
}
Size.TINY = new Size('Tiny', 'T');
Size.SMALL = new Size('Small', 'S');
Size.MEDIUM = new Size('Medium', 'M');
Size.LARGE = new Size('Large', 'L');
Size.HUGE = new Size('Huge', 'H');
Size.GARGANTUAN = new Size('Gargantuan', 'G');

export default Size;
